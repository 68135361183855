import React, { useEffect, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Moment from "moment";
import { useAlert } from "react-alert";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import {
  isWithinInterval,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useRef } from 'react';

const socket = io.connect("https://api.caddcornerbd.com");

const TrashApplications = () => {
  const alert = useAlert();

  const [trashApps, setTrashApps] = useState([]);
  const [viewableTrashApps, setviewableTrashApps] = useState([]);

  const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
    useState(false);
  const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
  const [selectedData, setSelectedData] = useState([]);


  const [dprDate, setDprDate] = useState([new Date(), new Date()]);

  //view particular row
  const [viewableRow, setViewableRow] = useState([]);

  // refs //
  //view row modal refs
  const viewRowModal = useRef(null);
  const closeViewRowModal = useRef(null);

  const fetchTrashApps = async () => {
    const response = await fetch(
      "https://api.caddcornerbd.com/api/applications/fetchtrash",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setTrashApps(json.result);
    setviewableTrashApps(json.result)
  }

  // react-data-table-component columns
  const columns = [
    {
      name: "Name",
      selector: row => row['name'],
      sortable: true,
      compact: true
    },
    {
      name: "Email",
      selector: row => row['email'],
      sortable: true,
      compact: true,
      maxWidth: "0px",
      minWidth: "0px",
    },
    {
      name: "Phone",
      selector: row => row['mobile'],
      sortable: true,
      compact: true,
      maxWidth: "0px",
      minWidth: "0px",
    },
    {
      name: "Course",
      selector: row => row['coursename'],
      sortable: true,
      compact: true
    },
    {
      name: "Applied at",
      selector: row => row['created_at'],
      format: (row) => Moment(row.created_at).format("DD-MM-YYYY"),
      sortable: true,
      compact: true
    },
    {
      name: "View",
      cell: (row) => {
        return (
          <div>
            <i
              className="fa-solid fa-eye"
              id={row.id}
              onClick={handleViewRow}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  // react-data-table-component handle Row View
  const handleViewRow = (state) => {
    const clickedRowId = state.target.id;
    for (let i = 0; i < viewableTrashApps.length; i++) {
      if (viewableTrashApps[i].id === parseFloat(clickedRowId)) {
        setViewableRow(viewableTrashApps[i]);
        viewRowModal.current.click();
      }
    }
  };

  // react-data-table-component handle selected Data
  const deleteSelectedData = async () => {
    let rows = [];
    for (let i = 0; i < selectedData.length; i++) {
        rows.push(selectedData[i]);
    }
    if (window.confirm("Really wanna delete these applications?")) {
        const response = await fetch(
            `https://api.caddcornerbd.com/api/applications/deleteappsfromtrash`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(rows),
            }
        );
        const json = await response.json();
        if (json.success) {
            setShowManageSelectionWrapper(false);
            setNumberOfSelectedRow(0);
            fetchTrashApps();
            alert.success('Successfully Deleted')
            socket.emit("delete_trash", {
                message: "trash Deleted"
            })
        }
    }
};

  // react-data-table-component Data of Table
  const tableData = {
    columns,
    data: viewableTrashApps,
  };

  // check if the given time is in the selected range
  const checkInRange = (time) => {
    return isWithinInterval(time, {
      start: dprDate[0],
      end: dprDate[1],
    });
  };

  // react-data-table-component handle on select change
  const handleOnSelectChange = (state) => {
    if (state.selectedCount > 0) {
      setNumberOfSelectedRow(state.selectedCount);
      setShowManageSelectionWrapper(true);
      setSelectedData(state.selectedRows);
    } else {
      setNumberOfSelectedRow(0);
      setShowManageSelectionWrapper(false);
    }
  };

  // on change Date Time Picker Range
  const onChangeDPRange = () => {
    let rows = [];
    for (let i = 0; i < trashApps.length; i++) {
      if (checkInRange(zonedTimeToUtc(trashApps[i].created_at))) {
        rows.push(trashApps[i]);
      }
    }
    setviewableTrashApps(rows);
  };

  useEffect(() => {
    fetchTrashApps();
    socket.on("sentto_trash", (data) => {
      fetchTrashApps();
    });
    socket.on("sentto_trashfrompending", (data) => {
      fetchTrashApps();
    });
  }, [])

  return (
    <div>
      {/* View Row Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#viewRowModal"
        ref={viewRowModal}
      >
        Launch View Row modal
      </button>

      <div
        className="modal fade"
        id="viewRowModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="viewRowModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <form>
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className="modal-body">
                <div className='row'>
                  <div className='col-lg-12'>
                    <h6 style={{ color: "grey" }}><i><b>Applied at: {Moment(viewableRow.created_at).format("MMM Do YY | hh:mm A")}</b></i></h6>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Applicant's name:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.name}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Applicant's email:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.email}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Applicant's phone:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        0{viewableRow.mobile}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Related course:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.coursename}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeViewRowModal}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* View Row Modal */}
      <div className="header">
        <div className="row">
          <div className="col-lg-4">
            <h4>Trash Applications : {trashApps.length}</h4>
          </div>
          <div className="col-lg-8">
            <div
              className="btnWrapper d-flex flex-row"
              style={{ width: "max-content", marginLeft: "auto" }}
            >

              <DateTimeRangePicker
                onChange={setDprDate}
                onCalendarClose={onChangeDPRange}
                value={dprDate}
                format="dd-MM-y"
                clearIcon={null}
                className="pickerBoxes"
                rangeDivider="- to -"
                dayAriaLabel="Day"
              />
              <button
                className="btn btnsuccess"
                onClick={fetchTrashApps}
                style={{
                  backgroundColor: "#d72323",
                  borderRadius: "0",
                  marginRight: "5px",
                  color: "#fff",
                  border: "none"
                }}
              >
                Reset Filter
              </button>
            </div>
          </div>
          {showManageSelectionWrapper && (
            <div className="col-lg-12 manageSelectionWrapperContainer">
              <div
                className="manageSelectionWrapper d-flex flex-row"
                style={{
                  margin: "5px 0",
                  padding: "5px",
                  border: "1px solid lightgray",
                  alignItems: "center",
                  backgroundColor: "#ff000024",
                }}
              >
                <p style={{ color: "gray", opacity: "0.8" }}>
                  Selected rows : {numberOfSelectedRow}
                </p>
                <button
                  className="btn btn-danger"
                  onClick={deleteSelectedData}
                  style={{ borderRadius: "0", marginLeft: "auto" }}
                >
                  Delete Selected
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Page Body */}

      <div className="body">
        <DataTableExtensions
          {...tableData}
          exportHeaders
          print={false}
          fileName={Moment().format("MMM Do YY") + " Trash Applications"}
        >
          <DataTable
            columns={columns}
            data={trashApps}
            pagination
            fixedHeader
            selectableRows
            fixedHeaderScrollHeight="580px"
            highlightOnHover
            onSelectedRowsChange={handleOnSelectChange}
          />
        </DataTableExtensions>
      </div>
      {/* Page Body */}
    </div>
  )
}

export default TrashApplications
