import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from "react-alert";

const AdminLogin = () => {
    const alert = useAlert();
    let navigate = useNavigate();

    const [adminLoginDetails, setAdminLoginDetails] = useState({ email: "", password: "", code:"", newPass:"" })

    const [fetchedAdminEmail, setFetchedAdminEmail] = useState("");
    const [generatedCode, setGeneratedCode] = useState("");

    const [loginComp, setLoginComp] = useState(true);
    const [enterEmailCompLoading, setEnterEmailCompLoading] = useState(false);
    const [enterEmailComp, setEnterEmailComp] = useState(false);
    const [enterCodeComp, setEnterCodeComp] = useState(false);
    const [newPassComp, setNewPassComp] = useState(false);

    const onChangeInput = (e) => {
        setAdminLoginDetails({ ...adminLoginDetails, [e.target.name]: e.target.value });
    }

    const openEnterEmailComp = async () => {
        setEnterEmailCompLoading(true);
        const response = await fetch(`https://api.caddcornerbd.com/api/auth/fetchadminemail`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }
        });

        const json = await response.json();
        if (json.success) {
            setFetchedAdminEmail(json.email);
            setLoginComp(false);
            setEnterEmailCompLoading(false);
            setEnterEmailComp(true);
        }
    }

    const backToLogin = () => {
        setAdminLoginDetails({email: "", password: "", code:"", newPass:""});
        setFetchedAdminEmail("");
        setGeneratedCode("");
        setEnterEmailCompLoading(false);
        setEnterEmailComp(false);
        setEnterCodeComp(false);
        setNewPassComp(false);
        setLoginComp(true);
    }

    const emailVerify = async (e) => {
        e.preventDefault();
        if (fetchedAdminEmail === adminLoginDetails.email) {
            const response = await fetch(`https://api.caddcornerbd.com/api/auth/adminemailverify`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: adminLoginDetails.email
                }),
            });

            const json = await response.json();
            if(json.success){
                setGeneratedCode(json.code);
                setEnterEmailComp(false);
                setEnterCodeComp(true);
            }
        } else {
            alert.error("Please enter the email correctly");
        }
    }

    const codeVerify = (e) => {
        e.preventDefault();
        if(generatedCode.toString() === adminLoginDetails.code){
            setEnterCodeComp(false);
            setNewPassComp(true);
        }else{
            alert.error('Invalid verification code');
        }
    }

    const handleSetNewPass = async (e) => {
        e.preventDefault();
        const { newPass } = adminLoginDetails;

        const response = await fetch(`https://api.caddcornerbd.com/api/auth/adminnewpass`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                newPass
            }),
        });

        const json = await response.json();
        if(json.success){
            backToLogin();
            alert.success("Password changed successfully")
        }
    }

    const handleAdminLogin = async (e) => {
        e.preventDefault();
        const { password } = adminLoginDetails;

        const response = await fetch(`https://api.caddcornerbd.com/api/auth/adminlogin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password
            }),
        });

        const json = await response.json();

        if (json.success) {
            setAdminLoginDetails({
                email: "",
                password: "",
                code:"",
                newPass:""
            });
            localStorage.setItem("tokenForCCAdmin", json.authToken);
            navigate("/");
        }
        if (json.error) {
            alert.error(json.errorMsg);
        }

        console.log(adminLoginDetails);
    }

    useEffect(() => {
        if (localStorage.getItem("tokenForCCAdmin")) {
            navigate("/");
        }
    })
    return (
        <div>
            <div className='container'>
                {loginComp && <div className='loginBox' style={{ padding: "100px", margin: "100px", border: "1px solid #d72323", color: "#d72323", textAlign: "center", fontFamily: "poppins" }}>
                    <h1 style={{ letterSpacing: "15px", marginBottom: "30px" }}>ADMIN LOGIN</h1>
                    <form onSubmit={handleAdminLogin} className="d-block m-0 col-sm" >
                        <input type="password" name="password" id="password" value={adminLoginDetails.password} placeholder="Enter your password" onChange={onChangeInput}
                            className="form-control col-6 mx-auto" autoComplete="off" required style={{ borderRadius: "0px" }} />
                        <br />
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn btn-success mx-2 " style={{ borderRadius: "0px", backgroundColor: "#d72323", borderColor: "#d72323" }}>Login</button>
                            <button type='button' style={{ border: "2px solid #d72323", color: "#d72323", backgroundColor: "#fff" }} onClick={openEnterEmailComp}>{enterEmailCompLoading ? ('Please wait...') : ('forgot password')}</button>
                        </div>
                    </form>
                </div>}
                {enterEmailComp && <div className='loginBox' style={{ padding: "100px", margin: "100px", border: "1px solid #d72323", color: "#d72323", textAlign: "center", fontFamily: "poppins" }}>
                    <h1 style={{ marginBottom: "30px" }}>Enter the recovery email: {fetchedAdminEmail.substring(0, 4) + '*****.com'}</h1>
                    <form onSubmit={emailVerify} className="d-block m-0 col-sm" >
                        <input type="email" name="email" id="email" value={adminLoginDetails.email} placeholder="Enter the recoery email" onChange={onChangeInput}
                            className="form-control col-6 mx-auto" autoComplete="off" required style={{ borderRadius: "0px" }} />
                        <br />
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn btn-success mx-2 " style={{ borderRadius: "0px", backgroundColor: "#d72323", borderColor: "#d72323" }}>Submit</button>
                            <button type='button' style={{ border: "2px solid #d72323", color: "#d72323", backgroundColor: "#fff" }} onClick={backToLogin}>Back to login</button>
                        </div>
                    </form>
                </div>}

                {enterCodeComp && <div className='loginBox' style={{ padding: "100px", margin: "100px", border: "1px solid #d72323", color: "#d72323", textAlign: "center", fontFamily: "poppins" }}>
                    <h1 style={{ marginBottom: "30px" }}>Enter the verification code sent to the email:</h1>
                    <form onSubmit={codeVerify} className="d-block m-0 col-sm" >
                        <input type="text" name="code" id="code" value={adminLoginDetails.code} placeholder="Verification code" onChange={onChangeInput}
                            className="form-control col-6 mx-auto" autoComplete="off" required style={{ borderRadius: "0px" }} />
                        <br />
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn btn-success mx-2 " style={{ borderRadius: "0px", backgroundColor: "#d72323", borderColor: "#d72323" }}>Submit</button>
                            <button type='button' style={{ border: "2px solid #d72323", color: "#d72323", backgroundColor: "#fff" }} onClick={backToLogin}>Back to login</button>
                        </div>
                    </form>
                </div>}

                {newPassComp && <div className='loginBox' style={{ padding: "100px", margin: "100px", border: "1px solid #d72323", color: "#d72323", textAlign: "center", fontFamily: "poppins" }}>
                    <h1 style={{ marginBottom: "30px" }}>Set New Password:</h1>
                    <form onSubmit={handleSetNewPass} className="d-block m-0 col-sm" >
                        <input type="text" name="newPass" id="newPass" value={adminLoginDetails.newPass} placeholder="Set New Password" onChange={onChangeInput}
                            className="form-control col-6 mx-auto" autoComplete="off" required style={{ borderRadius: "0px" }} />
                        <br />
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn btn-success mx-2 " style={{ borderRadius: "0px", backgroundColor: "#d72323", borderColor: "#d72323" }}>Confirm</button>
                            <button type='button' style={{ border: "2px solid #d72323", color: "#d72323", backgroundColor: "#fff" }} onClick={backToLogin}>Cancel</button>
                        </div>
                    </form>
                </div>}
            </div>
        </div>
    )
}

export default AdminLogin
