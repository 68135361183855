import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");

const Branches = () => {

    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [branches, setbranches] = useState([]);
    const [viewablebranches, setViewablebranches] = useState([]);

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //add branch state
    const [addbranch, setAddbranch] = useState({ name: "", address: "", branchcode:"" });

    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    //refs
    //add branch button refs
    const viewAddbranchModal = useRef(null);
    const closeAddbranchModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);

    // fetch branchs
    const fetchbranches = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/others/fetchbranches",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setbranches(json.result);
        setViewablebranches(json.result);
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these branches?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/others/deletebranches`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchbranches();
                alert.success('Branches Deleted Successfully')
                socket.emit("delete_branch", {
                    message: "branch Deleted"
                })
            }
        }
    };

    // handle add branch
    const handleAddbranch = async (e) => {
        e.preventDefault();
        const { name, address, branchcode } = addbranch;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/others/addbranch`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    address,
                    branchcode
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchbranches();
            closeAddbranchModal.current.click();
            setAddbranch([]);
            alert.success('Branch Added Successfully');
            socket.emit("add_branch", {
                message: "branch added"
            })
        }
    }

    // handle update branch
    const handleUpdatebranch = async (e) => {
        e.preventDefault();
        const { id, branch, address, branchcode } = editableRow;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/others/updatebranch`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id,
                    branch,
                    address,
                    branchcode
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchbranches();
            closeEditRowModal.current.click();
            setEditableRow([]);
            alert.success('Branch Updated Successfully');
            // socket.emit("update_schlrship", {
            //     message: "schlrship Updated"
            // })
        }
    }

    // open add branch modal
    const openAddbranchModal = () => {
        viewAddbranchModal.current.click();
    }

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewablebranches.length; i++) {
            if (viewablebranches[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewablebranches[i]);
                editRowModal.current.click();
            }
        }
    };

    // react-data-table-component columns
    const columns = [
        {
            name: "Branches",
            selector: row => row['branch'],
            sortable: true,
            compact: true
        },
        {
            name: "Address",
            selector: row => row['address'],
            sortable: true,
        },
        {
            name: "Branch Code",
            selector: row => row['branchcode'],
            sortable: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewablebranches,
    };
    
    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    const onChangeAddbranchInput = (e) => {
        setAddbranch({ ...addbranch, [e.target.name]: e.target.value });
    }

    // onChange update branch Input
    const onChangeUpdatebranchInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchbranches();
    }, [])

    return (
        <div>
             {/* Edit Row Modal */}
             <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editbranchModal"
                ref={editRowModal}
            >
                Launch Edit branch modal
            </button>

            <div
                className="modal fade"
                id="editbranchModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editbranchModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleUpdatebranch}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit branch</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <input className="form-control" type="text" id="id" name='id' value={editableRow.id} onChange={onChangeUpdatebranchInput} required hidden/>
                                        <label htmlFor="branch" class="form-label">Branch name:</label>
                                        <input className="form-control" type="text" id="branch" name='branch' value={editableRow.branch} onChange={onChangeUpdatebranchInput} required /><br />
                                        <label htmlFor="address" class="form-label">Address:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="address" rows="5" onChange={onChangeUpdatebranchInput} name='address' value={editableRow.address} required></textarea><br/>
                                        <label htmlFor="branchcode" class="form-label">Branch Code:</label>
                                        <input className="form-control" type="text" id="branchcode" name='branchcode' value={editableRow.branchcode} onChange={onChangeUpdatebranchInput} required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* Add branch Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addbranchModal"
                ref={viewAddbranchModal}
            >
                Launch Add branch modal
            </button>

            <div
                className="modal fade"
                id="addbranchModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addbranchModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleAddbranch}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add branch</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label htmlFor="name" class="form-label">Branch name:</label>
                                        <input className="form-control" type="text" id="name" name='name' value={addbranch.name} onChange={onChangeAddbranchInput} required /><br />
                                        <label htmlFor="address" class="form-label">Address:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="address" rows="5" onChange={onChangeAddbranchInput} name='address' value={addbranch.address} required></textarea><br/>
                                        <label htmlFor="branchcode" class="form-label">Branch Code:</label>
                                        <input className="form-control" type="text" id="branchcode" name='branchcode' value={addbranch.branchcode} onChange={onChangeAddbranchInput} required /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddbranchModal}
                                    onClick={() => { setAddbranch({ name: "", address: "", branchcode:"" }) }}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add branch Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Branches : {branches.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddbranchModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Branches"}
                >
                    <DataTable
                        columns={columns}
                        data={branches}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default Branches
