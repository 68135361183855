import React from 'react'

const SmallScreenErr = () => {
  return (
    <div>
      <p style={{textAlign:"center", margin:"100px", border:"1px solid gray", color:"gray", borderRadius:"3px", padding:"50px"}}>!!!Please open the full screen!!!</p>
    </div>
  )
}

export default SmallScreenErr
