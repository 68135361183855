import React from 'react'
import { useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";
import { useEffect } from 'react';
import { useRef } from 'react';
import DateTimePicker from "react-datetime-picker";

const socket = io.connect("https://api.caddcornerbd.com");

const ManualReceipts = () => {
    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [receipts, setReceipts] = useState([]);
    const [viewableReceipts, setViewableReceipts] = useState([]);

    const [loading, setLoading] = useState(false);

    const [addReceipt, setAddReceipt] = useState({ name: "", email:"", st_id:"", coursename:"", payMethod:"", issuedBy:"" });
    const [totalFee, setTotalFee] = useState(0);
    const [paidnow, setPaidnow] = useState(0);
    const [paidtotal, setPaidtotal] = useState(0);
    const [due, setDue] = useState(0);
    
    const [nextPaymentDpDate, setNextPaymentDpDate] = useState(new Date());

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //view particular row
    const [viewableRow, setViewableRow] = useState([]);

    //refs
    //add receipt button refs
    const viewAddReceiptModal = useRef(null);
    const closeAddReceiptModal = useRef(null);

    //view row modal refs
    const viewRowModal = useRef(null);
    const closeViewRowModal = useRef(null);

    // fetch Receipts
    const fetchReceipts = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/receipts/manual/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setReceipts(json.result);
        setViewableReceipts(json.result);
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these receipts?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/receipts/manual/delete`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchReceipts();
                alert.success('Receipts Deleted Successfully')
                socket.emit("delete_receipts", {
                    message: "receipts Deleted"
                })
            }
        }
    };

    // handle add Receipt
    const handleAddReceipt = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { name, email, st_id, coursename, payMethod, issuedBy } = addReceipt;
        let total = totalFee;
        let nextPayDate = Moment(nextPaymentDpDate).format("DD/MM/YYYY");
        let tdate = Moment(new Date()).format("DD/MM/YYYY");
        const response = await fetch(
            `https://api.caddcornerbd.com/api/receipts/addreceipt`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name, email, st_id, coursename, payMethod, total, paidnow, paidtotal, due, nextPayDate, issuedBy, tdate
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchReceipts();
            setLoading(false);
            closeAddReceiptModal.current.click();
            setAddReceipt({name: "", email:"", st_id:"", coursename:"", payMethod:"", issuedBy:""});
            setNextPaymentDpDate(new Date());
            setTotalFee(0);
            setPaidnow(0);
            setDue(0);
            setPaidtotal(0);
            alert.success('Receipt Added Successfully');
            socket.emit("add_receipt", {
                message: "receipt added"
            })
        }
    }

    // react-data-table-component columns
    const columns = [
        {
            name: "Name",
            selector: row => row['name'],
            sortable: true,
        },
        {
            name: "Student ID",
            selector: row => row['st_id'],
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row['email'],
            sortable: true,
            minWidth:"0px",
            maxWidth:"0px"
        },
        {
            name: "Course",
            selector: row => row['coursename'],
            sortable: true,
            minWidth:"0px",
            maxWidth:"0px"
        },
        {
            name: "Payment Method",
            selector: row => row['payMethod'],
            sortable: true,
            minWidth:"0px",
            maxWidth:"0px"
        },
        {
            name: "Payable Fee",
            selector: row => row['total'],
            sortable: true,
            minWidth:"0px",
            maxWidth:"0px"
        },
        {
            name: "Paid Now",
            selector: row => row['paidnow'],
            sortable: true,
            minWidth:"0px",
            maxWidth:"0px"
        },
        {
            name: "Paid total",
            selector: row => row['paidtotal'],
            sortable: true,
            minWidth:"0px",
            maxWidth:"0px"
        },
        {
            name: "Due Amount",
            selector: row => row['due'],
            sortable: true,
        },
        {
            name: "Paid at",
            selector: row => row['created_at'],
            format: (row) => Moment(row.created_at).format("DD-MM-YYYY"),
            sortable: true,
            minWidth:"0px",
            maxWidth:"0px"
        },
        {
            name: "Next Payment Date",
            selector: row => row['nextPayDate'],
            sortable: true,
            minWidth:"0px",
            maxWidth:"0px"
        },
        {
            name: "Issued by",
            selector: row => row['issuedBy'],
            sortable: true,
            minWidth:"0px",
            maxWidth:"0px"
        },
        {
            name: "Download",
            cell: (row) => {
                return (
                    <div>
                        <a href={`https://api.caddcornerbd.com/schlrships/manualReceipts/${row.receiptlink}.pdf`} download>
                            <i
                                className="fa-solid fa-download"
                                id={row.id}
                                style={{ cursor: "pointer" }}
                            ></i>
                        </a>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "View",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-eye"
                            id={row.id}
                            onClick={handleViewRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component handle Row View
    const handleViewRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableReceipts.length; i++) {
            if (viewableReceipts[i].id === parseFloat(clickedRowId)) {
                setViewableRow(viewableReceipts[i]);
                viewRowModal.current.click();
            }
        }
    };

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    // open add Receipt modal
    const openAddReceiptModal = () => {
        viewAddReceiptModal.current.click();
    }

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableReceipts,
    };

    const onChangeAddReceiptInput = (e) => {
        setAddReceipt({ ...addReceipt, [e.target.name]: e.target.value });
    }

    const onChangeTotalFee = (e) =>{
        setTotalFee(e.target.value);
    }

    const onChangePaidNow = (e) =>{
        setPaidnow(e.target.value);
    }

    const onChangePaidtotal = (e) =>{
        setPaidtotal(e.target.value);
        setDue(totalFee - e.target.value);
    }

    useEffect(() => {
        fetchReceipts();
    })
    return (
        <div>
            {/* View Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#viewRowModal"
                ref={viewRowModal}
            >
                Launch View Row modal
            </button>

            <div
                className="modal fade"
                id="viewRowModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="viewRowModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <form>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h6 style={{ color: "grey" }}><i><b>Issued at: {Moment(viewableRow.created_at).format("MMM Do YY | hh:mm A")}</b></i></h6>
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Recepient's name:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.name}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Recepient's email:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.email}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Recepient's Student ID:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.st_id}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Recepient's Course:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.coursename}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Payment Method:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.payMethod}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Total Payable:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.total}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Paid now:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.paidnow}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Paid total:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.paidtotal}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Due Amount:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.due}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Next Payment Date:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.nextPayDate}
                                            </div>
                                            <div className='col-lg-4'>
                                                <h6>
                                                    <b>Issued by:</b>
                                                </h6>
                                            </div>
                                            <div className='col-lg-8'>
                                                {viewableRow.issuedBy}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeViewRowModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* View Row Modal */}
            {/* Add Receipt Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addReceiptModal"
                ref={viewAddReceiptModal}
            >
                Launch Add Receipt modal
            </button>

            <div
                className="modal fade"
                id="addReceiptModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addReceiptModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <form onSubmit={handleAddReceipt}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Receipt</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label htmlFor="name" className="form-label">Recepient's name:</label>
                                        <input className="form-control" type="text" id="name" name='name' value={addReceipt.name} onChange={onChangeAddReceiptInput} required /><br />
                                        <label htmlFor="email" className="form-label">Recepient's email:</label>
                                        <input className="form-control" type="email" id="email" name='email' value={addReceipt.email} onChange={onChangeAddReceiptInput} required /><br />
                                        <label htmlFor="st_id" className="form-label">ID:</label>
                                        <input className="form-control" type="text" id="st_id" name='st_id' value={addReceipt.st_id} onChange={onChangeAddReceiptInput} required /><br />
                                        <label htmlFor="coursename" className="form-label">Product Name:</label>
                                        <input className="form-control" type="text" id="coursename" name='coursename' value={addReceipt.coursename} onChange={onChangeAddReceiptInput} required /><br />
                                        <label htmlFor='payMethod' className='form-label'>Payment Method:</label>
                                        <select name='payMethod' required className='form-select' id='paymentmethod' autoComplete='off' onChange={(e) => { onChangeAddReceiptInput(e) }} value={addReceipt.payMethod}>
                                            <option value=''>Select Payment Method</option>
                                            <option>Cash</option>
                                            <option>Bkash</option>
                                            <option>Nagad</option>
                                            <option>Bank</option>
                                        </select><br />
                                        <label htmlFor="total" className="form-label">Total Fee:</label>
                                        <input className="form-control" type="text" id="total" name='total' value={totalFee} onChange={onChangeTotalFee} required /><br />
                                        <label htmlFor="paidnow" className="form-label">Paid now:</label>
                                        <input className="form-control" type="text" id="paidnow" name='paidnow' value={paidnow} onChange={onChangePaidNow} required /><br />
                                        <label htmlFor="paidtotal" className="form-label">Total paid:</label>
                                        <input className="form-control" type="text" id="paidtotal" name='paidtotal' value={paidtotal} onChange={onChangePaidtotal} required /><br />
                                        <label htmlFor="due" className="form-label">Due:</label>
                                        <input className="form-control" type="text" id="due" name='due' value={due} required /><br />
                                        <label htmlFor="nextpaymentdate" className="form-label">Next Payment Date:</label><br />
                                        <DateTimePicker
                                            onChange={setNextPaymentDpDate}
                                            value={nextPaymentDpDate}
                                            format="dd/MM/y"
                                            clearIcon={null}
                                            className="pickerBoxes"
                                        />
                                        <br />
                                        <br />
                                        <label htmlFor='issuedBy' className='form-label'>Issued by:</label>
                                        <select name='issuedBy' required className='form-select' id='paymentmethod' autoComplete='off' onChange={(e) => { onChangeAddReceiptInput(e) }} value={addReceipt.issuedBy}>
                                            <option value=''>None</option>
                                            <option>Mahfuzur Rahman</option>
                                        </select><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddReceiptModal}
                                    onClick={() => { setAddReceipt({ name: "", relatedcourse: "" }) }}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    {loading?('Please wait...'):('Submit')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add receipt Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Manual Receipts : {receipts.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddReceiptModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Receipts"}
                >
                    <DataTable
                        columns={columns}
                        data={receipts}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default ManualReceipts
