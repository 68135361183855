import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import {
    isWithinInterval,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

const socket = io.connect("https://api.caddcornerbd.com");

const FormerStudents = () => {

    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [students, setStudents] = useState([]);
    const [viewableStudents, setViewableStudents] = useState([]);

    //view particular row
    const [viewableRow, setViewableRow] = useState([]);

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    const [dprDate, setDprDate] = useState([new Date(), new Date()]);

    //refs
    //view row modal refs
    const viewRowModal = useRef(null);
    const closeViewRowModal = useRef(null);
    //refs

    // fetch students
    const fetchStudents = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/students/fetchformer",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setStudents(json.result);
        setViewableStudents(json.result);
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these students permanently?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/students/deletepermanently`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchStudents();
                alert.success('Students Deleted Permanently')
                socket.emit("delete_studentPermanent", {
                    message: "student Permanently Deleted"
                })
            }
        }
    };

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    // react-data-table-component columns
    const columns = [
        {
            name: "Name",
            selector: "name",
            sortable: true
        },
        {
            name: "Student ID",
            selector: "studentid",
            sortable: true
        },
        {
            name: "Email",
            selector: "email",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Batch",
            selector: "batch",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Branch",
            selector: "branch",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Phone",
            selector: "mobile",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Field of study",
            selector: "fieldofstudy",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Course Name",
            selector: "coursename",
            sortable: true
        },
        {
            name: "Institute Name",
            selector: "institute",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Present Address",
            selector: "address",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Home District",
            selector: "district",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Emergency Phone",
            selector: "emermobile",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Date of Birth",
            selector: "birthdate",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Blood Group",
            selector: "bloodgroup",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Gender",
            selector: "gender",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Position",
            selector: "position",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Graduated at",
            selector: "created_at",
            format: (row) => Moment(row.updated_at).format("DD-MM-YYYY"),
            sortable: true
        },
        {
            name: "Expected Class Start Date",
            selector: "classstartdate",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Actual Course Fee",
            selector: "coursefee",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Discounted Course Fee",
            selector: "discoursefee",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Payment Term",
            selector: "paymentterm",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Payment Method",
            selector: "paymentmethod",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Transaction Type",
            selector: "transtype",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Money Receipt/Transaction ID no.",
            selector: "transid",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Next Payment Date",
            selector: "nextpaymentdate",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Interested to join in",
            selector: "classshift",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Class Type",
            selector: "classtype",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Money Received/Admitted by",
            selector: "admittedby",
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "View",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-eye"
                            id={row.id}
                            onClick={handleViewRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    // react-data-table-component handle Row View
    const handleViewRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableStudents.length; i++) {
            if (viewableStudents[i].id === parseFloat(clickedRowId)) {
                setViewableRow(viewableStudents[i]);
                viewRowModal.current.click();
            }
        }
    };

    // check if the given time is in the selected range
    const checkInRange = (time) => {
        return isWithinInterval(time, {
            start: dprDate[0],
            end: dprDate[1],
        });
    };

    // on change Date Time Picker Range
    const onChangeDPRange = () => {
        let rows = [];
        for (let i = 0; i < students.length; i++) {
            if (checkInRange(zonedTimeToUtc(students[i].created_at))) {
                rows.push(students[i]);
            }
        }
        setViewableStudents(rows);
    };

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableStudents,
    };

    useEffect(() => {
        fetchStudents();
    }, [])

    return (
        <div>
            {/* View Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#viewRowModal"
                ref={viewRowModal}
            >
                Launch View Row modal
            </button>

            <div
                className="modal fade"
                id="viewRowModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="viewRowModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <img style={{ width: "180px" }} src={viewableRow.studentdp === null ? (`https://api.caddcornerbd.com/schlrships/stImg/placeholder.png`) : (`https://api.caddcornerbd.com/schlrships/stImg/${viewableRow.studentdp}`)} alt="dp" className='img-fluid' /><br /><br />
                                        <h4><b><i>Personal Information</i></b></h4>
                                        <p>Name: {viewableRow.name}</p>
                                        <p>Email: {viewableRow.email}</p>
                                        <p>Phone: {viewableRow.mobile}</p>
                                        <p>Present Address: {viewableRow.address}</p>
                                        <p>Home District: {viewableRow.district}</p>
                                        <p>Emergency Phone: {viewableRow.emermobile}</p>
                                        <p>Date of Birth: {viewableRow.birthdate}</p>
                                        <p>Blood Group: {viewableRow.bloodgroup}</p>
                                        <p>Gender: {viewableRow.gender}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <br />
                                        <br />
                                        <p>Position: {viewableRow.position}</p>
                                        <p>Field of Study: {viewableRow.fieldofstudy}</p>
                                        <p>Institute: {viewableRow.institute}</p>
                                        <br />
                                        <br />
                                        <p>Branch: {viewableRow.branch}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <p><b>Date of Admission: {Moment(viewableRow.created_at).format("DD/MM/YYYY")}</b></p>
                                        <br />
                                        <br />
                                        <p>Student ID: {viewableRow.studentid}</p>
                                        <p>Batch: {viewableRow.batch}</p>
                                        <p>Expected Class Start Date: {viewableRow.classstartdate}</p>
                                        <br />
                                        <br />
                                        <p>Course: {viewableRow.coursename}</p>
                                        <p>Payable Fee: {viewableRow.discoursefee}</p>
                                        <p>Payment term: {viewableRow.paymentterm}</p>
                                        <p>Payment method: {viewableRow.paymentmethod}</p>
                                        <p>Next Payment Date: {viewableRow.nextpaymentdate}</p>
                                        <br />
                                        <br />
                                        <p>Class type: {viewableRow.classtype}</p>
                                        <p>Class Shift: {viewableRow.classshift}</p>
                                        <br />
                                        <br />
                                        <p><b>Money recieved/Admitted by: {viewableRow.admittedby}</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeViewRowModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* View Row Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Former Students : {students.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >

                            <DateTimeRangePicker
                                onChange={setDprDate}
                                onCalendarClose={onChangeDPRange}
                                value={dprDate}
                                format="dd-MM-y"
                                clearIcon={null}
                                className="pickerBoxes"
                                rangeDivider="- to -"
                                dayAriaLabel="Day"
                            />
                            <button
                                className="btn btnsuccess"
                                onClick={fetchStudents}
                                style={{
                                    backgroundColor: "#d72323",
                                    borderRadius: "0",
                                    marginRight: "5px",
                                    color: "#fff",
                                    border: "none"
                                }}
                            >
                                Reset Filter
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Fromer Students"}
                >
                    <DataTable
                        columns={columns}
                        data={students}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default FormerStudents