import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import DateTimePicker from "react-datetime-picker";
import {
    isWithinInterval,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

const socket = io.connect("https://api.caddcornerbd.com");

const CurrentStudents = () => {

    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [students, setStudents] = useState([]);
    const [viewableStudents, setViewableStudents] = useState([]);

    //loading states
    const [addStudentPreviewLoading, setAddStudentPreviewLoading] = useState(false);

    const [branches, setBranches] = useState([]);
    const [courses, setCourses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [otherDetails, setOtherDetails] = useState([]);

    const [birthDpDate, setBirthDpDate] = useState(new Date());
    const [classStartDpDate, setClassStartDpDate] = useState(new Date());
    const [nextPaymentDpDate, setNextPaymentDpDate] = useState(new Date());

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    const [dprDate, setDprDate] = useState([new Date(), new Date()]);

    //add student details states
    const [courseCode, setCourseCode] = useState("");
    const [branchCode, setBranchCode] = useState("");
    const [selectedCourse, setSelectedCourse] = useState("");
    const [selectedBranch, setSelectedBranch] = useState("");
    const [studentid, setStudentid] = useState("");
    const [coursefee, setCoursefee] = useState("");
    const [discountedCourseFee, setDiscountedCourseFee] = useState("");
    const [addStudent, setAddStudent] = useState({ name: "", email: "", discount: "", mobile: "", fieldofstudy: "", institute: "", address: "", district: "", emermobile: "", bloodgroup: "", gender: "", position: "", paymentterm: "", paymentmethod: "", classshift: "", classtype: "", batch: "", admittedby: "" });
    const [vat, setVat] = useState("");
    const [idCardFee, setIdCardFee] = useState("");
    const [otherPays, setOtherPays] = useState("");

    //view particular row
    const [viewableRow, setViewableRow] = useState([]);

    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    // refs
    //add Student button refs
    const viewAddStudentModal = useRef(null);
    const closeAddStudentModal = useRef(null);
    //add Student preview button refs
    const viewAddStudentPreviewModal = useRef(null);
    const closeAddStudentPreviewModal = useRef(null);
    const closeAddStudentPreviewModalHidden = useRef(null);

    const discountInput = useRef(null);

    //view row modal refs
    const viewRowModal = useRef(null);
    const closeViewRowModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);
    // refs

    // fetch students
    const fetchStudents = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/students/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setStudents(json.result);
        setViewableStudents(json.result);
    }

    // fetch courses
    const fetchCourses = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourses(json.result);
    }

    // fetch branches
    const fetchBranches = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/others/fetchbranches",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setBranches(json.result);
    }

    // fetch roll
    const fetchroll = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/others/fetchroll",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setOtherDetails(json.result[0]);
        // setNewRoll(otherDetails.rollNo + 1);
    }

    // fetch batches
    const fetchBatches = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/students/fetch/batches",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setBatches(json.result);
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these students?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/students/delete`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchStudents();
                alert.success('Students Deleted Successfully');
                socket.emit("delete_student", {
                    message: "student Deleted"
                })
            }
            if (json.unpaid) {
                alert.error('Payment is not clear of this student');
            }
        }
    };

    // open add student modal
    const openAddStudentModal = () => {
        viewAddStudentModal.current.click();
    }

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    // react-data-table-component columns
    const columns = [
        {
            name: "Name",
            selector: row => row['name'],
            sortable: true,
            compact: true,
        },
        {
            name: "Student ID",
            selector: row => row['studentid'],
            sortable: true,
            compact: true
        },
        {
            name: "Email",
            selector: row => row['email'],
            sortable: true,
            compact: true
        },
        {
            name: "Batch",
            selector: row => row['batch'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Branch",
            selector: row => row['branch'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Phone",
            selector: row => row['mobile'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Field of study",
            selector: row => row['fieldofstudy'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Course Name",
            selector: row => row['coursename'],
            sortable: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Institute Name",
            selector: row => row['institute'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Present Address",
            selector: row => row['address'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Home District",
            selector: row => row['district'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Emergency Phone",
            selector: row => row['emermobile'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Date of Birth",
            selector: row => row['birthdate'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Blood Group",
            selector: row => row['bloodgroup'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Gender",
            selector: row => row['gender'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Position",
            selector: row => row['position'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Date of Admission",
            selector: row => row['created_at'],
            format: (row) => Moment(row.created_at).format("DD-MM-YYYY"),
            sortable: true,
            compact: true
        },
        {
            name: "Expected Class Start Date",
            selector: row => row['classstartdate'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Actual Course Fee",
            selector: row => row['coursefee'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Discounted Course Fee",
            selector: row => row['discoursefee'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Payment Term",
            selector: row => row['paymentterm'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Payment Method",
            selector: row => row['paymentmethod'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Transaction Type",
            selector: row => row['transtype'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Money Receipt/Transaction ID no.",
            selector: row => row['transid'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Next Payment Date",
            selector: row => row['nextpaymentdate'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Interested to join in",
            selector: row => row['classshift'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Class Type",
            selector: row => row['classtype'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Money Received/Admitted by",
            selector: row => row['admittedby'],
            sortable: true,
            compact: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "View",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-eye"
                            id={row.id}
                            onClick={handleViewRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            compact: true
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            compact: true
        }
    ];

    // react-data-table-component handle Row View
    const handleViewRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableStudents.length; i++) {
            if (viewableStudents[i].id === parseFloat(clickedRowId)) {
                setViewableRow(viewableStudents[i]);
                viewRowModal.current.click();
            }
        }
    };

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableStudents.length; i++) {
            if (viewableStudents[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewableStudents[i]);
                editRowModal.current.click();
            }
        }
    };

    // handle add student
    const handleAddStudent = async (e) => {
        setAddStudentPreviewLoading(true);
        e.preventDefault();
        const { name, email, mobile, fieldofstudy, institute, address, district, emermobile, bloodgroup, gender, position, paymentterm, paymentmethod, classshift, classtype, batch, admittedby } = addStudent;

        let birthdate = Moment(birthDpDate).format("DD/MM/YYYY");
        let dateofadmission = Moment(new Date()).format("DD/MM/YYYY");
        let classstartdate = Moment(classStartDpDate).format("DD/MM/YYYY");
        let nextpaymentdate = Moment(nextPaymentDpDate).format("DD/MM/YYYY");

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/add`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name, email, mobile, fieldofstudy, institute, address, district, emermobile, bloodgroup, gender, position, classstartdate, paymentterm, paymentmethod, classshift, classtype, batch, admittedby, selectedCourse, selectedBranch, studentid, birthdate, dateofadmission, nextpaymentdate, coursefee, discountedCourseFee, totalpayable: (parseFloat(discountedCourseFee)+parseFloat(vat)+parseFloat(idCardFee)+parseFloat(otherPays)), vat, idCardFee, otherPays
                }),
            }
        );

        let newRoll = otherDetails.rollNo + 1;

        await fetch(
            `https://api.caddcornerbd.com/api/others/updateroll`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    newRoll
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            setAddStudentPreviewLoading(false);
            fetchStudents();
            closeAddStudentPreviewModalHidden.current.click();
            clearAddStudentInputsWOConfirmation();
            setAddStudent([]);
            alert.success('Student Added Successfully');
            socket.emit("add_student", {
                message: "student added"
            })
        } else {
            alert.error(json)
        }
    }

    // handle update position
    const handleUpdateStudent = async (e) => {
        e.preventDefault();
        const { id, name, email, branch, mobile, fieldofstudy, coursename, institute, address, district, emermobile, birthdate, bloodgroup, gender, position, classshift, classtype, batch } = editableRow;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/update`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id, name, email, branch, mobile, fieldofstudy, coursename, institute, address, district, emermobile, birthdate, bloodgroup, gender, position, classshift, classtype, batch
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchStudents();
            closeEditRowModal.current.click();
            setEditableRow([]);
            alert.success('Student Updated Successfully');
            socket.emit("update_student", {
                message: "student Updated"
            })
        }
    }

    //handle preview add student
    const handlePreviewAddStudent = (e) => {
        e.preventDefault();
        closeAddStudentModal.current.click();
        viewAddStudentPreviewModal.current.click();
    }

    // check if the given time is in the selected range
    const checkInRange = (time) => {
        return isWithinInterval(time, {
            start: dprDate[0],
            end: dprDate[1],
        });
    };

    // clear add student inputs
    const clearAddStudentInputs = () => {
        if (window.confirm("Sure to discard?")) {
            closeAddStudentModal.current.click();
            setAddStudent({ name: "", email: "", mobile: "", fieldofstudy: "", institute: "", address: "", district: "", emermobile: "", bloodgroup: "", gender: "", position: "", discount: "", paymentterm: "", paymentmethod: "", classshift: "", classtype: "", batch: "", admittedby: "" });
            setSelectedCourse("");
            setSelectedBranch("");
            setCourseCode("");
            setBranchCode("");
            setStudentid("");
            setCoursefee("");
            setDiscountedCourseFee("");
            setBirthDpDate(new Date());
            setClassStartDpDate(new Date());
            setNextPaymentDpDate(new Date());
            discountInput.current.value = "";
        }
    }

    // clear add student inputs without confirmation
    const clearAddStudentInputsWOConfirmation = () => {
        closeAddStudentModal.current.click();
        setAddStudent({ name: "", email: "", mobile: "", fieldofstudy: "", institute: "", address: "", district: "", emermobile: "", bloodgroup: "", gender: "", position: "", discount: "", paymentterm: "", paymentmethod: "", classshift: "", classtype: "", batch: "", admittedby: "" });
        setSelectedCourse("");
        setSelectedBranch("");
        setCourseCode("");
        setBranchCode("");
        setStudentid("");
        setCoursefee("");
        setDiscountedCourseFee("");
        setBirthDpDate(new Date());
        setClassStartDpDate(new Date());
        setNextPaymentDpDate(new Date());
        discountInput.current.value = "";
    }

    // on change Date Time Picker Range
    const onChangeDPRange = () => {
        let rows = [];
        for (let i = 0; i < students.length; i++) {
            if (checkInRange(zonedTimeToUtc(students[i].created_at))) {
                rows.push(students[i]);
            }
        }
        setViewableStudents(rows);
    };

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableStudents,
    };

    //to uppercase 
    const toUpperCaseInput = (e) => {
        e.target.value = e.target.value.toUpperCase();
    }

    //on change select course
    const onChangeSelectCourse = (e) => {
        setDiscountedCourseFee("");
        setSelectedCourse(e.target.value);
        //course fee
        for (let i = 0; i < courses.length; i++) {
            if (e.target.value === (courses[i].name + " (" + courses[i].catagory + ")")) {
                setCoursefee(courses[i].price);
                setCourseCode(courses[i].short);
                if (selectedBranch !== "") {
                    createStudentid(courses[i].short, branchCode);
                }
            }
        }
    }

    //create student id
    const createStudentid = (codeOfCourse, codeOfBranch) => {
        //year
        const d = new Date();
        const yr = d.getFullYear().toString().slice(2, 4);

        //month
        let mon;
        const monInt = (d.getMonth() + 1);
        if (monInt < 10) {
            mon = '0' + monInt.toString();
        } else {
            mon = monInt.toString();
        }
        setStudentid(codeOfCourse + "-" + yr + "0" + mon + (otherDetails.rollNo + 1).toString() + "-" + codeOfBranch)
    }

    //on change select branch
    const onChangeSelectBranch = (e) => {
        setSelectedBranch(e.target.value);
        for (let i = 0; i < branches.length; i++) {
            if (e.target.value === branches[i].branch) {
                setBranchCode(branches[i].branchcode)
                if (selectedCourse !== "") {
                    createStudentid(courseCode, branches[i].branchcode)
                }
            }
        }
    }

    // handle View to Edit modal
    const handleViewtoEditModal = (id) => {
        for (let i = 0; i < viewableStudents.length; i++) {
            if (viewableStudents[i].id === parseFloat(id)) {
                setEditableRow(viewableStudents[i]);
                editRowModal.current.click();
            }
        }
    };

    //VIEW to EDIT modal
    const viewToEditModal = (id) => {
        closeViewRowModal.current.click();
        handleViewtoEditModal(id);
    }

    //on change discount input
    const onChangeDiscountInput = (e) => {
        const finAmount = coursefee - ((coursefee * e.target.value) / 100);
        setDiscountedCourseFee(finAmount);
    }

    // onChange update Course Input
    const onChangeUpdateStudentInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    // onChange Add Student Input
    const onChangeAddStudentInput = (e) => {
        setAddStudent({ ...addStudent, [e.target.name]: e.target.value });
    };

    //onchange vat input
    const onChangeVatInput  = (e) => {
        const vatAmount = ((e.target.value * discountedCourseFee)/100)
        setVat(vatAmount);
    }

    // onchange idcardfee input 
    const onChangeIDFee = (e) => {
        setIdCardFee(e.target.value);
    }

    // onchange others pays input 
    const onChangeOtherPaysInput = (e) => {
        setOtherPays(e.target.value);
    }

    useEffect(() => {
        fetchStudents();
        fetchBranches();
        fetchCourses();
        fetchroll();
        fetchBatches()
        socket.on("updated_studentdp", (data) => {
            fetchStudents();
        });
        socket.on("added_student", (data) => {
            fetchStudents();
            fetchroll();
        });
    }, [])

    return (
        <div>
            {/* View Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#viewRowModal"
                ref={viewRowModal}
            >
                Launch View Row modal
            </button>

            <div
                className="modal fade"
                id="viewRowModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="viewRowModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <img style={{ width: "180px" }} src={viewableRow.studentdp === null ? (`https://api.caddcornerbd.com/schlrships/stImg/placeholder.png`) : (`https://api.caddcornerbd.com/schlrships/stImg/${viewableRow.studentdp}`)} alt="dp" className='img-fluid' /><br /><br />
                                        <h4><b><i>Personal Information</i></b></h4>
                                        <p>Name: {viewableRow.name}</p>
                                        <p>Email: {viewableRow.email}</p>
                                        <p>Phone: {viewableRow.mobile}</p>
                                        <p>Present Address: {viewableRow.address}</p>
                                        <p>Home District: {viewableRow.district}</p>
                                        <p>Emergency Phone: {viewableRow.emermobile}</p>
                                        <p>Date of Birth: {viewableRow.birthdate}</p>
                                        <p>Blood Group: {viewableRow.bloodgroup}</p>
                                        <p>Gender: {viewableRow.gender}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <br />
                                        <br />
                                        <p>Position: {viewableRow.position}</p>
                                        <p>Field of Study: {viewableRow.fieldofstudy}</p>
                                        <p>Institute: {viewableRow.institute}</p>
                                        <br />
                                        <br />
                                        <p>Branch: {viewableRow.branch}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <p><b>Date of Admission: {Moment(viewableRow.created_at).format("DD/MM/YYYY")}</b></p>
                                        <br />
                                        <br />
                                        <p>Student ID: {viewableRow.studentid}</p>
                                        <p>Batch: {viewableRow.batch}</p>
                                        <p>Expected Class Start Date: {viewableRow.classstartdate}</p>
                                        <br />
                                        <br />
                                        <p>Course: {viewableRow.coursename}</p>
                                        <p>Payable Fee: {viewableRow.discoursefee}</p>
                                        <p>Payment term: {viewableRow.paymentterm}</p>
                                        <p>Payment method: {viewableRow.paymentmethod}</p>
                                        <p>Next Payment Date: {viewableRow.nextpaymentdate}</p>
                                        <br />
                                        <br />
                                        <p>Class type: {viewableRow.classtype}</p>
                                        <p>Class Shift: {viewableRow.classshift}</p>
                                        <br />
                                        <br />
                                        <p><b>Money recieved/Admitted by: {viewableRow.admittedby}</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeViewRowModal}
                                >
                                    Close
                                </button>
                                <button type='button' onClick={() => { viewToEditModal(viewableRow.id) }} className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* View Row Modal */}
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editCourseModal"
                ref={editRowModal}
            >
                Launch Edit Course modal
            </button>

            <div
                className="modal fade"
                id="editCourseModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editCourseModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form onSubmit={handleUpdateStudent}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Student's Info</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row justify-content-center'>
                                    <div className='col-lg-10'>
                                        <h5><b>Applicant's Section:</b></h5>
                                    </div>
                                    <div className="col-lg-8">
                                        <input className="form-control" type="text" id="id" name='id' value={editableRow.id} required hidden /><br />
                                        <label htmlFor="name" className="form-label">Name of the Application (Capital Letter):</label>
                                        <input className="form-control" onInput={toUpperCaseInput} type="text" id="name" name='name' value={editableRow.name} onChange={onChangeUpdateStudentInput} required /><br />
                                        <label htmlFor="email" className="form-label">Email address:</label>
                                        <input className="form-control" type="email" id="email" name='email' value={editableRow.email} onChange={onChangeUpdateStudentInput} required /><br />
                                        <label htmlFor="branch" className="form-label">Branch:</label>
                                        <input className="form-control" type="text" id="branch" name='branch' value={editableRow.branch} onChange={onChangeUpdateStudentInput} required /><br />
                                        <label htmlFor="mobile" className="form-label">Mobile:</label>
                                        <input className="form-control" required type="text" id="mobile" name='mobile' value={"0" + editableRow.mobile} onChange={onChangeUpdateStudentInput} /><br />
                                        <label htmlFor="fieldofstudy" className="form-label">Field of Study:</label>
                                        <input className="form-control" required type="text" id="fieldofstudy" name='fieldofstudy' value={editableRow.fieldofstudy} onChange={onChangeUpdateStudentInput} /><br />
                                        <label htmlFor='coursename' className='form-label'>Name of the course:</label>
                                        <select name="coursename" required className="form-select" id="coursename" onChange={(e) => { onChangeUpdateStudentInput(e) }} value={editableRow.coursename}>
                                            <option>{editableRow.coursename}</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="institute" className="form-label">Name of the Institute/University (Write the full name):</label>
                                        <input className="form-control" required type="text" id="institute" name='institute' value={editableRow.institute} onChange={onChangeUpdateStudentInput} /><br />
                                        <label htmlFor="address" className="form-label">Present Address:</label>
                                        <textarea className="form-control" required style={{ whiteSpace: "pre-line" }} id="address" rows="3" onChange={onChangeUpdateStudentInput} name='address' value={editableRow.address} ></textarea><br />
                                        <label htmlFor="district" className="form-label">Home District:</label>
                                        <input className="form-control" required type="text" id="district" name='district' value={editableRow.district} onChange={onChangeUpdateStudentInput} /><br />
                                        <label htmlFor="emermobile" className="form-label">Emergency Mobile:</label>
                                        <input className="form-control" required type="text" id="emermobile" name='emermobile' value={"0" + editableRow.emermobile} onChange={onChangeUpdateStudentInput} /><br />
                                        <label htmlFor="birthdate" className="form-label">Date of Birth:</label>
                                        <input className="form-control" required type="text" id="birthdate" name='birthdate' value={editableRow.birthdate} onChange={onChangeUpdateStudentInput} /><br />
                                        <label htmlFor="bloodgroup" className="form-label">Blood Group:</label>
                                        <input className="form-control" required type="text" id="bloodgroup" name='bloodgroup' value={editableRow.bloodgroup} onChange={onChangeUpdateStudentInput} /><br />
                                        <label htmlFor="gender" className="form-label">Gender:</label>
                                        <input className="form-control" required type="text" id="gender" name='gender' value={editableRow.gender} onChange={onChangeUpdateStudentInput} /><br />
                                        <label htmlFor="position" className="form-label">Position:</label>
                                        <input className="form-control" required type="text" id="position" name='position' value={editableRow.position} onChange={onChangeUpdateStudentInput} /><br />
                                    </div>
                                    <div className='col-lg-10'>
                                        <h5><b>Administrator's Section:</b></h5>
                                    </div>
                                    <div className='col-lg-8'>
                                        <label htmlFor='classshift' className='form-label'>Interested to join in:</label>
                                        <select name='classshift' required className='form-select' id='classshift' autoComplete='off' onChange={(e) => { onChangeUpdateStudentInput(e) }} value={editableRow.classshift}>
                                            <option value=''>Select Class Shift</option>
                                            <option>Morning Time (9am - 12pm)</option>
                                            <option>Day Time (2pm - 5pm)</option>
                                            <option>Evening Time (5pm - 9pm)</option>
                                        </select><br />
                                        <label htmlFor='classtype' className='form-label'>Class type:</label>
                                        <select name='classtype' required className='form-select' id='classtype' autoComplete='off' onChange={(e) => { onChangeUpdateStudentInput(e) }} value={editableRow.classtype}>
                                            <option value=''>Select class type</option>
                                            <option>Onsite Campus Training (ক্যাম্পাস ট্রেনিং)</option>
                                            <option>Online Training (অনলাইনে সরাসরি)</option>
                                        </select><br />
                                        <label htmlFor='batch' className='form-label'>Batch:</label>
                                        <select name="batch" className="form-select" id="batch" onChange={(e) => { onChangeUpdateStudentInput(e) }} value={editableRow.batch}>
                                            <option value="">Select Batch</option>
                                            {batches.map((batch) => {
                                                return <option key={batch.id}>{batch.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* Add Student Preview Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addAddStudentPreviewModal"
                ref={viewAddStudentPreviewModal}
            >
                Launch Add Student Preview modal
            </button>

            <div
                className="modal fade"
                id="addAddStudentPreviewModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addAddStudentPreviewLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form onSubmit={handleAddStudent}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Student Preview</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <h4><b><i>Personal Information</i></b></h4>
                                        <p>Name: {addStudent.name}</p>
                                        <p>Email: {addStudent.email}</p>
                                        <p>Phone: {addStudent.mobile}</p>
                                        <p>Present Address: {addStudent.address}</p>
                                        <p>Home District: {addStudent.district}</p>
                                        <p>Emergency Phone: {addStudent.emermobile}</p>
                                        <p>Date of Birth: {Moment(birthDpDate).format("DD/MM/YYYY")}</p>
                                        <p>Blood Group: {addStudent.bloodgroup}</p>
                                        <p>Gender: {addStudent.gender}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <br />
                                        <br />
                                        <p>Position: {addStudent.position}</p>
                                        <p>Field of Study: {addStudent.fieldofstudy}</p>
                                        <p>Institute: {addStudent.institute}</p>
                                        <br />
                                        <br />
                                        <p>Branch: {selectedBranch}</p>
                                        <br />
                                        <br />
                                        <p><b>Payments:</b></p>
                                        <p>Course fee: {discountedCourseFee} BDT</p>
                                        <p>VAT: {vat} BDT</p>
                                        <p>ID Card Fee: {idCardFee} BDT</p>
                                        <p>Others: {otherPays} BDT</p>
                                        <br/>
                                        <p><b>Total Payable Amount: {parseFloat(discountedCourseFee)+parseFloat(vat)+parseFloat(idCardFee)+parseFloat(otherPays)} BDT</b></p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <p><b>Date of Admission: {Moment(new Date()).format("DD/MM/YYYY")}</b></p>
                                        <br />
                                        <br />
                                        <p>Student ID: {studentid}</p>
                                        <p>Batch: {addStudent.batch}</p>
                                        <p>Expected Class Start Date: {Moment(classStartDpDate).format("DD/MM/YYYY")}</p>
                                        <br />
                                        <br />
                                        <p>Course: {selectedCourse}</p>
                                        <p>Course fee after discount: {discountedCourseFee} BDT</p>
                                        <p>Payment term: {addStudent.paymentterm}</p>
                                        <p>Payment method: {addStudent.paymentmethod}</p>
                                        <p>Next Payment Date: {Moment(nextPaymentDpDate).format("DD/MM/YYYY")}</p>
                                        <br />
                                        <br />
                                        <p>Class type: {addStudent.classtype}</p>
                                        <p>Class Shift: {addStudent.classshift}</p>
                                        <br />
                                        <br />
                                        <p><b>Money recieved/Admitted by: {addStudent.admittedby}</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddStudentPreviewModalHidden}
                                    hidden
                                >
                                    Back to Form
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddStudentPreviewModal}
                                    onClick={openAddStudentModal}
                                >
                                    Back to Form
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    {addStudentPreviewLoading ? ('Please wait...') : ('Confirm')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Student Preview Modal */}
            {/* Add Student Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addStudentModal"
                ref={viewAddStudentModal}
            >
                Launch Add Student modal
            </button>

            <div
                className="modal fade"
                id="addStudentModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addStudentModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form onSubmit={handlePreviewAddStudent}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4 className='mx-auto'><b>Application Form</b></h4>
                            </div>
                            <div className="modal-body">
                                <div className='row justify-content-center'>
                                    <div className='col-lg-10'>
                                        <h5><b>Applicant's Section:</b></h5>
                                    </div>
                                    <div className="col-lg-8">
                                        <label htmlFor="name" className="form-label">Name of the Application (Capital Letter):</label>
                                        <input className="form-control" onInput={toUpperCaseInput} type="text" id="name" name='name' value={addStudent.name} onChange={onChangeAddStudentInput} required /><br />
                                        <label htmlFor="email" className="form-label">Email address:</label>
                                        <input className="form-control" type="email" id="email" name='email' value={addStudent.email} onChange={onChangeAddStudentInput} required /><br />
                                        <label htmlFor='branch' className='form-label'>Branch:</label>
                                        <select name="branch" className="form-select" id="branch" required autoComplete="off" onChange={onChangeSelectBranch} value={selectedBranch}>
                                            <option value="">Select Branch</option>
                                            {branches.map((branch) => {
                                                return <option key={branch.id}>{branch.branch}</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="mobile" className="form-label">Mobile:</label>
                                        <input className="form-control" required type="text" id="mobile" name='mobile' value={addStudent.mobile} onChange={onChangeAddStudentInput} /><br />
                                        <label htmlFor='fieldofstudy' className='form-label'>Field of Study:</label>
                                        <select name='fieldofstudy' required className='form-select' id='' autoComplete='off' onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.fieldofstudy}>
                                            <option value=''>Select Field of Study</option>
                                            <option>Civil</option>
                                            <option>Architectural</option>
                                            <option>Electrical</option>
                                            <option>Mechanical</option>
                                            <option>Others</option>
                                        </select><br />
                                        <label htmlFor='coursename' className='form-label'>Name of the course:</label>
                                        <select name="coursename" required className="form-select" id="coursename" onChange={onChangeSelectCourse} value={selectedCourse}>
                                            <option value="">Select Course</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="institute" className="form-label">Name of the Institute/University (Write the full name):</label>
                                        <input className="form-control" required type="text" id="institute" name='institute' value={addStudent.institute} onChange={onChangeAddStudentInput} /><br />
                                        <label htmlFor="address" className="form-label">Present Address:</label>
                                        <textarea className="form-control" required style={{ whiteSpace: "pre-line" }} id="address" rows="3" onChange={onChangeAddStudentInput} name='address' value={addStudent.address} ></textarea><br />
                                        <label htmlFor="district" className="form-label">Home District:</label>
                                        <input className="form-control" required type="text" id="district" name='district' value={addStudent.district} onChange={onChangeAddStudentInput} /><br />
                                        <label htmlFor="emermobile" className="form-label">Emergency Mobile:</label>
                                        <input className="form-control" required type="text" id="emermobile" name='emermobile' value={addStudent.emermobile} onChange={onChangeAddStudentInput} /><br />
                                        <label htmlFor="dateofbirth" className="form-label">Date of Birth:</label><br />
                                        <DateTimePicker
                                            onChange={setBirthDpDate}
                                            // onCalendarClose={onChangeDP}
                                            value={birthDpDate}
                                            format="dd-MM-y"
                                            clearIcon={null}
                                            className="pickerBoxes"
                                        />
                                        <br />
                                        <br />
                                        <label htmlFor='bloodgroup' className='form-label'>Blood Group:</label>
                                        <select name='bloodgroup' required className='form-select' id='' autoComplete='off' onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.bloodgroup}>
                                            <option value=''>Select Blood Group</option>
                                            <option>A+</option>
                                            <option>A-</option>
                                            <option>B+</option>
                                            <option>B-</option>
                                            <option>AB+</option>
                                            <option>AB-</option>
                                            <option>O+</option>
                                            <option>O-</option>
                                        </select><br />
                                        <label htmlFor='gender' className='form-label'>Gender:</label>
                                        <select name='gender' required className='form-select' id='gender' autoComplete='off' onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.gender}>
                                            <option value=''>Select Gender</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                            <option>Other</option>
                                        </select><br />
                                        <label htmlFor='position' className='form-label'>Position:</label>
                                        <select name='position' required className='form-select' id='' autoComplete='off' onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.position}>
                                            <option value=''>Select Position</option>
                                            <option>Student</option>
                                            <option>Service</option>
                                        </select><br />
                                    </div>
                                    <div className='col-lg-10'>
                                        <h5><b>Administrator's Section:</b></h5>
                                    </div>
                                    <div className='col-lg-8'>
                                        <label htmlFor="studentid" className="form-label">Student ID:</label>
                                        <input className="form-control" required type="text" id="studentid" name='studentid' value={studentid} placeholder='Please select Branch and Course' onChange={onChangeAddStudentInput} /><br />
                                        <label htmlFor="classstartdate" className="form-label">Expected Class Start Date:</label><br />
                                        <DateTimePicker
                                            onChange={setClassStartDpDate}
                                            value={classStartDpDate}
                                            format="dd-MM-y"
                                            clearIcon={null}
                                            className="pickerBoxes"
                                        />
                                        <br />
                                        <br />
                                        <label htmlFor="coursefee" className="form-label">Actual Course Fee:</label>
                                        <input className="form-control" required type="text" id="coursefee" name='coursefee' value={coursefee} placeholder='Please select Course' onChange={onChangeAddStudentInput} /><br />
                                        <label htmlFor="discount" className="form-label">Discount:</label>
                                        <input className="form-control" required type="text" id="discount" name='discount' placeholder='Discount' ref={discountInput} onChange={onChangeDiscountInput} /><br />
                                        <label htmlFor="discoursefee" className="form-label">Course fee After Discount:</label>
                                        <input className="form-control" required type="text" id="discoursefee" name='discoursefee' placeholder='Discount' value={discountedCourseFee} /><br />
                                        <label htmlFor="vat" className="form-label">VAT (in percentage %):</label>
                                        <input className="form-control" required type="text" id="vat" name='vat' placeholder='Vat' onChange={onChangeVatInput} /><br />
                                        <label htmlFor="idCardFee" className="form-label">ID Card Fee:</label>
                                        <input className="form-control" required type="text" id="idCardFee" name='idCardFee' placeholder='ID Card Fee' onChange={onChangeIDFee} /><br />
                                        <label htmlFor="otherPays" className="form-label">Others:</label>
                                        <input className="form-control" required type="text" id="otherPays" name='otherPays' placeholder='Others' onChange={onChangeOtherPaysInput} /><br />
                                        <label htmlFor='paymentterm' className='form-label'>Payment Term:</label>
                                        <select name='paymentterm' required className='form-select' id='paymentterm' autoComplete='off' onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.paymentterm}>
                                            <option value=''>Select Payment Term</option>
                                            <option>At a time</option>
                                            <option>2 Installments</option>
                                            <option>3 Installments</option>
                                        </select><br />
                                        <label htmlFor='paymentmethod' className='form-label'>Payment Method:</label>
                                        <select name='paymentmethod' required className='form-select' id='paymentmethod' autoComplete='off' onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.paymentmethod}>
                                            <option value=''>Select Payment Method</option>
                                            <option>Cash</option>
                                            <option>Bkash</option>
                                            <option>Nagad</option>
                                            <option>Bank</option>
                                        </select><br />
                                        <label htmlFor="nextpaymentdate" className="form-label">Next Payment Date:</label><br />
                                        <DateTimePicker
                                            onChange={setNextPaymentDpDate}
                                            value={nextPaymentDpDate}
                                            format="dd-MM-y"
                                            clearIcon={null}
                                            className="pickerBoxes"
                                        />
                                        <br />
                                        <br />
                                        <label htmlFor='classshift' className='form-label'>Interested to join in:</label>
                                        <select name='classshift' required className='form-select' id='classshift' autoComplete='off' onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.classshift}>
                                            <option value=''>Select Class Shift</option>
                                            <option>Morning Time (9am - 12pm)</option>
                                            <option>Day Time (2pm - 5pm)</option>
                                            <option>Evening Time (5pm - 9pm)</option>
                                        </select><br />
                                        <label htmlFor='classtype' className='form-label'>Class type:</label>
                                        <select name='classtype' required className='form-select' id='classtype' autoComplete='off' onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.classtype}>
                                            <option value=''>Select class type</option>
                                            <option>Onsite Campus Training (ক্যাম্পাস ট্রেনিং)</option>
                                            <option>Online Training (অনলাইনে সরাসরি)</option>
                                        </select><br />
                                        <label htmlFor='batch' className='form-label'>Batch:</label>
                                        <select name="batch" className="form-select" id="batch" onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.batch}>
                                            <option value="">Select Batch</option>
                                            {batches.map((batch) => {
                                                return <option key={batch.id}>{batch.name}</option>
                                            })}
                                        </select><br />
                                        <label htmlFor='admittedby' className='form-label'>Money Recieved/Admitted by:</label>
                                        <select name='admittedby' required className='form-select' id='admittedby' autoComplete='off' onChange={(e) => { onChangeAddStudentInput(e) }} value={addStudent.admittedby}>
                                            <option value=''>None</option>
                                            <option>Mafuzur Rahman</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddStudentModal}
                                    hidden
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    style={{ borderRadius: "0" }}
                                    onClick={clearAddStudentInputs}
                                >
                                    Cancel
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
            {/* Add Student Modal */}
            <div div className="header" >
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Cuurent Students : {students.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >

                            <DateTimeRangePicker
                                onChange={setDprDate}
                                onCalendarClose={onChangeDPRange}
                                value={dprDate}
                                format="dd-MM-y"
                                clearIcon={null}
                                className="pickerBoxes"
                                rangeDivider="- to -"
                                dayAriaLabel="Day"
                            />
                            <button
                                className="btn btnsuccess"
                                onClick={fetchStudents}
                                style={{
                                    backgroundColor: "#d72323",
                                    borderRadius: "0",
                                    marginRight: "5px",
                                    color: "#fff",
                                    border: "none"
                                }}
                            >
                                Reset Filter
                            </button>
                            <button className='btn btnsuccess' onClick={openAddStudentModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div >
            {/* Page Body */}

            <div div className="body" >
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Students"}
                >
                    <DataTable
                        columns={columns}
                        data={students}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div >
            {/* Page Body */}
        </div >
    )
}

export default CurrentStudents
