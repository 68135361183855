import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";
import Resizer from "react-image-file-resizer";

const socket = io.connect("https://api.caddcornerbd.com");

const Certificates = () => {
    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [certificates, setCertificates] = useState([]);
    const [viewableCertificates, setViewableCertificates] = useState([]);

    const [courses, setCourses] = useState([]);

    const [coursename, setCoursename] = useState("");
    const [templink, setTemplink] = useState("");
    const [editTemplink, setEditTemplink] = useState("");

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //view particular row
    const [viewableRow, setViewableRow] = useState([]);
    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    //refs
    //add Batch button refs
    const viewAddBatchModal = useRef(null);
    const closeAddBatchModal = useRef(null);

    //thumbnail input
    const thumbnailInput = useRef(null);
    const thumbnailEditInput = useRef(null);

    //view row modal refs
    const viewRowModal = useRef(null);
    const closeViewRowModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);

    // fetch batchs
    const fetchCerficates = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/students/fetch/certificates",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCertificates(json.result);
        setViewableCertificates(json.result);
    }

    // fetch batchs
    const fetchCourses = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetchnames",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourses(json.result);
    }

    //image resizer
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                3508,
                3508,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                3508,
            );
        });

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these certificates?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/students/deletecertificates`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchCerficates();
                alert.success('Certificates Deleted Successfully')
                socket.emit("delete_certificate", {
                    message: "certificate Deleted"
                })
            }
        }
        console.log(rows)
    };

    const handleAddCertificate = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', templink);
        formData.append('coursename', coursename);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/addcertificate`,
            {
                method: "POST",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchCerficates();
            closeAddBatchModal.current.click();
            setCoursename("");
            setTemplink(null);
            thumbnailInput.current.value = null;
            alert.success('Certificate Added Successfully');
            socket.emit("add_certificate", {
                message: "certificate Added"
            })
        }
    }

    // handle update scholarship
    const handleUpdateCertificate = async (e) => {
        e.preventDefault();
        const { id, templink, coursename } = editableRow;
        const formData = new FormData();
        formData.append('image', editTemplink);
        formData.append('id', id);
        formData.append('oldTempLink', templink);
        formData.append('coursename', coursename);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/updatecertificate`,
            {
                method: "PUT",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchCerficates();
            closeEditRowModal.current.click();
            setEditableRow([]);
            setEditTemplink(null);
            thumbnailEditInput.current.value = null;
            alert.success('Certificate Updated Successfully');
            socket.emit("update_certificate", {
                message: "certificate Updated"
            })
        }
    }

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableCertificates.length; i++) {
            if (viewableCertificates[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewableCertificates[i]);
                editRowModal.current.click();
            }
        }
    };

    // react-data-table-component columns
    const columns = [
        {
            name: "Course Name",
            selector: row => row['coursename'],
            sortable: true,
        },
        {
            name: "Template",
            selector: row => row['templink'],
            sortable: true,
        },
        {
            name: "View",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-eye"
                            id={row.id}
                            onClick={handleViewRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component handle Row View
    const handleViewRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableCertificates.length; i++) {
            if (viewableCertificates[i].id === parseFloat(clickedRowId)) {
                setViewableRow(viewableCertificates[i]);
                viewRowModal.current.click();
            }
        }
    };

    // open add Batch modal
    const openAddBatchModal = () => {
        viewAddBatchModal.current.click();
    }

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableCertificates,
    };

    // data to file
    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    //make null edit thumbnails
    const nullEditThumbs = () => {
        setEditTemplink(null);
    }

    //on change thumbnail
    const onChangeThumbnail = async (e) => {

        const file = e.target.files[0];
        const imageResized = await resizeFile(file);

        const imageFile = dataURLtoFile(imageResized, file.name)
        setTemplink(imageFile);
    }

    //on change thumbnail
    const onChangeEditThumbnail = async (e) => {

        const file = e.target.files[0];
        const imageResized = await resizeFile(file);

        const imageFile = dataURLtoFile(imageResized, file.name)
        setEditTemplink(imageFile);
    }

    const onChangeCoursenameSelect = (e) => {
        setCoursename(e.target.value);
    }

    // onChange update Scholarship Input
    const onChangeUpdateScholarshipInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchCerficates();
        fetchCourses();
    })


    return (
        <div>
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editScholarshipModal"
                ref={editRowModal}
            >
                Launch Edit Scholarship modal
            </button>

            <div
                className="modal fade"
                id="editScholarshipModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editScholarshipModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleUpdateCertificate} enctype="multipart/form-data">
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Scholarship</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <input type="text" id='id' name='id' value={editableRow.id} onChange={onChangeUpdateScholarshipInput} hidden />
                                        <input type="text" id='templink' name='templink' value={editableRow.templink} onChange={onChangeUpdateScholarshipInput} hidden />
                                        <label htmlFor="coursename" className="form-label">Course Name:</label>
                                        <select name="coursename" className="form-select" id="coursename" autoComplete="off" value={editableRow.coursename} onChange={onChangeUpdateScholarshipInput}>
                                            <option value="">Select Related Course</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="templink" className="form-label">Template Link</label>
                                        <input className="form-control" type="file" id="templink" name='templink' onChange={onChangeEditThumbnail} accept=".jpg, .jpeg, .png" ref={thumbnailEditInput} /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                    onClick={nullEditThumbs}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* View Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#viewRowModal"
                ref={viewRowModal}
            >
                Launch View Row modal
            </button>

            <div
                className="modal fade"
                id="viewRowModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="viewRowModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <form>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h4>Course name: {viewableRow.coursename}</h4>
                                        <hr />
                                        <img src={`https://api.caddcornerbd.com/schlrships/certificateImg/${viewableRow.templink}`} alt={viewableRow.templink} className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeViewRowModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* View Row Modal */}
            {/* Add Batch Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addBatchModal"
                ref={viewAddBatchModal}
            >
                Launch Add Batch modal
            </button>

            <div
                className="modal fade"
                id="addBatchModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addBatchModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleAddCertificate}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Batch</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label htmlFor="coursename" className="form-label">Course Name:</label>
                                        <select name="coursename" className="form-select" id="coursename" autoComplete="off" required onChange={onChangeCoursenameSelect}>
                                            <option value="">Select Related Course</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="templink" className="form-label">Template Link</label>
                                        <input className="form-control" type="file" id="templink" name='templink' onChange={onChangeThumbnail} accept=".jpg, .jpeg, .png" required ref={thumbnailInput} /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddBatchModal}
                                    onClick={() => { setCoursename(""); }}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Batch Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Certificates : {certificates.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddBatchModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Batchs"}
                >
                    <DataTable
                        columns={columns}
                        data={certificates}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default Certificates
