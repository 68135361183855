import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Moment from "moment";
import { useAlert } from "react-alert";
import YouTube from 'react-youtube';
import Resizer from "react-image-file-resizer";

const socket = io.connect("https://api.caddcornerbd.com");

const Courses = () => {

    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [courses, setCourses] = useState([]);
    const [viewableCourses, setViewableCourses] = useState([]);

    //add Course details states
    const [addCourse, setAddCourse] = useState({ name: "", short: "", description: "", catagory: "", price: "", discountedprice: "", ulearn: "", requirements:"", courseisfor:"", coursecontent:"", videoid:"" });
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
    const [previewThumbnail, setPreviewthumbnail] = useState(null);

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //view particular row
    const [viewableRow, setViewableRow] = useState([]);
    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    //edit Course details states
    const [selectedEditThumbnail, setSelectedEditThumbnail] = useState(null);
    const [previewEditThumbnail, setPreviewEditThumbnail] = useState(null);

    //basic view states after fetch
    const [courseCatagories, setCourseCatagories] = useState([]);

    //States//

    // refs //
    //view row modal refs
    const viewRowModal = useRef(null);
    const closeViewRowModal = useRef(null);

    //add Course button refs
    const viewAddCourseModal = useRef(null);
    const closeAddCourseModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);

    //thumbnail input
    const thumbnailInput = useRef(null);
    const editThumbnailInput = useRef(null);
    // refs //

    //image resizer
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                688,
                688,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                688,
            );
        });

    // fetch Courses
    const fetchCourses = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourses(json.result);
        setViewableCourses(json.result);
    }

    const opts = {
        height: '390',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
        },
    };

    const _onReady = (e) => {
        // access to player in all event handlers via event.target
        e.target.pauseVideo();
    }

    // fetch course catagories
    const fetchCourseCatagories = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetch/catagories",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourseCatagories(json.result);
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these courses?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/courses/deletecourses`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchCourses();
                alert.success('Course Deleted Successfully')
                socket.emit("delete_course", {
                    message: "course Deleted"
                })
            }
        }
    };

    // react-data-table-component handle Row View
    const handleViewRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableCourses.length; i++) {
            if (viewableCourses[i].id === parseFloat(clickedRowId)) {
                setViewableRow(viewableCourses[i]);
                viewRowModal.current.click();
            }
        }
    };

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableCourses.length; i++) {
            if (viewableCourses[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewableCourses[i]);
                editRowModal.current.click();
            }
        }
    };

    // handle View to Edit modal
    const handleViewtoEditModal = (id) => {
        for (let i = 0; i < viewableCourses.length; i++) {
            if (viewableCourses[i].id === parseFloat(id)) {
                setEditableRow(viewableCourses[i]);
                editRowModal.current.click();
            }
        }
    };

    //VIEW to EDIT modal
    const viewToEditModal = (id) => {
        closeViewRowModal.current.click();
        handleViewtoEditModal(id);
    }

    // react-data-table-component columns
    const columns = [
        {
            name: "Course",
            selector: row => row['name'],
            sortable: true,
        },
        {
            name: "Catagory",
            selector: row => row['catagory'],
            sortable: true,
        },
        {
            name: "Short Form",
            selector: row => row['short'],
            sortable: true,
        },
        {
            name: "Displayed Price",
            selector: row => row['discountedprice'],
            sortable: true,
        },
        {
            name: "Description",
            selector: row => row['description'],
            sortable: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Date created",
            selector: row => row['created_at'],
            format: (row) => Moment(row.created_at).format("DD-MM-YYYY"),
            sortable: true,
        },
        {
            name: "View",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-eye"
                            id={row.id}
                            onClick={handleViewRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableCourses,
    };

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    // open add course modal
    const openAddCourseModal = () => {
        viewAddCourseModal.current.click();
    }

    // handle add Course
    const handleAddCourse = async (e) => {
        e.preventDefault();
        const { name, description, short, catagory, price, discountedprice, ulearn, requirements, courseisfor, coursecontent, videoid } = addCourse;
        const formData = new FormData();
        formData.append('image', selectedThumbnail);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('short', short);
        formData.append('catagory', catagory);
        formData.append('price', price);
        formData.append('discountedprice', discountedprice);
        formData.append('ulearn', ulearn);
        formData.append('requirements', requirements);
        formData.append('courseisfor', courseisfor);
        formData.append('coursecontent', coursecontent);
        formData.append('videoid', videoid);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/courses/addcourse`,
            {
                method: "POST",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchCourses();
            closeAddCourseModal.current.click();
            setAddCourse({ name: "", description: "", short: "", catagory: "", price: "", discountedprice: "" });
            setSelectedThumbnail(null);
            setPreviewthumbnail(null);
            thumbnailInput.current.value = null;
            alert.success('Course Added Successfully');
            socket.emit("add_course", {
                message: "course Added"
            })
        }
        // console.log(selectedThumbnail);
    }

    // handle update Course
    const handleUpdateCourse = async (e) => {
        e.preventDefault();
        const { id, thumbnail, name, description, short, catagory, price, discountedprice, ulearn, requirements, courseisfor, coursecontent, videoid } = editableRow;
        const formData = new FormData();
        formData.append('image', selectedEditThumbnail);
        formData.append('id', id);
        formData.append('thumbnail', thumbnail);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('short', short);
        formData.append('catagory', catagory);
        formData.append('price', price);
        formData.append('discountedprice', discountedprice);
        formData.append('ulearn', ulearn);
        formData.append('requirements', requirements);
        formData.append('courseisfor', courseisfor);
        formData.append('coursecontent', coursecontent);
        formData.append('videoid', videoid);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/courses/updatecourse`,
            {
                method: "PUT",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchCourses();
            closeEditRowModal.current.click();
            setEditableRow([]);
            setSelectedEditThumbnail(null);
            setPreviewEditThumbnail(null);
            thumbnailInput.current.value = null;
            alert.success('Course Updated Successfully');
            socket.emit("update_course", {
                message: "course Updated"
            })
        }
    }

    // data to file
    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    //on change thumbnail
    const onChangeThumbnail = async (e) => {
        // console.log(e.target.files);
        // setSelectedThumbnail(e.target.files[0]);

        // const objectUrl = URL.createObjectURL(e.target.files[0]);
        // setPreviewthumbnail(objectUrl);

        const file = e.target.files[0];
        const imageResized = await resizeFile(file);

        const imageFile = dataURLtoFile(imageResized, file.name)
        setSelectedThumbnail(imageFile);

        const previewUrl = await imageResized;
        setPreviewthumbnail(previewUrl);
        // console.log(imageFile);
    }

    //make null edit thumbnails
    const nullEditThumbs = () => {
        setPreviewEditThumbnail(null);
        setSelectedEditThumbnail(null);
    }

    //on change thumbnail
    const onChangeEditThumbnail = async (e) => {
        // console.log(e.target.files);
        // setSelectedThumbnail(e.target.files[0]);

        // const objectUrl = URL.createObjectURL(e.target.files[0]);
        // setPreviewthumbnail(objectUrl);

        const file = e.target.files[0];
        const imageResized = await resizeFile(file);

        const imageFile = dataURLtoFile(imageResized, file.name)
        setSelectedEditThumbnail(imageFile);

        const previewUrl = await imageResized;
        setPreviewEditThumbnail(previewUrl);
        // console.log(imageFile);
    }

    // onChange Add Course Input
    const onChangeAddCourseInput = (e) => {
        setAddCourse({ ...addCourse, [e.target.name]: e.target.value });
    };

    // onChange update Course Input
    const onChangeUpdateCourseInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchCourses();
        fetchCourseCatagories();
        socket.on("applied_course", (data) => {
            fetchCourses();
        });
    }, [])

    return (
        <div>
            {/* Add Course Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addCourseModal"
                ref={viewAddCourseModal}
            >
                Launch Add Course modal
            </button>

            <div
                className="modal fade"
                id="addCourseModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addCourseModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form onSubmit={handleAddCourse} enctype="multipart/form-data">
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Course</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-4' style={{ height: "300px" }}>
                                        {selectedThumbnail && <img src={previewThumbnail} className="img-fluid" style={{ width: "300px" }} alt='thumbnail' />}
                                    </div>
                                    <div className="col-lg-8">
                                        <label htmlFor="thumbnail" class="form-label">Thumbnail:</label>
                                        <input className="form-control" type="file" id="thumbnail" name='thumbnail' onChange={onChangeThumbnail} accept=".jpg, .jpeg, .png" required ref={thumbnailInput} /><br />
                                        <label htmlFor="name" class="form-label">Name:</label>
                                        <input className="form-control" type="text" id="name" name='name' value={addCourse.name} onChange={onChangeAddCourseInput} required /><br />
                                        <label htmlFor="short" class="form-label">Short Form:</label>
                                        <input className="form-control" type="text" id="short" name='short' value={addCourse.short} onChange={onChangeAddCourseInput} required /><br />
                                        <label htmlFor="catagory" class="form-label">Catagory:</label>
                                        <select name="catagory" className="form-select" id="catagory" autoComplete="off" required onChange={(e) => { onChangeAddCourseInput(e) }} value={addCourse.catagory}>
                                            <option value="">Select Catagory</option>
                                            {courseCatagories.map((catagory) => {
                                                return <option key={catagory.id}>{catagory.catagory}</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="description" class="form-label">Course Description:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="description" rows="5" onChange={onChangeAddCourseInput} name='description' value={addCourse.description} required></textarea><br />
                                        <label htmlFor="ulearn" class="form-label">You'll learn:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="ulearn" rows="5" onChange={onChangeAddCourseInput} name='ulearn' value={addCourse.ulearn} required></textarea><br />
                                        <label htmlFor="requirements" class="form-label">Requirements:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="requirements" rows="5" onChange={onChangeAddCourseInput} name='requirements' value={addCourse.requirements} required></textarea><br />
                                        <label htmlFor="courseisfor" class="form-label">This course is for:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="courseisfor" rows="5" onChange={onChangeAddCourseInput} name='courseisfor' value={addCourse.courseisfor} required></textarea><br />
                                        <label htmlFor="coursecontent" class="form-label">Course Content:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="coursecontent" rows="5" onChange={onChangeAddCourseInput} name='coursecontent' value={addCourse.coursecontent} required></textarea><br />
                                        <label htmlFor="videoid" class="form-label">Video ID:</label>
                                        <input className="form-control" type="text" id="videoid" name='videoid' value={addCourse.videoid} onChange={onChangeAddCourseInput}/><br />
                                        <label htmlFor="price" class="form-label">Price:</label>
                                        <input className="form-control" type="text" id="price" name='price' value={addCourse.price} onChange={onChangeAddCourseInput} required /><br />
                                        <label htmlFor="discountedprice" class="form-label">Discounted Price:</label>
                                        <input className="form-control" type="text" id="discountedprice" name='discountedprice' value={addCourse.discountedprice} onChange={onChangeAddCourseInput} required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddCourseModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Course Modal */}
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editCourseModal"
                ref={editRowModal}
            >
                Launch Edit Course modal
            </button>

            <div
                className="modal fade"
                id="editCourseModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editCourseModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form onSubmit={handleUpdateCourse} enctype="multipart/form-data">
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Course</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-4' style={{ height: "300px" }}>
                                        {previewEditThumbnail !== null ? (<img src={previewEditThumbnail} className="img-fluid" style={{ width: "300px" }} alt='thumbnail' />) : (<img src={`https://api.caddcornerbd.com/schlrships/courseImg/${editableRow.thumbnail}`} className="img-fluid" style={{ width: "300px" }} alt='thumbnail' />)}
                                    </div>
                                    <div className="col-lg-8">
                                        <input type="text" id='id' name='id' value={editableRow.id} onChange={onChangeUpdateCourseInput} hidden />
                                        <input type="text" id='oldThumb' name='oldThumb' value={editableRow.thumbnail} onChange={onChangeUpdateCourseInput} hidden />
                                        <label htmlFor="thumbnail" class="form-label">Thumbnail</label>
                                        <input className="form-control" type="file" id="thumbnail" name='thumbnail' onChange={onChangeEditThumbnail} value={setSelectedEditThumbnail} accept=".jpg, .jpeg, .png" ref={editThumbnailInput} /><br />
                                        <label htmlFor="name" class="form-label">Name</label>
                                        <input className="form-control" type="text" id="name" name='name' value={editableRow.name} onChange={onChangeUpdateCourseInput} required /><br />
                                        <label htmlFor="short" class="form-label">Short Form:</label>
                                        <input className="form-control" type="text" id="short" name='short' value={editableRow.short} onChange={onChangeUpdateCourseInput} required /><br />
                                        <label htmlFor="catagory" class="form-label">Catagory</label>
                                        <select name="catagory" className="form-select" id="catagory" autoComplete="off" required onChange={(e) => { onChangeUpdateCourseInput(e) }} value={editableRow.catagory}>
                                            <option>{editableRow.catagory}</option>
                                            {courseCatagories.map((catagory) => {
                                                return <option key={catagory.id}>{catagory.catagory}</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="description" class="form-label">Course Description</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="description" rows="5" onChange={onChangeUpdateCourseInput} name='description' value={editableRow.description} required></textarea><br />
                                        <label htmlFor="ulearn" class="form-label">You'll learn:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="ulearn" rows="5" onChange={onChangeUpdateCourseInput} name='ulearn' value={editableRow.ulearn} required></textarea><br />
                                        <label htmlFor="requirements" class="form-label">Requirements:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="requirements" rows="5" onChange={onChangeUpdateCourseInput} name='requirements' value={editableRow.requirements} required></textarea><br />
                                        <label htmlFor="courseisfor" class="form-label">This course is for:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="courseisfor" rows="5" onChange={onChangeUpdateCourseInput} name='courseisfor' value={editableRow.courseisfor} required></textarea><br />
                                        <label htmlFor="coursecontent" class="form-label">Course Content:</label>
                                        <textarea class="form-control" style={{ whiteSpace: "pre-line" }} id="coursecontent" rows="5" onChange={onChangeUpdateCourseInput} name='coursecontent' value={editableRow.coursecontent} required></textarea><br />
                                        <label htmlFor="videoid" class="form-label">Video ID:</label>
                                        <input className="form-control" type="text" id="videoid" name='videoid' value={editableRow.videoid} onChange={onChangeUpdateCourseInput}/><br />
                                        <label htmlFor="price" class="form-label">Price:</label>
                                        <input className="form-control" type="text" id="price" name='price' value={editableRow.price} onChange={onChangeUpdateCourseInput} required /><br />
                                        <label htmlFor="discountedprice" class="form-label">Discounted Price:</label>
                                        <input className="form-control" type="text" id="discountedprice" name='discountedprice' value={editableRow.discountedprice} onChange={onChangeUpdateCourseInput} required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                    onClick={nullEditThumbs}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* View Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#viewRowModal"
                ref={viewRowModal}
            >
                Launch View Row modal
            </button>

            <div
                className="modal fade"
                id="viewRowModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="viewRowModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h4 style={{ fontFamily: "poppins", backgroundColor: "#d72323", color: "#fff", padding: "15px" }}>{viewableRow.name} <span style={{ fontSize: "17px" }}>({viewableRow.catagory})</span></h4>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='yllLearnBox' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px", borderTopRightRadius: "20px", borderTopLeftRadius: "20px", backgroundColor: "#d72323", color: "#fff", borderColor: "#d72323" }}>
                                            <h3 style={{ fontFamily: "poppins" }}>You'll learn:</h3>
                                            <p style={{ textAlign: "justify", whiteSpace:"pre-line" }}>{viewableRow.ulearn}</p>
                                        </div>
                                        <div className='requirementsBox' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px" }}>
                                            <h4 style={{ fontFamily: "poppins" }}>Requirements:</h4>
                                            <p style={{ textAlign: "justify",whiteSpace:"pre-line"}}>{viewableRow.requirements}</p>
                                        </div>
                                        <div className='requirementsBox' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px" }}>
                                            <h4 style={{ fontFamily: "poppins" }}>Description:</h4>
                                            <p style={{ textAlign: "justify",whiteSpace:"pre-line"}}>{viewableRow.description}</p>
                                        </div>
                                        <div className='requirementsBox' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px" }}>
                                            <h4 style={{ fontFamily: "poppins" }}>This course is for:</h4>
                                            <p style={{ textAlign: "justify",whiteSpace:"pre-line"}}>{viewableRow.courseisfor}</p>
                                        </div>
                                        <div className='requirementsBox' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", backgroundColor: "#d72323", color: "#fff", borderColor: "#d72323" }}>
                                            <h4 style={{ fontFamily: "poppins" }}>Course content:</h4>
                                            <p style={{ textAlign: "justify",whiteSpace:"pre-line"}}>{viewableRow.coursecontent}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="column8">
                                            <div className="seminarInCon">
                                                <img src={`https://api.caddcornerbd.com/schlrships/courseImg/${viewableRow.thumbnail}`} alt="Course" className="img-fluid" /><br /><br />
                                            </div>
                                        </div>
                                        <div className="column8">
                                            <div className="seminarInCon">
                                                <YouTube videoId={viewableRow.videoid} opts={opts} onReady={_onReady} iframeClassName={viewableRow.videoid} id={viewableRow.videoid}/>
                                            </div>
                                        </div>
                                        <div className='column8'>
                                            <div className="column4">
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className="seminarInCon" style={{ width: "max-content" }}>
                                                            <h4>Price: <del style={{ fontSize: "60%", color: "grey" }}>{viewableRow.price}</del> {viewableRow.discountedprice} tk</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeViewRowModal}
                                >
                                    Close
                                </button>
                                <button type='button' onClick={() => { viewToEditModal(viewableRow.id) }} className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* View Row Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Courses : {courses.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddCourseModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Course Regs"}
                >
                    <DataTable
                        columns={columns}
                        data={courses}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default Courses
