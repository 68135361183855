import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import AdminLogin from './components/AdminLogin';
import Main from './components/Main';
import SmallScreenErr from './components/SmallScreenErr';

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const windowOnResize = () => {
    setWindowWidth(window.innerWidth);
  }

  window.addEventListener('resize', windowOnResize)
  return (
    <div className="App">
      {windowWidth > 1100?(
        <Routes>
          <Route path="*" element={<Main/>}></Route>
          <Route path="/admin/login" element={<AdminLogin/>}></Route>
        </Routes>
      ):(<SmallScreenErr/>)}
    </div>
  );
}

export default App;
