import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Moment from "moment";
import { useAlert } from "react-alert";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import DateTimePicker from "react-datetime-picker";
import {
    isWithinInterval,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import Resizer from "react-image-file-resizer";

const socket = io.connect("https://api.caddcornerbd.com");

const Seminars = () => {
    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [seminars, setSeminars] = useState([]);
    const [viewableSeminars, setViewableSeminars] = useState([]);

    //add Seminar details states
    const [addSeminar, setAddSeminar] = useState({ title: "", semides: "", });
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
    const [previewThumbnail, setPreviewthumbnail] = useState(null);

    //Date time picker range state
    const [dprDate, setDprDate] = useState([new Date(), new Date()]);

    const [seminarDpDate, setSeminarDpDate] = useState(new Date());

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //view particular row
    const [viewableRow, setViewableRow] = useState([]);
    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    //edit Seminar details states
    const [selectedEditThumbnail, setSelectedEditThumbnail] = useState(null);
    const [previewEditThumbnail, setPreviewEditThumbnail] = useState(null);

    //States//

    // refs //
    //view row modal refs
    const viewRowModal = useRef(null);
    const closeViewRowModal = useRef(null);

    //add Seminar button refs
    const viewAddSeminarModal = useRef(null);
    const closeAddSeminarModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);

    //thumbnail input
    const thumbnailInput = useRef(null);
    const editThumbnailInput = useRef(null);
    // refs //

    //image resizer
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                688,
                688,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                688,
            );
        });

    // fetch Seminars
    const fetchSeminars = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/seminars/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setSeminars(json.result);
        setViewableSeminars(json.result);
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these seminars?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/seminars/deleteseminars`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchSeminars();
                alert.success('Seminar Deleted Successfully')
                socket.emit("delete_seminar", {
                    message: "seminar Deleted"
                })
            }
        }
    };

    // react-data-table-component handle Row View
    const handleViewRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableSeminars.length; i++) {
            if (viewableSeminars[i].id === parseFloat(clickedRowId)) {
                setViewableRow(viewableSeminars[i]);
                viewRowModal.current.click();
            }
        }
    };

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableSeminars.length; i++) {
            if (viewableSeminars[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewableSeminars[i]);
                editRowModal.current.click();
            }
        }
    };

    // handle View to Edit modal
    const handleViewtoEditModal = (id) => {
        for (let i = 0; i < viewableSeminars.length; i++) {
            if (viewableSeminars[i].id === parseFloat(id)) {
                setEditableRow(viewableSeminars[i]);
                editRowModal.current.click();
            }
        }
    };

    //VIEW to EDIT modal
    const viewToEditModal = (id) => {
        closeViewRowModal.current.click();
        handleViewtoEditModal(id);
    }

    // react-data-table-component columns
    const columns = [
        {
            name: "Seminar",
            selector: row => row['title'],
            sortable: true,
        },
        {
            name: "Description",
            selector: row => row['semides'],
            sortable: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Date of Seminars",
            selector: row => row['dateofsemi'],
            sortable: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Date created",
            selector: row => row['created_at'],
            format: (row) => Moment(row.created_at).format("DD-MM-YYYY"),
            sortable: true,
        },
        {
            name: "View",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-eye"
                            id={row.id}
                            onClick={handleViewRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableSeminars,
    };

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    // check if the given time is in the selected range
    const checkInRange = (time) => {
        return isWithinInterval(time, {
            start: dprDate[0],
            end: dprDate[1],
        });
    };

    // on change Date Time Picker Range
    const onChangeDPRange = () => {
        let rows = [];
        for (let i = 0; i < seminars.length; i++) {
            if (checkInRange(zonedTimeToUtc(seminars[i].created_at))) {
                rows.push(seminars[i]);
            }
        }
        setViewableSeminars(rows);
    };

    // open add Seminar modal
    const openAddSeminarModal = () => {
        viewAddSeminarModal.current.click();
    }

    // handle add Seminar
    const handleAddSeminar = async (e) => {
        e.preventDefault();
        const { title, semides } = addSeminar;
        const formData = new FormData();
        formData.append('image', selectedThumbnail);
        formData.append('title', title);
        formData.append('dateofsemi', Moment(seminarDpDate).format("DD MMM y, ddd, h:m A"));
        formData.append('semides', semides);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/seminars/addSeminar`,
            {
                method: "POST",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchSeminars();
            closeAddSeminarModal.current.click();
            setAddSeminar({ title: "", semides: "" });
            setSelectedThumbnail(null);
            setPreviewthumbnail(null);
            setSeminarDpDate(new Date());
            thumbnailInput.current.value = null;
            alert.success('Seminar Added Successfully');
            socket.emit("add_seminar", {
                message: "seminar Added"
            })
        }
        // console.log(Moment(seminarDpDate).format("DD MMM y, ddd, h:m A"));
    }

    // handle update Seminar
    const handleUpdateSeminar = async (e) => {
        e.preventDefault();
        const { id, thumblink, title, semides, dateofsemi } = editableRow;
        const formData = new FormData();
        formData.append('image', selectedEditThumbnail);
        formData.append('id', id);
        formData.append('thumblink', thumblink);
        formData.append('title', title);
        formData.append('semides', semides);
        formData.append('dateofsemi', dateofsemi);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/seminars/updateseminar`,
            {
                method: "PUT",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchSeminars();
            closeEditRowModal.current.click();
            setEditableRow([]);
            setSelectedEditThumbnail(null);
            setPreviewEditThumbnail(null);
            thumbnailInput.current.value = null;
            alert.success('Seminar Updated Successfully');
            socket.emit("update_seminar", {
                message: "seminar Updated"
            })
        }
    }

    // data to file
    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    //on change thumbnail
    const onChangeThumbnail = async (e) => {
        // console.log(e.target.files);
        // setSelectedThumbnail(e.target.files[0]);

        // const objectUrl = URL.createObjectURL(e.target.files[0]);
        // setPreviewthumbnail(objectUrl);

        const file = e.target.files[0];
        const imageResized = await resizeFile(file);

        const imageFile = dataURLtoFile(imageResized, file.name)
        setSelectedThumbnail(imageFile);

        const previewUrl = await imageResized;
        setPreviewthumbnail(previewUrl);
        // console.log(imageFile);
    }

    //make null edit thumbnails
    const nullEditThumbs = () => {
        setPreviewEditThumbnail(null);
        setSelectedEditThumbnail(null);
    }

    //on change thumbnail
    const onChangeEditThumbnail = async (e) => {
        // console.log(e.target.files);
        // setSelectedThumbnail(e.target.files[0]);

        // const objectUrl = URL.createObjectURL(e.target.files[0]);
        // setPreviewthumbnail(objectUrl);

        const file = e.target.files[0];
        const imageResized = await resizeFile(file);

        const imageFile = dataURLtoFile(imageResized, file.name)
        setSelectedEditThumbnail(imageFile);

        const previewUrl = await imageResized;
        setPreviewEditThumbnail(previewUrl);
        // console.log(imageFile);
    }

    // onChange Add Seminar Input
    const onChangeAddSeminarInput = (e) => {
        setAddSeminar({ ...addSeminar, [e.target.name]: e.target.value });
    };

    // onChange update Seminar Input
    const onChangeUpdateSeminarInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchSeminars();
        socket.on("applied_seminar", (data) => {
            fetchSeminars();
        });
    }, [])

    return (
        <div>
            {/* Add Seminar Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addSeminarModal"
                ref={viewAddSeminarModal}
            >
                Launch Add Seminar modal
            </button>

            <div
                className="modal fade"
                id="addSeminarModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addSeminarModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form onSubmit={handleAddSeminar} encType="multipart/form-data">
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Seminar</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-4' style={{ height: "300px" }}>
                                        {selectedThumbnail && <img src={previewThumbnail} className="img-fluid" style={{ width: "300px" }} alt='thumbnail' />}
                                    </div>
                                    <div className="col-lg-8">
                                        <label htmlFor="thumblink" className="form-label">Thumbnail</label>
                                        <input className="form-control" type="file" id="thumblink" name='thumblink' onChange={onChangeThumbnail} accept=".jpg, .jpeg, .png" required ref={thumbnailInput} /><br />
                                        <label htmlFor="title" className="form-label">Seminar title:</label>
                                        <input className="form-control" type="text" id="title" name='title' value={addSeminar.title} onChange={onChangeAddSeminarInput} required /><br />
                                        <label htmlFor="dateofbirth" className="form-label">Date of Birth:</label><br />
                                        <DateTimePicker
                                            onChange={setSeminarDpDate}
                                            value={seminarDpDate}
                                            format="dd MMM y, h:mm a"
                                            clearIcon={null}
                                            className="pickerBoxes"
                                        />
                                        <br />
                                        <br />
                                        <label htmlFor="semides" className="form-label">Seminar Description</label>
                                        <textarea className="form-control" style={{ whiteSpace: "pre-line" }} id="semides" rows="5" onChange={onChangeAddSeminarInput} name='semides' value={addSeminar.semides} required></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddSeminarModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Seminar Modal */}
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editSeminarModal"
                ref={editRowModal}
            >
                Launch Edit Seminar modal
            </button>

            <div
                className="modal fade"
                id="editSeminarModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editSeminarModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form onSubmit={handleUpdateSeminar} encType="multipart/form-data">
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Seminar</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-4' style={{ height: "300px" }}>
                                        {previewEditThumbnail !== null ? (<img src={previewEditThumbnail} className="img-fluid" style={{ width: "300px" }} alt='thumbnail' />) : (<img src={`https://api.caddcornerbd.com/schlrships/seminarImg/${editableRow.thumblink}`} className="img-fluid" style={{ width: "300px" }} alt='thumbnail' />)}
                                    </div>
                                    <div className="col-lg-8">
                                        <input type="text" id='id' name='id' value={editableRow.id} onChange={onChangeUpdateSeminarInput} hidden />
                                        <input type="text" id='oldThumb' name='oldThumb' value={editableRow.thumblink} onChange={onChangeUpdateSeminarInput} hidden />
                                        <label htmlFor="thumblink" className="form-label">Thumbnail</label>
                                        <input className="form-control" type="file" id="thumblink" name='thumblink' onChange={onChangeEditThumbnail} value={setSelectedEditThumbnail} accept=".jpg, .jpeg, .png" ref={editThumbnailInput} /><br />
                                        <label htmlFor="title" className="form-label">Seminar title:</label>
                                        <input className="form-control" type="text" id="title" name='title' value={editableRow.title} onChange={onChangeUpdateSeminarInput} required /><br />
                                        <label htmlFor="dateofsemi" className="form-label">Date of Seminar:</label>
                                        <input className="form-control" type="text" id="dateofsemi" name='dateofsemi' value={editableRow.dateofsemi} onChange={onChangeUpdateSeminarInput} required /><br />
                                        <label htmlFor="semides" className="form-label">Seminar Description</label>
                                        <textarea className="form-control" style={{ whiteSpace: "pre-line" }} id="semides" rows="5" onChange={onChangeUpdateSeminarInput} name='semides' value={editableRow.semides} required></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                    onClick={nullEditThumbs}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* View Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#viewRowModal"
                ref={viewRowModal}
            >
                Launch View Row modal
            </button>

            <div
                className="modal fade"
                id="viewRowModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="viewRowModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <form>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h4>{viewableRow.title}</h4>
                                        <hr />
                                        <img src={`https://api.caddcornerbd.com/schlrships/seminarImg/${viewableRow.thumblink}`} alt={viewableRow.title} className='img-fluid' />
                                        <h4><b>Date of Seminar: {viewableRow.dateofsemi}</b></h4>
                                        <h4>Description</h4><hr />
                                        <p style={{ whiteSpace: "pre-line" }}>{viewableRow.semides}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeViewRowModal}
                                >
                                    Close
                                </button>
                                <button type='button' onClick={() => { viewToEditModal(viewableRow.id) }} className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* View Row Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Seminars : {seminars.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >

                            <DateTimeRangePicker
                                onChange={setDprDate}
                                onCalendarClose={onChangeDPRange}
                                value={dprDate}
                                format="dd-MM-y"
                                clearIcon={null}
                                className="pickerBoxes"
                                rangeDivider="- to -"
                                dayAriaLabel="Day"
                            />
                            <button
                                className="btn btnsuccess"
                                onClick={fetchSeminars}
                                style={{
                                    backgroundColor: "#d72323",
                                    borderRadius: "0",
                                    marginRight: "5px",
                                    color: "#fff",
                                    border: "none"
                                }}
                            >
                                Reset Filter
                            </button>
                            <button className='btn btnsuccess' onClick={openAddSeminarModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Seminars"}
                >
                    <DataTable
                        columns={columns}
                        data={seminars}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default Seminars
