/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";
import { useEffect } from 'react';
import { useRef } from 'react';
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import DateTimePicker from "react-datetime-picker"; import {
  isWithinInterval,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

const socket = io.connect("https://api.caddcornerbd.com");

const AutoReceipts = () => {
  const alert = useAlert();
  //States//
  //basic view states after fetch
  const [receipts, setReceipts] = useState([]);
  const [students, setStudents] = useState([]);
  const [viewableReceipts, setViewableReceipts] = useState([]);

  const [addReceipt, setAddReceipt] = useState({});
  const [paidnow, setPaidnow] = useState(0);
  const [paidtotal, setPaidtotal] = useState(0);
  const [due, setDue] = useState(0);
  const [nextPaymentDpDate, setNextPaymentDpDate] = useState(new Date());

  const [dprDate, setDprDate] = useState([new Date(), new Date()]);


  const [loading, setLoading] = useState(false);
  //view particular row
  // const [viewableRow, setViewableRow] = useState([]);
  const [managableRow, setManagableRow] = useState([]);
  const [managableStudent, setManagableStudent] = useState([]);

  //refs
  //view row modal refs
  // const viewRowModal = useRef(null);
  // const closeViewRowModal = useRef(null);


  //view row modal refs
  const manageRowModal = useRef(null);
  const closeManageRowModal = useRef(null);

  // fetch Receipts
  const fetchReceipts = async () => {
    const response = await fetch(
      "https://api.caddcornerbd.com/api/receipts/auto/fetch",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setReceipts(json.result);
    setViewableReceipts(json.result);
  }

  // fetch Receipts
  const fetchStudents = async () => {
    const response = await fetch(
      "https://api.caddcornerbd.com/api/students/fetch",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setStudents(json.result);
  }

  // handle add Receipt
  const handlePayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { payMethod, issuedBy } = addReceipt;
    let name = managableStudent.name;
    let coursename = managableStudent.coursename;
    let studentid = managableStudent.studentid;
    let email = managableStudent.email;
    let total = managableRow.totalfee;
    let nextPayDate = Moment(nextPaymentDpDate).format("DD/MM/YYYY");
    let tdate = Moment(new Date()).format("DD/MM/YYYY");
    const response = await fetch(
      `https://api.caddcornerbd.com/api/receipts/addreceiptauto`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name, email, studentid, coursename, total, lastpaymethod: payMethod, paidnow, paidtotal, due, nextPayDate, lastissuedby: issuedBy, tdate
        }),
      }
    );

    const json = await response.json();
    if (json.success) {
      fetchReceipts();
      setLoading(false);
      closeManageRowModal.current.click();
      setAddReceipt({ payMethod: "", issuedBy: "" });
      setNextPaymentDpDate(new Date());
      setPaidnow(0);
      setDue(0);
      setPaidtotal(0);
      alert.success('Receipt Added Successfully');
      socket.emit("add_receiptauto", {
        message: "auto receipt added"
      })
    }
    // console.log(issuedBy);
  }

  // react-data-table-component columns
  const columns = [
    {
      name: "Name",
      cell: (row) => {
        let name;
        for( let i = 0; i< students.length; i++){
          if( row.studentid === students[i].studentid){
            name = students[i].name;
          }
        }
        return (
          <div>
            <p>{name}</p>
          </div>
        );
      }
    },
    {
      name: "Student ID",
      selector: row => row['studentid'],
      cellExport: row => row['studentid'],
      sortable: true,
    },
    {
      name: "Total fee",
      selector: row => row['totalfee'],
      cellExport: row => row['totalfee'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Total paid",
      selector: row => row['totalpaid'],
      cellExport: row => row['totalpaid'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Paid now",
      selector: row => row['paidnow'],
      cellExport: row => row['paidnow'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Last payment method",
      selector: row => row['lastpaymethod'],
      cellExport: row => row['lastpaymethod'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Next payment date",
      selector: row => row['nextpaydate'],
      cellExport: row => row['nextpaydate'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Last issued by",
      selector: row => row['lastissuedby'],
      cellExport: row => row['lastissuedby'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div>
            {row.totalfee !== row.totalpaid ? (
              <button type='button' id={row.studentid} className="btn btn-danger" onClick={sendDueMail} style={{ fontSize: "10px" }}>Send Due Mail</button>
            ) : (
              ""
            )}
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Manage Payment",
      cell: (row) => {
        return (
          <div>
            {row.totalfee !== row.totalpaid ? (
              <i
                className="fa-solid fa-money-check-dollar"
                id={row.studentid}
                onClick={handleManagePayment}
                style={{ cursor: "pointer" }}
              ></i>
            ) : (
              ""
            )}
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Generate Last Payment",
      cell: (row) => {
        return (
          <div>
            <i
              className="fa-solid fa-gear"
              id={row.studentid}
              style={{ cursor: "pointer" }}
              onClick={generatePdf}
            ></i>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "View Last Payment",
      cell: (row) => {
        return (
          <div>
            <a href={`https://api.caddcornerbd.com/schlrships/autoReceipts/${row.studentid}.pdf`} target="_blank" download >
            <i
              className="fa-solid fa-download"
              id={row.studentid}
              style={{ cursor: "pointer" }}
            ></i>
            </a>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <div>
            {row.totalfee !== row.totalpaid ? (
              <button className='btn btn-danger' disabled>DUE</button>
            ) : (
              <button className='btn btn-success' disabled>PAID</button>
            )}
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const generatePdf = async (state) => {
    const clickedRowStudentId = state.target.id;
    let due;
    let nextpaydate;
    let studentid;
    let total;
    let paydate;
    let lastpaymethod;
    let lastissuedby;
    let paidnow;
    let totalpaid;
    for (let i = 0; i < receipts.length; i++) {
      if (receipts[i].studentid === clickedRowStudentId) {
        due = (receipts[i].totalfee - receipts[i].totalpaid);
        nextpaydate = receipts[i].nextpaydate;
        studentid = receipts[i].studentid;
        total = receipts[i].totalfee;
        paydate = Moment(receipts[i].updated_at).format("DD-MM-YYYY");
        lastpaymethod = receipts[i].lastpaymethod;
        lastissuedby = receipts[i].lastissuedby;
        paidnow = receipts[i].paidnow;
        totalpaid = receipts[i].totalpaid;
      }
    }

    for (let i = 0; i < students.length; i++) {
      if (students[i].studentid === clickedRowStudentId) {
        const response = await fetch(
          `https://api.caddcornerbd.com/api/receipts/generateautoreceipt`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: students[i].name,
              email: students[i].email,
              studentid,
              coursename: students[i].coursename,
              paydate,
              due,
              nextpaydate,
              total,
              lastpaymethod,
              lastissuedby,
              paidnow,
              totalpaid
            }),
          }
        );

        const json = await response.json();
        if (json.success) {
          alert.success(`Receipt is generated for ID : ${clickedRowStudentId}`);
        }
      }
    }
  }

  //on change Date Time Picker Range
  const onChangeDPRange = () => {
    let rows = [];
    for (let i = 0; i < receipts.length; i++) {
      if (checkInRange(zonedTimeToUtc(receipts[i].created_at))) {
        rows.push(receipts[i]);
      }
    }
    setViewableReceipts(rows);
  };

  // check if the given time is in the selected range
  const checkInRange = (time) => {
    return isWithinInterval(time, {
      start: dprDate[0],
      end: dprDate[1],
    });
  };

  const sendDueMail = async (state) => {
    const clickedRowStId = state.target.id;
    let due;
    let date;
    for (let i = 0; i < receipts.length; i++) {
      if (receipts[i].studentid === clickedRowStId) {
        due = (receipts[i].totalfee - receipts[i].totalpaid);
        date = receipts[i].nextpaydate;
      }
    }
    for (let i = 0; i < students.length; i++) {
      if (students[i].studentid === clickedRowStId) {
        const response = await fetch(
          `https://api.caddcornerbd.com/api/receipts/sendduemail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: students[i].name,
              email: students[i].email,
              due,
              date
            }),
          }
        );

        const json = await response.json();
        if (json.success) {
          alert.success(`Mail sent to ${json.name} of ID : ${clickedRowStId}`);
        }
      }
    }
  }

  // react-data-table-component handle Row View
  // const handleViewRow = (state) => {
  //   const clickedRowId = state.target.id;
  //   for (let i = 0; i < viewableReceipts.length; i++) {
  //     if (viewableReceipts[i].id === parseFloat(clickedRowId)) {
  //       setViewableRow(viewableReceipts[i]);
  //       viewRowModal.current.click();
  //     }
  //   }
  // };

  // filter dues
  const filterDues = () => {
    let rows = [];
    for (let i = 0; i < receipts.length; i++) {
      if (receipts[i].totalfee !== receipts[i].totalpaid) {
        rows.push(receipts[i]);
      }
    }
    setViewableReceipts(rows);
  }

  // filter Paids
  const filterPaids = () => {
    let rows = [];
    for (let i = 0; i < receipts.length; i++) {
      if (receipts[i].totalfee === receipts[i].totalpaid) {
        rows.push(receipts[i]);
      }
    }
    setViewableReceipts(rows);
  }

  // react-data-table-component handle Row View
  const handleManagePayment = (state) => {
    const clickedRowId = state.target.id;
    for (let i = 0; i < viewableReceipts.length; i++) {
      if (viewableReceipts[i].studentid === clickedRowId) {
        setManagableRow(viewableReceipts[i]);
        setPaidtotal(viewableReceipts[i].totalpaid);
        setDue(viewableReceipts[i].totalfee - viewableReceipts[i].totalpaid)
        manageRowModal.current.click();
      }
    }
    for (let i = 0; i < students.length; i++) {
      if (students[i].studentid === clickedRowId) {
        setManagableStudent(students[i]);
      }
    }
  };

  // react-data-table-component Data of Table
  const tableData = {
    columns,
    data: viewableReceipts,
  };

  const onChangePaidNow = (e) => {
    setPaidnow(e.target.value);
    setPaidtotal(parseFloat(managableRow.totalpaid) + parseFloat(e.target.value))
    setDue(parseFloat(managableRow.totalfee) - (parseFloat(managableRow.totalpaid) + parseFloat(e.target.value)))
  }

  const onChangeAddReceiptInput = (e) => {
    setAddReceipt({ ...addReceipt, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    fetchReceipts();
    fetchStudents();
  }, [])
  return (
    <div>
      {/* Manage Row Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#manageRowModal"
        ref={manageRowModal}
      >
        Launch Manage Row modal
      </button>

      <div
        className="modal fade"
        id="manageRowModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="viewRowModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <form onSubmit={handlePayment}>
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className="modal-body">
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Student's Name:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {managableStudent.name}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Email:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {managableStudent.email}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>ID:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {managableStudent.studentid}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Course:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {managableStudent.coursename}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Total payable fee:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {managableRow.totalfee}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Due:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {due}
                      </div>
                      <div className='col-lg-4'>
                        <label htmlFor='payMethod' className='form-label'>Payment Method:</label>
                        <select name='payMethod' required className='form-select' id='paymentmethod' autoComplete='off' onChange={(e) => { onChangeAddReceiptInput(e) }} value={addReceipt.payMethod}>
                          <option value=''>Select Payment Method</option>
                          <option>Cash</option>
                          <option>Bkash</option>
                          <option>Nagad</option>
                          <option>Bank</option>
                        </select>
                      </div>
                      <div className='col-lg-4'>
                        <label htmlFor="paidnow" className="form-label">Paid now:</label>
                        <input className="form-control" type="text" id="paidnow" name='paidnow' value={paidnow} onChange={onChangePaidNow} required />
                      </div>
                      <div className='col-lg-4'>
                        <label htmlFor="paidtotal" className="form-label">Total paid:</label>
                        <input className="form-control" type="text" id="paidtotal" name='paidtotal' value={paidtotal} required /><br />
                      </div>
                      <div className='col-lg-4'>
                        <label htmlFor="due" className="form-label">Due:</label>
                        <input className="form-control" type="text" id="due" name='due' value={due} required /><br />
                      </div>
                      <div className='col-lg-4'>
                        <label htmlFor="nextpaymentdate" className="form-label">Next Payment Date:</label><br />
                        <DateTimePicker
                          onChange={setNextPaymentDpDate}
                          value={nextPaymentDpDate}
                          format="dd/MM/y"
                          clearIcon={null}
                          className="pickerBoxes"
                        />
                      </div>
                      <div className='col-lg-4'>
                        <label htmlFor='issuedBy' className='form-label'>Issued by:</label>
                        <select name='issuedBy' required className='form-select' id='issuedBy' autoComplete='off' onChange={(e) => { onChangeAddReceiptInput(e) }} value={addReceipt.issuedBy}>
                          <option value=''>None</option>
                          <option>Mahfuzur Rahman</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeManageRowModal}
                  onClick={() => { setPaidnow(0); setManagableStudent([]) }}
                >
                  Close
                </button>
                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                  {loading ? ('Please wait...') : ('Submit')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Manage Row Modal */}
      {/* View Row Modal */}
      {/* <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#viewRowModal"
        ref={viewRowModal}
      >
        Launch View Row modal
      </button>

      <div
        className="modal fade"
        id="viewRowModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="viewRowModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <form>
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className="modal-body">
                <div className='row'>
                  <div className='col-lg-12'>
                    <h6 style={{ color: "grey" }}><i><b>Issued at: {Moment(viewableRow.created_at).format("MMM Do YY | hh:mm A")}</b></i></h6>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Student's ID:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.studentid}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Total fee:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.totalfee}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Total paid:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.totalpaid}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Paid now:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.paidnow}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Payment Method:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.lastpaymethod}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Next Payment Date:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.nextpaydate}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Issued by:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.lastissuedby}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeViewRowModal}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div> */}
      {/* View Row Modal */}
      <div className="header">
        <div className="row">
          <div className="col-lg-4">
            <h4>Auto Receipts : {receipts.length}</h4>
          </div>
          <div className="col-lg-8">
            <div
              className="btnWrapper d-flex flex-row"
              style={{ width: "max-content", marginLeft: "auto" }}
            >
              <DateTimeRangePicker
                onChange={setDprDate}
                onCalendarClose={onChangeDPRange}
                value={dprDate}
                format="dd-MM-y"
                clearIcon={null}
                className="pickerBoxes mx-2"
                rangeDivider="- to -"
                dayAriaLabel="Day"
              />
              <button type='button' className='btn btn-success' onClick={filterPaids} style={{ marginRight: "10px" }}>Show only PAIDs</button>
              <button type='button' className='btn btn-danger' onClick={filterDues} style={{ marginRight: "10px" }}>Show only DUEs</button>
              <button type='button' className='btn btn-warning' onClick={fetchReceipts} style={{ marginRight: "20px" }}>Reset Filter</button>
            </div>
          </div>
        </div>
      </div>
      {/* Page Body */}

      <div className="body">
        <DataTableExtensions
          {...tableData}
          exportHeaders
          print={false}
          fileName={Moment().format("MMM Do YY") + " Auto Receipts"}
        >
          <DataTable
            columns={columns}
            data={viewableReceipts}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="580px"
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {/* Page Body */}
    </div>
  )
}

export default AutoReceipts
