import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");
const Positions = () => {

    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [positions, setPositions] = useState([]);
    const [viewablePositions, setViewablePositions] = useState([]);

    //add position state
    const [addPosition, setAddPosition] = useState({ positionrank: "", position: "" });

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    //refs
    //add Position button refs
    const viewAddPositionModal = useRef(null);
    const closeAddPositionModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);

    // fetch positions
    const fetchPositions = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/teachers/fetch/positions",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setPositions(json.result);
        setViewablePositions(json.result);
    }


    // react-data-table-component columns
    const columns = [
        {
            name: "Rank",
            selector: row => row['positionrank'],
            sortable: true,
        },
        {
            name: "Designation",
            selector: row => row['position'],
            sortable: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewablePositions.length; i++) {
            if (viewablePositions[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewablePositions[i]);
                editRowModal.current.click();
            }
        }
    };

    // handle update teacher
    const handleAddPosition = async (e) => {
        e.preventDefault();
        const { position, positionrank } = addPosition;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/teachers/addposition`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    position,
                    positionrank
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchPositions();
            closeAddPositionModal.current.click();
            setAddPosition([]);
            alert.success('Position Added Successfully');
            socket.emit("add_position", {
                message: "position added"
            })
        }
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these positions?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/teachers/deletepositions`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchPositions();
                alert.success('Position Deleted Successfully')
                socket.emit("delete_position", {
                    message: "position Deleted"
                })
            }
        }
    };

    // handle update position
    const handleUpdatePosition = async (e) => {
        e.preventDefault();
        const { id, positionrank, position } = editableRow;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/teachers/updateposition`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id,
                    positionrank,
                    position
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchPositions();
            closeEditRowModal.current.click();
            setEditableRow([]);
            alert.success('Position Updated Successfully');
            socket.emit("update_position", {
                message: "position Updated"
            })
        }
    }

    // open add position modal
    const openAddPositionModal = () => {
        viewAddPositionModal.current.click();
    }

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewablePositions,
    };

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    const onChangeAddPositionInput = (e) => {
        setAddPosition({ ...addPosition, [e.target.name]: e.target.value });
    }

    // onChange update position Input
    const onChangeUpdatePositionInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchPositions();
        // socket.on("applied_schlrship", (data) => {
        //     fetchScholarships();
        // });
    }, [])

    return (
        <div>
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editPositionModal"
                ref={editRowModal}
            >
                Launch Edit Position modal
            </button>

            <div
                className="modal fade"
                id="editPositionModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editPositionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleUpdatePosition}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Position</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <input className="form-control" type="text" id="id" name='id' value={editableRow.id} onChange={onChangeUpdatePositionInput} required hidden/>
                                        <label htmlFor="position" class="form-label">Position name:</label>
                                        <input className="form-control" type="text" id="position" name='position' value={editableRow.position} onChange={onChangeUpdatePositionInput} required /><br />
                                        <label htmlFor="rank" class="form-label">Rank:</label>
                                        <input className="form-control" type="text" id="positionrank" name='positionrank' value={editableRow.positionrank} onChange={onChangeUpdatePositionInput} required /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* Add Position Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addPositionModal"
                ref={viewAddPositionModal}
            >
                Launch Add Position modal
            </button>

            <div
                className="modal fade"
                id="addPositionModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addPositionModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleAddPosition}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Position</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label htmlFor="position" class="form-label">Position name:</label>
                                        <input className="form-control" type="text" id="position" name='position' value={addPosition.position} onChange={onChangeAddPositionInput} required /><br />
                                        <label htmlFor="positionrank" class="form-label">Rank:</label>
                                        <input className="form-control" type="text" id="positionrank" name='positionrank' value={addPosition.positionrank} onChange={onChangeAddPositionInput} required /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddPositionModal}
                                    onClick={() => { setAddPosition({ positionrank: "", position: "" }) }}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Position Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Positions : {positions.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddPositionModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Teacher Positions"}
                >
                    <DataTable
                        columns={columns}
                        data={positions}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default Positions
