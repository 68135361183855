// eslint-disable-next-line
import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';
import AutoReceipts from "./AutoReceipts";
import Batches from "./Batches";
import Books from "./Books";
import Branches from "./Branches";
import CertificatePosts from "./CertificatePosts";
import Certificates from "./Certificates";
import CourseCatagories from "./CourseCatagories";
import Courses from "./Courses";
import CurrentStudents from "./CurrentStudents";
import Dashboard from "./Dashboard";
import FormerStudents from "./FormerStudents";
import ManageCertificate from "./ManageCertificate";
import ManageSalary from "./ManageSalary";
import ManageTeachers from "./ManageTeachers";
import ManualReceipts from "./ManualReceipts";
import Navbar from "./Navbar";
import Offers from "./Offers";
import PendingApplications from "./PendingApplications";
import Positions from "./Positions";
import RegisteredCompanies from "./RegisteredCompanies";
import ScholarshipRegs from "./ScholarshipRegs";
import Scholarships from "./Scholarships";
import SeminarRegs from "./SeminarRegs";
import Seminars from "./Seminars";
import Sidebar from "./Sidebar";
import Softwares from "./Softwares";
import TrashApplications from "./TrashApplications";
import UncheckedApplications from "./UncheckedApplications";

const Main = () => {

  let navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("tokenForCCAdmin")) {
      navigate("/admin/login");
    }
  })

  return (
    <div style={{ paddingTop: "58px" }}>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0">
            <Sidebar />
          </div>
          <div className="col-lg-10">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/view/courses" element={<Courses />} />
              <Route path="/view/batches" element={<Batches />} />
              <Route path="/view/students" element={<CurrentStudents />} />
              <Route path="/view/formerstudents" element={<FormerStudents />} />
              <Route path="/view/registeredcompanies" element={<RegisteredCompanies />} />
              <Route path="/view/branches" element={<Branches />} />
              <Route path="/view/coursecatagories" element={<CourseCatagories />} />
              <Route path="/view/manageteachers" element={<ManageTeachers />} />
              <Route path="/view/positions" element={<Positions />} />
              <Route path="/view/scholarshipregistrations" element={<ScholarshipRegs />} />
              <Route path="/view/seminarregistrations" element={<SeminarRegs />} />
              <Route path="/view/scholarships" element={<Scholarships />} />
              <Route path="/view/seminars" element={<Seminars />} />
              <Route path="/view/offers" element={<Offers />} />
              <Route path="/view/books" element={<Books />} />
              <Route path="/view/softwares" element={<Softwares />} />
              <Route path="/view/uncheckedapplications" element={<UncheckedApplications />} />
              <Route path="/view/pendingapplications" element={<PendingApplications />} />
              <Route path="/view/trashapplications" element={<TrashApplications />} />
              <Route path="/view/manualreceipts" element={<ManualReceipts />} />
              <Route path="/view/autoreceipts" element={<AutoReceipts />} />
              <Route path="/view/managesalary" element={<ManageSalary />} />
              <Route path="/view/cfreqs" element={<ManageCertificate />} />
              <Route path="/view/certificates" element={<Certificates />} />
              <Route path="/view/cfposts" element={<CertificatePosts />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
