// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { Link, useLocation } from "react-router-dom";

const socket = io.connect("https://api.caddcornerbd.com");

const Sidebar = () => {
  let location = useLocation();

  const [counts, setCounts] = useState([]);

  const fetchCounts = async () => {
    const response = await fetch(
      "https://api.caddcornerbd.com/api/counts/fetchcounts",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setCounts(json);
  }

  useEffect(() => {
    fetchCounts();
    socket.on("deleted_schlrreg", (data) => {
      fetchCounts();
    });
    socket.on("applied_schlrship", (data) => {
      fetchCounts();
    });
    socket.on("added_schlrship", (data) => {
      fetchCounts();
    });
    socket.on("deleted_schlrship", (data) => {
      fetchCounts();
    });
    socket.on("added_course", (data) => {
      fetchCounts();
    });
    socket.on("deleted_course", (data) => {
      fetchCounts();
    });
    socket.on("deleted_batch", (data) => {
      fetchCounts();
    });
    socket.on("added_batch", (data) => {
      fetchCounts();
    });
    socket.on("applied_foradmission", (data) => {
      fetchCounts();
    });
    socket.on("sentto_pending", (data) => {
      fetchCounts();
    });
    socket.on("applied_forseminar", (data) => {
      fetchCounts();
    });
    socket.on("deleted_semiregs", (data) => {
      fetchCounts();
    });
  }, [])

  return (
    <div>
      <div className="dashboardSidebar" style={{ backgroundColor: "#636363" }}>
        <nav className="navbar navbar-expand-* p-0">
          <div className="container-fluid p-0">
            <ul className="navbar-nav" style={{ width: "100%" }}>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/' ? ("sidebarActive") : ("")}`} to="/">
                  <i className="fa-solid fa-gauge"></i> &nbsp; Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link manageStudentsAccord accordsHeaderClosed ${location.pathname === '/view/batches' || location.pathname === '/view/students' || location.pathname === '/view/formerstudents' ? ("sidebarActive") : ("")}`}
                  href="#manageStudentsCollapse"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-solid fa-people-line"></i> &nbsp; Manage Students
                </a>
                <div className="collapse accords" id="manageStudentsCollapse">
                  <Link className={`nav-link ${location.pathname === '/view/batches' ? ("sidebarSubActive") : ("")}`} to="/view/batches">
                    Manage Batches <span className="badge bg-danger">{counts.batchesCount}</span>
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/students' ? ("sidebarSubActive") : ("")}`} to="/view/students">
                    Current Students
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/formerstudents' ? ("sidebarSubActive") : ("")}`} to="/view/formerstudents">
                    Former Students
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link manageCertificateAccord accordsHeaderClosed ${location.pathname === '/view/cfreqs' || location.pathname === '/view/certificates' || location.pathname === '/view/cfposts' ? ("sidebarActive") : ("")}`}
                  href="#manageCertificateCollapse"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-solid fa-people-line"></i> &nbsp; Manage Certificate
                </a>
                <div className="collapse accords" id="manageCertificateCollapse">
                  <Link className={`nav-link ${location.pathname === '/view/certificates' ? ("sidebarSubActive") : ("")}`} to="/view/certificates">
                    Certificates
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/cfreqs' ? ("sidebarSubActive") : ("")}`} to="/view/cfreqs">
                    Certificate Requests
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/cfposts' ? ("sidebarSubActive") : ("")}`} to="/view/cfposts">
                    Certificate Posts
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link manageResourcesAccord accordsHeaderClosed ${location.pathname === '/view/softwares' || location.pathname === '/view/books' ? ("sidebarActive") : ("")}`}
                  href="#manageResourcesCollapse"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-solid fa-basket-shopping"></i> &nbsp; Manage Resources
                </a>
                <div className="collapse accords" id="manageResourcesCollapse">
                  <Link className={`nav-link ${location.pathname === '/view/books' ? ("sidebarSubActive") : ("")}`} to="/view/books">
                    Books
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/softwares' ? ("sidebarSubActive") : ("")}`} to="/view/softwares">
                    Softwares
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link manageTeachersAccord accordsHeaderClosed ${location.pathname === '/view/positions' || location.pathname === '/view/manageteachers' ? ("sidebarActive") : ("")}`}
                  href="#manageTeachersCollapse"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-solid fa-chalkboard-user"></i> &nbsp; Manage Teachers
                </a>
                <div className="collapse accords" id="manageTeachersCollapse">
                  <Link className={`nav-link position-relative ${location.pathname === '/view/manageteachers' ? ("sidebarSubActive") : ("")}`} to="/view/manageteachers">
                    Teachers
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/positions' ? ("sidebarSubActive") : ("")}`} to="/view/positions">
                    Positions
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link manageApplicationsAccord accordsHeaderClosed ${location.pathname === '/view/uncheckedapplications' || location.pathname === '/view/trashapplications' || location.pathname === '/view/pendingapplications' ? ("sidebarActive") : ("")}`}
                  href="#manageApplicationsCollapse"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-brands fa-wpforms"></i> &nbsp; Manage Apps <span className="badge bg-light text-dark">{counts.uappsCount + counts.pappsCount}</span>
                </a>
                <div className="collapse accords" id="manageApplicationsCollapse">
                  <Link className={`nav-link ${location.pathname === '/view/uncheckedapplications' ? ("sidebarSubActive") : ("")}`} to="/view/uncheckedapplications">
                    Unchecked Apps <span className="badge bg-danger">{counts.uappsCount}</span>
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/pendingapplications' ? ("sidebarSubActive") : ("")}`} to="/view/pendingapplications">
                    Pending Apps <span className="badge bg-danger">{counts.pappsCount}</span>
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/trashapplications' ? ("sidebarSubActive") : ("")}`} to="/view/trashapplications">
                    Trash
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link manageCoursesAccord accordsHeaderClosed ${location.pathname === '/view/courses' || location.pathname === '/view/coursecatagories' ? ("sidebarActive") : ("")}`}
                  href="#manageCoursesCollapse"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-solid fa-bucket"></i> &nbsp; Manage Courses
                </a>
                <div className="collapse accords" id="manageCoursesCollapse">
                  <Link className={`nav-link ${location.pathname === '/view/courses' ? ("sidebarSubActive") : ("")}`} to="/view/courses">
                    Courses <span className="badge bg-danger">{counts.coursesCount}</span>
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/coursecatagories' ? ("sidebarSubActive") : ("")}`} to="/view/coursecatagories">
                    Course Catagories
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link manageScholarshipsAccord accordsHeaderClosed ${location.pathname === '/view/scholarshipregistrations' || location.pathname === '/view/scholarships' ? ("sidebarActive") : ("")}`}
                  href="#manageScholarshipsCollapse"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-solid fa-graduation-cap"></i> &nbsp; Scholarships <span className="badge bg-light text-dark">{counts.schlrregsCount}</span>
                </a>
                <div className="collapse accords" id="manageScholarshipsCollapse">
                  <Link className={`nav-link position-relative ${location.pathname === '/view/scholarshipregistrations' ? ("sidebarSubActive") : ("")}`} to="/view/scholarshipregistrations">
                    Scholarship Registrations <span className="badge bg-danger">{counts.schlrregsCount}</span>
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/scholarships' ? ("sidebarSubActive") : ("")}`} to="/view/scholarships">
                    Running Scholarships <span className="badge bg-danger">{counts.scholarshipsCount}</span>
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/view/offers' ? ("sidebarActive") : ("")}`} to="/view/offers">
                  <i className="fa-solid fa-fire"></i> &nbsp; Manage Offers
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link managePaymentsAccord accordsHeaderClosed ${location.pathname === '/view/manualreceipts' || location.pathname === '/view/autoreceipts' || location.pathname === '/view/managesalary' ? ("sidebarActive") : ("")}`}
                  href="#managePaymentsCollapse"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-solid fa-money-check-dollar"></i> &nbsp; Payments
                </a>
                <div className="collapse accords" id="managePaymentsCollapse">
                  <Link className={`nav-link ${location.pathname === '/view/manualreceipts' ? ("sidebarSubActive") : ("")}`} to="/view/manualreceipts">
                    Manual Receipts
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/autoreceipts' ? ("sidebarSubActive") : ("")}`} to="/view/autoreceipts">
                    Auto Receipts
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/managesalary' ? ("sidebarSubActive") : ("")}`} to="/view/managesalary">
                    Manage Salary
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link manageSeminarsAccord accordsHeaderClosed ${location.pathname === '/view/seminarregistrations' || location.pathname === '/view/seminars' ? ("sidebarActive") : ("")}`}
                  href="#manageSeminarsCollapse"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-solid fa-person-chalkboard"></i> &nbsp; Seminars <span className="badge bg-light text-dark">{counts.semiregsCount}</span>
                </a>
                <div className="collapse accords" id="manageSeminarsCollapse">
                  <Link className={`nav-link position-relative ${location.pathname === '/view/seminarregistrations' ? ("sidebarSubActive") : ("")}`} to="/view/seminarregistrations">
                    Seminar Registrations <span className="badge bg-danger">{counts.semiregsCount}</span>
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/seminars' ? ("sidebarSubActive") : ("")}`} to="/view/seminars">
                    Manage Seminars
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link othersAccord accordsHeaderClosed ${location.pathname === '/view/registeredcompanies' || location.pathname === '/view/branches' ? ("sidebarActive") : ("")}`}
                  href="#others"
                  role="button"
                  data-bs-toggle="collapse"
                >
                  <i className="fa-solid fa-bars"></i> &nbsp; Others
                </a>
                <div className="collapse accords" id="others">
                  <Link className={`nav-link ${location.pathname === '/view/registeredcompanies' ? ("sidebarSubActive") : ("")}`} to="/view/registeredcompanies">
                    Registered Companies
                  </Link>
                  <Link className={`nav-link ${location.pathname === '/view/branches' ? ("sidebarSubActive") : ("")}`} to="/view/branches">
                    Branches
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
