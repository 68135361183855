import React, { useEffect, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";
import { useRef } from 'react';

const socket = io.connect("https://api.caddcornerbd.com");

const ManageCertificate = () => {

    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [certificateRequests, setCertificateRequests] = useState([]);
    const [viewableCertificateRequests, setViewableCertificateRequests] = useState([]);
    const [students, setStudents] = useState([]);

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //certify particular row
    const [certifiableRow, setCertifiableRow] = useState([]);

    const [loading, setLoading] = useState(false);

    //refs
    //add Batch button refs
    const confirmCertifyModal = useRef(null);
    const closeConfirmCertifyModal = useRef(null);

    // fetch batchs
    const fetchCertificateReuests = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/students/fetch/cfreuests",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCertificateRequests(json.result);
        setViewableCertificateRequests(json.result);
    }

    // fetch batchs
    const fetchStudents = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/students/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setStudents(json.result);
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these certificate requests?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/students/deleterequests`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchCertificateReuests();
                alert.success('Certificate Requests Deleted Successfully')
                socket.emit("delete_certificatereq", {
                    message: "certificatereq Deleted"
                })
            }
        }
    };

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    // react-data-table-component columns
    const columns = [
        {
            name: "Name",
            selector: row => row['name'],
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row['email'],
            sortable: true,
            maxWidth: "0px",
            minWidth: "0px"
        },
        {
            name: "Student ID",
            selector: row => row['studentid'],
            sortable: true,
        },
        {
            name: "Mobile",
            selector: row => row['mobile'],
            sortable: true,
            maxWidth: "0px",
            minWidth: "0px"
        },
        {
            name: "Course",
            selector: row => row['coursename'],
            sortable: true
        },
        {
            name: "Status",
            cell: (row) => {
                return (
                    <div>
                        {row.status === 0 ? (
                            <button type='button' className='btn btn-danger' disabled>
                                Pending
                            </button>
                        ) : (
                            <a href={`https://api.caddcornerbd.com/schlrships/certificates/${row.studentid+"-"+row.coursename}.pdf`} className='btn btn-success'>
                                Download
                            </a>
                        )}
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Certify",
            cell: (row) => {
                return (
                    <div>
                        {row.status === 0 ? (
                            <button type='button' className='btn btn-primary' id={row.studentid} onClick={handleCertify}>
                                Certify
                            </button>
                        ) : (
                            <button type='button' className='btn btn-info' disabled>
                                Certified
                            </button>
                        )}
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component handle Row Edit
    const handleCertify = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < students.length; i++) {
            if (students[i].studentid === clickedRowId) {
                setCertifiableRow(students[i]);
                confirmCertifyModal.current.click();
            }
        }
    };

    const handleCertifyConfirmed = async (e) =>{
        e.preventDefault();
        setLoading(true);
        const { id, name, studentid, email, coursename, mobile } = certifiableRow;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/certifyconfirm`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id, name, studentid, email, coursename, mobile
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchCertificateReuests();
            setLoading(false);
            closeConfirmCertifyModal.current.click();
            setCertifiableRow([]);
            alert.success('Certified Successfully');
            socket.emit("confirm_certificate", {
                message: "confirmed certificate"
            })
        }
    }

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableCertificateRequests,
    };

    useEffect(() => {
        fetchCertificateReuests();
        fetchStudents();
    })

    return (
        <div>
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editScholarshipModal"
                ref={confirmCertifyModal}
            >
                Launch Confirm Certify modal
            </button>

            <div
                className="modal fade"
                id="editScholarshipModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editScholarshipModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleCertifyConfirmed}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Confirm Certification</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <h4 style={{textAlign:"center", fontWeight:"bold"}}>{certifiableRow.name}</h4>
                                        <p style={{textAlign:"center"}}>Student ID: {certifiableRow.studentid}<br/>{certifiableRow.coursename}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeConfirmCertifyModal}
                                    onClick={() => {setCertifiableRow([])}}
                                >
                                    Cancel
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    {loading?('Please wait...'):('Confirm')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Certificate Requests : {certificateRequests.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Batchs"}
                >
                    <DataTable
                        columns={columns}
                        data={certificateRequests}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default ManageCertificate
