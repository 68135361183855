import React, { useEffect, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Moment from "moment";
import { useAlert } from "react-alert";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import {
  isWithinInterval,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useRef } from 'react';

const socket = io.connect("https://api.caddcornerbd.com");


const PendingApplications = () => {
  const alert = useAlert();

  const [pendingApps, setPendingApps] = useState([]);
  const [viewablePendingApps, setviewablePendingApps] = useState([]);


  const [dprDate, setDprDate] = useState([new Date(), new Date()]);

  //view particular row
  const [viewableRow, setViewableRow] = useState([]);

  // refs //
  //view row modal refs
  const viewRowModal = useRef(null);
  const closeViewRowModal = useRef(null);

  const fetchPendingApps = async () => {
    const response = await fetch(
      "https://api.caddcornerbd.com/api/applications/fetchpendings",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setPendingApps(json.result);
    setviewablePendingApps(json.result)
  }

  // react-data-table-component columns
  const columns = [
    {
      name: "Name",
      selector: row => row['name'],
      sortable: true,
      compact: true
    },
    {
      name: "Email",
      selector: row => row['email'],
      sortable: true,
      compact: true,
      maxWidth: "0px",
      minWidth: "0px",
    },
    {
      name: "Phone",
      selector: row => row['mobile'],
      sortable: true,
      compact: true,
      maxWidth: "0px",
      minWidth: "0px",
    },
    {
      name: "Course",
      selector: row => row['coursename'],
      sortable: true,
      compact: true
    },
    {
      name: "Applied at",
      selector: row => row['created_at'],
      format: (row) => Moment(row.created_at).format("DD-MM-YYYY"),
      sortable: true,
      compact: true
    },
    {
      name: "View",
      cell: (row) => {
        return (
          <div>
            <i
              className="fa-solid fa-eye"
              id={row.id}
              onClick={handleViewRow}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Delete",
      cell: (row) => {
        return (
          <div>
            <i
              className="fa-solid fa-trash"
              id={row.id}
              onClick={handleDeleteRow}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // react-data-table-component handle Row View
  const handleViewRow = (state) => {
    const clickedRowId = state.target.id;
    for (let i = 0; i < viewablePendingApps.length; i++) {
      if (viewablePendingApps[i].id === parseFloat(clickedRowId)) {
        setViewableRow(viewablePendingApps[i]);
        viewRowModal.current.click();
      }
    }
  };

  
  // react-data-table-component handle Row View
  const handleDeleteRow = async (state) => {
    const id = state.target.id;
    if (window.confirm("Are you sure to send it to trash?")) {
      const response = await fetch(
        `https://api.caddcornerbd.com/api/applications/sendtotrashfrompending`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id
          }),
        }
      );

      const json = await response.json();
      if (json.success) {
        const response01 = await fetch(
          `https://api.caddcornerbd.com/api/applications/deletefrompapps`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id
            }),
          }
        );

        const json01 = await response01.json();
        if (json01.success) {
          fetchPendingApps();
          alert.success("Application Successfully sent to trash");
          socket.emit("sendto_trashfrompending", {
            message: "sent to trash"
          })
        }
      }
    }
  };

  // react-data-table-component Data of Table
  const tableData = {
    columns,
    data: viewablePendingApps,
  };

  // check if the given time is in the selected range
  const checkInRange = (time) => {
    return isWithinInterval(time, {
      start: dprDate[0],
      end: dprDate[1],
    });
  };

  // on change Date Time Picker Range
  const onChangeDPRange = () => {
    let rows = [];
    for (let i = 0; i < pendingApps.length; i++) {
      if (checkInRange(zonedTimeToUtc(pendingApps[i].created_at))) {
        rows.push(pendingApps[i]);
      }
    }
    setviewablePendingApps(rows);
  };

  useEffect(() => {
    fetchPendingApps();
    socket.on("sentto_pending", (data) => {
      fetchPendingApps();
    });
  }, [])

  return (
    <div>
      {/* View Row Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#viewRowModal"
        ref={viewRowModal}
      >
        Launch View Row modal
      </button>

      <div
        className="modal fade"
        id="viewRowModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="viewRowModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <form>
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className="modal-body">
                <div className='row'>
                  <div className='col-lg-12'>
                    <h6 style={{ color: "grey" }}><i><b>Applied at: {Moment(viewableRow.created_at).format("MMM Do YY | hh:mm A")}</b></i></h6>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Applicant's name:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.name}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Applicant's email:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.email}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Applicant's phone:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        0{viewableRow.mobile}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Related course:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.coursename}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeViewRowModal}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* View Row Modal */}
      <div className="header">
        <div className="row">
          <div className="col-lg-4">
            <h4>Pending Applications : {pendingApps.length}</h4>
          </div>
          <div className="col-lg-8">
            <div
              className="btnWrapper d-flex flex-row"
              style={{ width: "max-content", marginLeft: "auto" }}
            >

              <DateTimeRangePicker
                onChange={setDprDate}
                onCalendarClose={onChangeDPRange}
                value={dprDate}
                format="dd-MM-y"
                clearIcon={null}
                className="pickerBoxes"
                rangeDivider="- to -"
                dayAriaLabel="Day"
              />
              <button
                className="btn btnsuccess"
                onClick={fetchPendingApps}
                style={{
                  backgroundColor: "#d72323",
                  borderRadius: "0",
                  marginRight: "5px",
                  color: "#fff",
                  border: "none"
                }}
              >
                Reset Filter
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Page Body */}

      <div className="body">
        <DataTableExtensions
          {...tableData}
          exportHeaders
          print={false}
          fileName={Moment().format("MMM Do YY") + " Pending Applications"}
        >
          <DataTable
            columns={columns}
            data={pendingApps}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="580px"
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {/* Page Body */}
    </div>
  )
}

export default PendingApplications
