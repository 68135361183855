import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Moment from "moment";
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");

const Books = () => {

    const alert = useAlert();

    //States//
    //basic view states after fetch
    const [books, setBooks] = useState([]);
    const [viewableBooks, setViewableBooks] = useState([]);

    const [courses, setCourses] = useState([]);

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    const [addBooks, setAddBooks] = useState({ title: "", coursename: "" });
    const [selectedBook, setSelectedBook] = useState(null);
    const [selectedEditBook, setSelectedEditBook] = useState(null);

    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    // refs
    //add book button refs
    const viewAddBookModal = useRef(null);
    const closeAddBookModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);
    // refs

    // fetch Courses
    const fetchCourses = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourses(json.result);
    }

    // fetch books
    const fetchBooks = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/students/fetchbooks",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setBooks(json.result);
        setViewableBooks(json.result);
    }

    // react-data-table-component columns
    const columns = [
        {
            name: "Book",
            selector: row => row['title'],
            sortable: true,
        },
        {
            name: "Related Course",
            selector: row => row['coursename'],
            sortable: true,
        },
        {
            name: "Download",
            cell: (row) => {
                return (
                    <div>
                        <a href={`https://api.caddcornerbd.com/schlrships/books/${row.booklink}`} download>
                            <i
                                className="fa-solid fa-download"
                                id={row.id}
                                style={{ cursor: "pointer" }}
                            ></i>
                        </a>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableBooks.length; i++) {
            if (viewableBooks[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewableBooks[i]);
                editRowModal.current.click();
            }
        }
    };

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these books?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/students/deletebooks`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchBooks();
                alert.success('Books Deleted Successfully')
                socket.emit("delete_book", {
                    message: "book Deleted"
                })
            }
        }
    };


    // handle add book
    const handleAddbook = async (e) => {
        e.preventDefault();
        const { title, coursename } = addBooks;
        const formData = new FormData();
        formData.append('file', selectedBook);
        formData.append('title', title);
        formData.append('coursename', coursename);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/addbooks`,
            {
                method: "POST",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchBooks();
            closeAddBookModal.current.click();
            setAddBooks({ title: "", coursename: "" });
            setSelectedBook(null);
            alert.success('Book Added Successfully');
            socket.emit("add_book", {
                message: "book Added"
            })
        }
        // console.log(selectedBook);
    }

    // open add book modal
    const openAddBookModal = () => {
        viewAddBookModal.current.click();
    }

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    // handle update book
    const handleUpdateBook = async (e) => {
        e.preventDefault();
        const { id, title, coursename, booklink } = editableRow;
        const formData = new FormData();
        formData.append('file', selectedEditBook);
        formData.append('id', id);
        formData.append('title', title);
        formData.append('coursename', coursename);
        formData.append('booklink', booklink);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/updatebook`,
            {
                method: "PUT",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchBooks();
            closeEditRowModal.current.click();
            setEditableRow([]);
            setSelectedEditBook(null);
            alert.success('Book Updated Successfully');
            socket.emit("update_book", {
                message: "book Updated"
            })
        }
    }

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableBooks,
    };

    //on change file input
    const onChangeFileInput = (e) => {
        setSelectedBook(e.target.files[0]);
    }

    //on change file input
    const onChangeEditFileInput = (e) => {
        setSelectedEditBook(e.target.files[0]);
    }

    // onChange update book Input
    const onChangeUpdateBookInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    // handle on change add book input
    const onChangeAddBookInput = (e) => {
        setAddBooks({ ...addBooks, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        fetchBooks();
        fetchCourses();
    }, [])

    return (
        <div>
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editBookModal"
                ref={editRowModal}
            >
                Launch Edit Book modal
            </button>

            <div
                className="modal fade"
                id="editBookModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editBookModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleUpdateBook} encType="multipart/form-data">
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Book</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <input className="form-control" type="text" id="title" name='title' value={editableRow.id} onChange={onChangeUpdateBookInput} required hidden/><br />
                                        <input className="form-control" type="text" id="title" name='title' value={editableRow.booklink} onChange={onChangeUpdateBookInput} required hidden/><br />
                                        <label htmlFor="title" className="form-label">Book Title:</label>
                                        <input className="form-control" type="text" id="title" name='title' value={editableRow.title} onChange={onChangeUpdateBookInput} required /><br />
                                        <label htmlFor="coursename" className="form-label">Related Course:</label>
                                        <select name="coursename" className="form-select" id="coursename" autoComplete="off" required onChange={(e) => { onChangeUpdateBookInput(e) }} value={editableRow.coursename}>
                                            <option value="">Select Related Course</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="book" className="form-label">Select Book:</label>
                                        <input className="form-control" type="file" id="booklink" name='booklink' accept=".pdf, .pptx" onChange={onChangeEditFileInput} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* Add Books Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addBookModal"
                ref={viewAddBookModal}
            >
                Launch Add Book modal
            </button>

            <div
                className="modal fade"
                id="addBookModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addBookModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleAddbook} encType="multipart/form-data">
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Book</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label htmlFor="title" className="form-label">Book Title:</label>
                                        <input className="form-control" type="text" id="title" name='title' value={addBooks.title} onChange={onChangeAddBookInput} required /><br />
                                        <label htmlFor="coursename" className="form-label">Related Course:</label>
                                        <select name="coursename" className="form-select" id="coursename" autoComplete="off" required onChange={(e) => { onChangeAddBookInput(e) }} value={addBooks.coursename}>
                                            <option value="">Select Related Course</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="book" className="form-label">Select Book:</label>
                                        <input className="form-control" type="file" id="thumblink" name='thumblink' accept=".pdf, .pptx" onChange={onChangeFileInput} required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddBookModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Book Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Books : {books.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddBookModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Books"}
                >
                    <DataTable
                        columns={columns}
                        data={books}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default Books
