// eslint-disable-next-line
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from '../img/logo.png';

const Navbar = () => {
  let navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem("tokenForCCAdmin");
    navigate('/admin/login');
  }
  return (
    <div className="fixed-top">
      <div
        className="container-fluid"
        style={{ borderBottom: "1px solid gray" }}
      >
        <div className="row">
          <div
            className="col-lg-2"
            style={{ backgroundColor: "#474747", color: "#fff" }}
          >
            <img src={logo} alt="CADD CORNER BD main logo" style={{width:"180px", margin:"auto", display:"block", marginTop:"12px"}}/>
          </div>
          <div className="col-lg-3" style={{ padding: "18px", backgroundColor:"#fff" }}>
            <p style={{fontWeight:"bold",color:"red"}}>ADMIN PANEL</p>
          </div>
          <div className="col-lg-7" style={{ padding: "18px", backgroundColor:"#fff"  }}>
            <p style={{ width: "max-content", marginLeft: "auto" }}>
              CEO{" "}
              <i
                className="fa-solid fa-right-from-bracket"
                style={{ marginLeft: "10px", cursor: "pointer" }} onClick={handleLogOut}
              ></i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
