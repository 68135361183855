import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");
const CourseCatagories = () => {

    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [courseCatagories, setCourseCatagories] = useState([]);
    const [viewableCourseCatagories, setViewableCourseCatagories] = useState([]);

    //add catagory state
    const [addCatagory, setAddCatagory] = useState({ catagory: ""});

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //edit particular row
    const [editableRow, setEditableRow] = useState([]);


    //refs
    //add catagory button refs
    const viewAddCatagoryModal = useRef(null);
    const closeAddCatagoryModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);

    // fetch course catagories
    const fetchCourseCatagories = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetch/catagories",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourseCatagories(json.result);
        setViewableCourseCatagories(json.result);
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these catagories?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/courses/deletecatagories`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchCourseCatagories();
                alert.success('Catagory Deleted Successfully')
                socket.emit("delete_catagory", {
                    message: "catagory Deleted"
                })
            }
        }
    };

    // handle Add Catagory
    const handleAddCatagory = async (e) => {
        e.preventDefault();
        const { catagory } = addCatagory;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/courses/addcatagory`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    catagory
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchCourseCatagories();
            closeAddCatagoryModal.current.click();
            setAddCatagory([]);
            alert.success('Catagory Added Successfully');
            socket.emit("add_catagory", {
                message: "catagory added"
            })
        }
    }

    // handle update course catagory
    const handleUpdateCatagory = async (e) => {
        e.preventDefault();
        const { id, catagory } = editableRow;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/courses/updatecatagory`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id,
                    catagory
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchCourseCatagories();
            closeEditRowModal.current.click();
            setEditableRow([]);
            alert.success('Catagory Updated Successfully');
            socket.emit("update_catagory", {
                message: "catagory Updated"
            })
        }
    }

    // open add catagory modal
    const openAddCatagoryModal = () => {
        viewAddCatagoryModal.current.click();
    }

    // react-data-table-component columns
    const columns = [
        {
            name: "Catagory",
            selector: row => row['catagory'],
            sortable: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableCourseCatagories.length; i++) {
            if (viewableCourseCatagories[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewableCourseCatagories[i]);
                editRowModal.current.click();
            }
        }
    };

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableCourseCatagories,
    };

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    const onChangeAddCatagoryInput = (e) => {
        setAddCatagory({ ...addCatagory, [e.target.name]: e.target.value });
    }

    // onChange update catagory Input
    const onChangeUpdateCatagoryInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };
    
    useEffect(() => {
        fetchCourseCatagories();
        // socket.on("applied_schlrship", (data) => {
        //     fetchScholarships();
        // });
    }, [])

    return (
        <div>
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editCourseCatagoriesModal"
                ref={editRowModal}
            >
                Launch Edit CourseCatagories modal
            </button>

            <div
                className="modal fade"
                id="editCourseCatagoriesModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="edittCourseCatagoriesModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleUpdateCatagory}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Position</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <input className="form-control" type="text" id="id" name='id' value={editableRow.id} onChange={onChangeUpdateCatagoryInput} required hidden/>
                                        <label htmlFor="catagory" class="form-label">Catagory:</label>
                                        <input className="form-control" type="text" id="catagory" name='catagory' value={editableRow.catagory} onChange={onChangeUpdateCatagoryInput} required /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* Add Catagory Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addCatagoryModal"
                ref={viewAddCatagoryModal}
            >
                Launch Add Catagory modal
            </button>

            <div
                className="modal fade"
                id="addCatagoryModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addCatagoryModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleAddCatagory}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Position</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label htmlFor="catagory" class="form-label">Catagory:</label>
                                        <input className="form-control" type="text" id="catagory" name='catagory' value={addCatagory.catagory} onChange={onChangeAddCatagoryInput} required /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddCatagoryModal}
                                    onClick={() => { setAddCatagory({ catagory: "" }) }}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Catagory Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Course Catagories : {courseCatagories.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddCatagoryModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Teacher Positions"}
                >
                    <DataTable
                        columns={columns}
                        data={courseCatagories}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="550px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default CourseCatagories
