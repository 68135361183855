import React from 'react'
import { useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";
import { useEffect } from 'react';
import { useRef } from 'react';
import DateTimePicker from "react-datetime-picker";

const socket = io.connect("https://api.caddcornerbd.com");

const ManageSalary = () => {
  const alert = useAlert();
  //States//
  //basic view states after fetch
  const [receipts, setReceipts] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [viewableReceipts, setViewableReceipts] = useState([]);

  const [loading, setLoading] = useState(false);

  const [addReceipt, setAddReceipt] = useState({ provident: "", signature: "" });

  const [month, setMonth] = useState(new Date());
  const [trainer, setTrainer] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");

  //React data table component manage selection states
  const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
    useState(false);
  const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
  const [selectedData, setSelectedData] = useState([]);

  //view particular row
  const [viewableRow, setViewableRow] = useState([]);

  //refs
  //add receipt button refs
  const viewAddReceiptModal = useRef(null);
  const closeAddReceiptModal = useRef(null);

  //view row modal refs
  const viewRowModal = useRef(null);
  const closeViewRowModal = useRef(null);

  // fetch Receipts
  const fetchSalaries = async () => {
    const response = await fetch(
      "https://api.caddcornerbd.com/api/receipts/salaries/fetch",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setReceipts(json.result);
    setViewableReceipts(json.result);
  }

  // react-data-table-component handle selected Data
  const deleteSelectedData = async () => {
    let rows = [];
    for (let i = 0; i < selectedData.length; i++) {
      rows.push(selectedData[i]);
    }
    if (window.confirm("Really wanna delete these receipts?")) {
      const response = await fetch(
        `https://api.caddcornerbd.com/api/receipts/salary/delete`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rows),
        }
      );
      const json = await response.json();
      if (json.success) {
        setShowManageSelectionWrapper(false);
        setNumberOfSelectedRow(0);
        fetchSalaries();
        alert.success('Receipts Deleted Successfully')
        socket.emit("delete_receipts", {
          message: "receipts Deleted"
        })
      }
    }
  };

  // handle add Receipt
  const handleAddReceipt = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { provident, signature } = addReceipt;
    let tdate = Moment(new Date()).format("DD/MM/YYYY");
    let monthOfSalary = Moment(month).format("MMMM-YYYY");
    const response = await fetch(
      `https://api.caddcornerbd.com/api/receipts/addreceiptsalary`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          trainer, designation, email, monthOfSalary, amount, provident, signature, tdate
        }),
      }
    );

    const json = await response.json();
    if (json.success) {
      fetchSalaries();
      setLoading(false);
      closeAddReceiptModal.current.click();
      setAddReceipt({ provident: "", signature: "" });
      setTrainer("");
      setDesignation("");
      setEmail("");
      setAmount("");
      setMonth(new Date());
      alert.success('Receipt Added Successfully');
      socket.emit("add_receiptsalary", {
        message: "receipt added salary"
      })
    }
  }

  // fetch Teachers
  const fetchTeachers = async () => {
    const response = await fetch(
      "https://api.caddcornerbd.com/api/teachers/fetch",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setTeachers(json.result);
  }

  // react-data-table-component columns
  const columns = [
    {
      name: "Name",
      selector: row => row['trainer'],
      sortable: true,
    },
    {
      name: "Position",
      selector: row => row['designation'],
      sortable: true,
    },
    {
      name: "Email",
      selector: row => row['email'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Month of Salary",
      selector: row => row['month'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Amount of Salary",
      selector: row => row['amount'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Provident Fund",
      selector: row => row['provident'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Paid Now",
      selector: row => row['paidnow'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Signature",
      selector: row => row['signature'],
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "Paid at",
      selector: row => row['created_at'],
      format: (row) => Moment(row.created_at).format("DD-MM-YYYY"),
      sortable: true,
      minWidth: "0px",
      maxWidth: "0px"
    },
    {
      name: "View",
      cell: (row) => {
        return (
          <div>
            <i
              className="fa-solid fa-eye"
              id={row.id}
              onClick={handleViewRow}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // react-data-table-component handle Row View
  const handleViewRow = (state) => {
    const clickedRowId = state.target.id;
    for (let i = 0; i < viewableReceipts.length; i++) {
      if (viewableReceipts[i].id === parseFloat(clickedRowId)) {
        setViewableRow(viewableReceipts[i]);
        viewRowModal.current.click();
      }
    }
  };

  // react-data-table-component handle on select change
  const handleOnSelectChange = (state) => {
    if (state.selectedCount > 0) {
      setNumberOfSelectedRow(state.selectedCount);
      setShowManageSelectionWrapper(true);
      setSelectedData(state.selectedRows);
    } else {
      setNumberOfSelectedRow(0);
      setShowManageSelectionWrapper(false);
    }
  };

  // open add Receipt modal
  const openAddReceiptModal = () => {
    viewAddReceiptModal.current.click();
  }

  // react-data-table-component Data of Table
  const tableData = {
    columns,
    data: viewableReceipts,
  };

  const onChangeTeacherChange = (e) => {
    setTrainer(e.target.value);
    for (let i = 0; i < teachers.length; i++) {
      if (teachers[i].name === e.target.value) {
        setDesignation(teachers[i].position);
        setEmail(teachers[i].email);
        setAmount(teachers[i].salary);
      }
    }
  }

  const onChangeAddReceiptInput = (e) => {
    setAddReceipt({ ...addReceipt, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    fetchSalaries();
    fetchTeachers();
  })
  return (
    <div>
      {/* View Row Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#viewRowModal"
        ref={viewRowModal}
      >
        Launch View Row modal
      </button>

      <div
        className="modal fade"
        id="viewRowModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="viewRowModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <form>
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className="modal-body">
                <div className='row'>
                  <div className='col-lg-12'>
                    <h6 style={{ color: "grey" }}><i><b>Issued at: {Moment(viewableRow.created_at).format("MMM Do YY | hh:mm A")}</b></i></h6>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Trainer's name:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.trainer}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Trainer's Designation:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.designation}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Trainer's Email:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.email}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Month of Salary:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.month}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Amount of Salary:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.amount}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Provident:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.provident}
                      </div>
                      <div className='col-lg-4'>
                        <h6>
                          <b>Issued by:</b>
                        </h6>
                      </div>
                      <div className='col-lg-8'>
                        {viewableRow.signature}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeViewRowModal}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* View Row Modal */}
      {/* Add Receipt Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#addReceiptModal"
        ref={viewAddReceiptModal}
      >
        Launch Add Receipt modal
      </button>

      <div
        className="modal fade"
        id="addReceiptModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addReceiptModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <form onSubmit={handleAddReceipt}>
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className='modal-header'>
                <h4>Add Receipt</h4>
              </div>
              <div className="modal-body">
                <div className='row'>
                  <div className="col-lg-12">
                    <label htmlFor="trainer" className="form-label">Trainer's name:</label>
                    <select name="coursename" className="form-select" id="trainer" autoComplete="off" required onChange={(e) => { onChangeTeacherChange(e) }}>
                      <option value="">Select Course</option>
                      {teachers.map((teacher) => {
                        return <option key={teacher.id}>{teacher.name}</option>
                      })}
                    </select><br />
                    <label htmlFor="designation" className="form-label">Designation:</label>
                    <input className="form-control" type="text" id="designation" name='designation' value={designation} required /><br />
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input className="form-control" type="text" id="email" name='email' value={email} required /><br />
                    <label htmlFor="amount" className="form-label">Amount of Salary:</label>
                    <input className="form-control" type="text" id="amount" name='amount' value={amount} required /><br />
                    <DateTimePicker
                      onChange={setMonth}
                      value={month}
                      format="MMMM-y"
                      clearIcon={null}
                      className="pickerBoxes"
                    />
                    <br/>
                    <br/>
                    <label htmlFor="provident" className="form-label">Provident:</label>
                    <input className="form-control" type="text" id="provident" name='provident' onChange={onChangeAddReceiptInput} value={addReceipt.provident} /><br />
                    <label htmlFor='signature' className='form-label'>Signature:</label>
                    <select name='signature' required className='form-select' id='signature' autoComplete='off' onChange={(e) => { onChangeAddReceiptInput(e) }} value={addReceipt.signature}>
                      <option value=''>None</option>
                      <option selected>Mahfuzur Rahman</option>
                    </select><br />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeAddReceiptModal}
                  onClick={() => { setTrainer(""); setDesignation(""); setEmail(""); setAmount(""); }}
                >
                  Close
                </button>
                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                  {loading ? ('Please wait...') : ('Submit')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Add receipt Modal */}
      <div className="header">
        <div className="row">
          <div className="col-lg-4">
            <h4>Salary Receipts : {receipts.length}</h4>
          </div>
          <div className="col-lg-8">
            <div
              className="btnWrapper d-flex flex-row"
              style={{ width: "max-content", marginLeft: "auto" }}
            >
              <button className='btn btnsuccess' onClick={openAddReceiptModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
          </div>
          {showManageSelectionWrapper && (
            <div className="col-lg-12 manageSelectionWrapperContainer">
              <div
                className="manageSelectionWrapper d-flex flex-row"
                style={{
                  margin: "5px 0",
                  padding: "5px",
                  border: "1px solid lightgray",
                  alignItems: "center",
                  backgroundColor: "#ff000024",
                }}
              >
                <p style={{ color: "gray", opacity: "0.8" }}>
                  Selected rows : {numberOfSelectedRow}
                </p>
                <button
                  className="btn btn-danger"
                  onClick={deleteSelectedData}
                  style={{ borderRadius: "0", marginLeft: "auto" }}
                >
                  Delete Selected
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Page Body */}

      <div className="body">
        <DataTableExtensions
          {...tableData}
          exportHeaders
          print={false}
          fileName={Moment().format("MMM Do YY") + " Salaries"}
        >
          <DataTable
            columns={columns}
            data={receipts}
            pagination
            selectableRows
            fixedHeader
            fixedHeaderScrollHeight="580px"
            highlightOnHover
            onSelectedRowsChange={handleOnSelectChange}
          />
        </DataTableExtensions>
      </div>
      {/* Page Body */}
    </div>
  )
}

export default ManageSalary
