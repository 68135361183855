import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Moment from "moment";
import { useAlert } from "react-alert";
import spinner from '../img/sqSpinner.svg';

const socket = io.connect("https://api.caddcornerbd.com");
const ManageTeachers = () => {

    const alert = useAlert();

    //spinner state
    const [showSpinner, setShowSpinner] = useState(false);

    const [teachers, setTeachers] = useState([]);
    const [viewableTeachers, setViewableTeachers] = useState([]);

    //positions state
    const [positions, setPositions] = useState([]);

    //add teacher details state
    const [addTeacherDetails, setAddTeacherDetails] = useState({ name: "", email: "", position: "", salary: "" });

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //view particular row
    const [viewableRow, setViewableRow] = useState([]);
    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    // refs //
    //view row modal refs
    const viewRowModal = useRef(null);
    const closeViewRowModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);

    //add teacher button refs
    const viewAddTeacherModal = useRef(null);
    const closeAddTeacherModal = useRef(null);

    // fetch teachers
    const fetchtTeachers = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/teachers/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setTeachers(json.result);
        setViewableTeachers(json.result);
    }

    // fetch positions
    const fetchPositions = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/teachers/fetch/positions",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setPositions(json.result);
    }

    // handle add teacher
    const handleAddTeacher = async (e) => {
        e.preventDefault();
        setShowSpinner(true);
        const { name, email, position, salary } = addTeacherDetails;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/teachers/addteacher`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    email,
                    position,
                    salary
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchtTeachers();
            closeAddTeacherModal.current.click();
            setAddTeacherDetails({ name: "", email: "", position: "", salary: "" });
            setShowSpinner(false);
            alert.success('Teacher Added Successfully');
            socket.emit("add_teacher", {
                message: "teacher added"
            })
        }
    }

    // handle update teacher
    const handleUpdateTeacher = async (e) => {
        e.preventDefault();
        const { id, position, salary } = editableRow;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/teachers/updateteachervad`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id,
                    position,
                    salary
                }),
            }
        );
        
        const json = await response.json();
        if (json.success) {
            fetchtTeachers();
            closeEditRowModal.current.click();
            setEditableRow([]);
            alert.success('Teacher Updated Successfully');
            socket.emit("update_teacher", {
                message: "teacher Updated"
            })
        }
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these teachers?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/teachers/deleteteachers`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchtTeachers();
                alert.success('Teachers Deleted Successfully')
                socket.emit("delete_teacher", {
                    message: "teacher Deleted"
                })
            }
        }
    };

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableTeachers.length; i++) {
            if (viewableTeachers[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewableTeachers[i]);
                editRowModal.current.click();
            }
        }
    };

    // react-data-table-component handle Row View
    const handleViewRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableTeachers.length; i++) {
            if (viewableTeachers[i].id === parseFloat(clickedRowId)) {
                setViewableRow(viewableTeachers[i]);
                viewRowModal.current.click();
            }
        }
    };

    // react-data-table-component columns
    const columns = [
        {
            name: "Name",
            selector: row => row['name'],
            sortable: true,
        },
        {
            name: "Designation",
            selector: row => row['position'],
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row['email'],
            sortable: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Phone",
            selector: row => row['mobile'],
            sortable: true,
            maxWidth: "0px",
            minWidth: "0px",
        },
        {
            name: "Salary",
            selector: row => row['salary'],
            sortable: true,
        },
        {
            name: "Date joined",
            selector: row => row['created_at'],
            format: (row) => Moment(row.created_at).format("DD-MM-YYYY"),
            sortable: true,
        },
        {
            name: "View",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-eye"
                            id={row.id}
                            onClick={handleViewRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableTeachers,
    };

    // handle View to Edit modal
    const handleViewtoEditModal = (id) => {
        for (let i = 0; i < viewableTeachers.length; i++) {
            if (viewableTeachers[i].id === parseFloat(id)) {
                setEditableRow(viewableTeachers[i]);
                editRowModal.current.click();
            }
        }
    };

    // open add teacher modal
    const openAddTeacherModal = () => {
        viewAddTeacherModal.current.click();
    }

    //VIEW to EDIT modal
    const viewToEditModal = (id) => {
        closeViewRowModal.current.click();
        handleViewtoEditModal(id);
    }

    // onChange update Teacher Input
    const onChangeUpdateTeacherInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    // onChange update Teacher Input
    const onChangeAddTeacherInput = (e) => {
        setAddTeacherDetails({ ...addTeacherDetails, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchtTeachers();
        fetchPositions();
    }, [])

    return (
        <div>
            {/* Add Teacher Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addTeacherModal"
                ref={viewAddTeacherModal}
            >
                Launch Add Teacher modal
            </button>

            <div
                className="modal fade"
                id="addTeacherModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addTeacherModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleAddTeacher}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Teacher</h4>
                            </div>
                            <div className="modal-body">
                                {showSpinner?(
                                    <div className='row'>
                                    <img src={spinner} alt="spinner" className="img-fluid" style={{width:"50%", margin:"auto"}} />
                                </div>
                                ):(
                                    <div className='row' >
                                    <div className='col-lg-12' >
                                        <label htmlFor="name" class="form-label">Name:</label>
                                        <input className="form-control" type="name" id="name" name='name' value={addTeacherDetails.name} onChange={onChangeAddTeacherInput} required /><br />
                                        <label htmlFor="email" class="form-label">Email:</label>
                                        <input className="form-control" type="email" id="email" name='email' value={addTeacherDetails.email} onChange={onChangeAddTeacherInput} required /><br />
                                        <label htmlFor="position" class="form-label">Designation:</label>
                                        <select name="position" className="form-select" id="position" autoComplete="off" required onChange={(e) => { onChangeAddTeacherInput(e) }} value={addTeacherDetails.position}>
                                            <option value="">Select Position</option>
                                            {positions.map((position) => {
                                                return <option key={position.id}>{position.position}</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="salary" class="form-label">Salary:</label>
                                        <input className="form-control" type="text" id="salary" name='salary' value={addTeacherDetails.salary} onChange={onChangeAddTeacherInput} required />
                                    </div>
                                </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddTeacherModal}
                                    onClick={() => { setAddTeacherDetails({ name: "", email: "", position: "", salary: "" }) }}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Teacher Modal */}
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editTeacherModal"
                ref={editRowModal}
            >
                Launch Edit Teacher modal
            </button>

            <div
                className="modal fade"
                id="editTeacherModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editTeacherModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <form onSubmit={handleUpdateTeacher}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Teacher</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={`https://api.caddcornerbd.com/schlrships/trImg/${editableRow.dp}`} alt={editableRow.dp} className='img-fluid' />
                                    </div>
                                    <div className='col-lg-4'>
                                        <h6 style={{ color: "grey" }}><i><b>Joined at: {Moment(editableRow.created_at).format("MMM Do YYYY")}</b></i></h6>
                                        <h4><b>{editableRow.name}</b></h4>
                                        <p><i class="fa-solid fa-arrows-turn-to-dots"></i> Designation: {editableRow.position}</p>
                                        <p><i class="fa-solid fa-envelope"></i> Email: {editableRow.email}</p>
                                        <p><i class="fa-solid fa-phone"></i> Phone: 0{editableRow.mobile}</p>
                                        <p><i class="fa-solid fa-money-bill"></i> Salary: {editableRow.salary}&#2547;</p>
                                    </div>
                                    <div className='col-lg-4' style={{ borderLeft: "2px solid grey" }}>
                                        <h5><b>Editable Section</b></h5>
                                        <label htmlFor="position" class="form-label">Designation</label>
                                        <select name="position" className="form-select" id="position" autoComplete="off" required onChange={(e) => { onChangeUpdateTeacherInput(e) }} value={editableRow.position}>
                                            <option>{editableRow.position}</option>
                                            {positions.map((position) => {
                                                return <option key={position.id}>{position.position}</option>
                                            })}
                                        </select><br />
                                        <label htmlFor="salary" class="form-label">Salary</label>
                                        <input className="form-control" type="text" id="salary" name='salary' value={editableRow.salary} onChange={onChangeUpdateTeacherInput} required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* View Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#viewRowModal"
                ref={viewRowModal}
            >
                Launch View Row modal
            </button>

            <div
                className="modal fade"
                id="viewRowModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="viewRowModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <form>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <img src={`https://api.caddcornerbd.com/schlrships/trImg/${viewableRow.dp}`} alt={viewableRow.dp} className='img-fluid' />
                                    </div>
                                    <div className='col-lg-8'>
                                        <h6 style={{ color: "grey" }}><i><b>Joined at: {Moment(viewableRow.created_at).format("MMM Do YYYY")}</b></i></h6>
                                        <h4><b>{viewableRow.name}</b></h4>
                                        <p><i class="fa-solid fa-arrows-turn-to-dots"></i> Designation: {viewableRow.position}</p>
                                        <p><i class="fa-solid fa-envelope"></i> Email: {viewableRow.email}</p>
                                        <p><i class="fa-solid fa-phone"></i> Phone: 0{viewableRow.mobile}</p>
                                        <p><i class="fa-solid fa-money-bill"></i> Salary: {viewableRow.salary}&#2547;</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeViewRowModal}
                                >
                                    Close
                                </button>
                                <button type='button' onClick={() => { viewToEditModal(viewableRow.id) }} className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* View Row Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Teachers : {teachers.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddTeacherModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Teachers"}
                >
                    <DataTable
                        columns={columns}
                        data={teachers}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default ManageTeachers
