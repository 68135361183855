import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Moment from "moment";
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");

const Batches = () => {

    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [batches, setBatches] = useState([]);
    const [courses, setCourses] = useState([]);
    const [viewableBatches, setViewableBatches] = useState([]);

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //add batch state
    const [addBatch, setAddBatch] = useState({ name: "", relatedcourse: "" });

    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    //refs
    //add Batch button refs
    const viewAddBatchModal = useRef(null);
    const closeAddBatchModal = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);

    // fetch batchs
    const fetchBatches = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/students/fetch/batches",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setBatches(json.result);
        setViewableBatches(json.result);
    }

    // fetch batchs
    const fetchCourses = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetchnames",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourses(json.result);
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these batches?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/students/deletebatches`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchBatches();
                alert.success('Batches Deleted Successfully')
                socket.emit("delete_batch", {
                    message: "batch Deleted"
                })
            }
        }
    };

    // handle add batch
    const handleAddBatch = async (e) => {
        e.preventDefault();
        const { name, relatedcourse } = addBatch;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/addbatch`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    relatedcourse
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchBatches();
            closeAddBatchModal.current.click();
            setAddBatch([]);
            alert.success('Batch Added Successfully');
            socket.emit("add_batch", {
                message: "batch added"
            })
        }
    }

    // handle update batch
    const handleUpdateBatch = async (e) => {
        e.preventDefault();
        const { id, name, relatedcourse } = editableRow;

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/updatebatch`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id,
                    name,
                    relatedcourse
                }),
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchBatches();
            closeEditRowModal.current.click();
            setEditableRow([]);
            alert.success('Batch Updated Successfully');
            socket.emit("update_batch", {
                message: "batch Updated"
            })
        }
    }

    // open add Batch modal
    const openAddBatchModal = () => {
        viewAddBatchModal.current.click();
    }

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableBatches.length; i++) {
            if (viewableBatches[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewableBatches[i]);
                editRowModal.current.click();
            }
        }
    };

    // react-data-table-component columns
    const columns = [
        {
            name: "Batch",
            selector: row => row['name'],
            sortable: true,
        },
        {
            name: "Related Course",
            selector: row => row['relatedcourse'],
            sortable: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableBatches,
    };
    
    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    const onChangeAddBatchInput = (e) => {
        setAddBatch({ ...addBatch, [e.target.name]: e.target.value });
    }

    // onChange update batch Input
    const onChangeUpdateBatchInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchBatches();
        fetchCourses();
    }, [])

    return (
        <div>
             {/* Edit Row Modal */}
             <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editBatchModal"
                ref={editRowModal}
            >
                Launch Edit Batch modal
            </button>

            <div
                className="modal fade"
                id="editBatchModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editBatchModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleUpdateBatch}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Batch</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <input className="form-control" type="text" id="id" name='id' value={editableRow.id} onChange={onChangeUpdateBatchInput} required hidden/>
                                        <label htmlFor="name" className="form-label">Batch name:</label>
                                        <input className="form-control" type="text" id="name" name='name' value={editableRow.name} onChange={onChangeUpdateBatchInput} required /><br />
                                        <label htmlFor="relatedcourse" className="form-label">Related Course:</label>
                                        <select name="relatedcourse" className="form-select" id="relatedcourse" autoComplete="off" required onChange={(e) => { onChangeUpdateBatchInput(e) }} value={editableRow.relatedcourse}>
                                            <option>{editableRow.relatedcourse}</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            {/* Add Batch Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addBatchModal"
                ref={viewAddBatchModal}
            >
                Launch Add Batch modal
            </button>

            <div
                className="modal fade"
                id="addBatchModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addBatchModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleAddBatch}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Batch</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label htmlFor="name" className="form-label">Batch name:</label>
                                        <input className="form-control" type="text" id="name" name='name' value={addBatch.name} onChange={onChangeAddBatchInput} required /><br />
                                        <label htmlFor="relatedcourse" className="form-label">Related Course:</label>
                                        <select name="relatedcourse" className="form-select" id="relatedcourse" autoComplete="off" required onChange={(e) => { onChangeAddBatchInput(e) }} value={addBatch.catagory}>
                                            <option value="">Select Related Course</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddBatchModal}
                                    onClick={() => { setAddBatch({ name: "", relatedcourse: "" }) }}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Batch Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Batches : {batches.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddBatchModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Batchs"}
                >
                    <DataTable
                        columns={columns}
                        data={batches}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default Batches
