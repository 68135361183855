/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Moment from "moment";
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");


const Softwares = () => {

  const alert = useAlert();

  //States//
  //basic view states after fetch
  const [softwares, setSoftwares] = useState([]);
  const [viewableSoftwares, setViewableSoftwares] = useState([]);

  //React data table component manage selection states
  const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
    useState(false);
  const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
  const [selectedData, setSelectedData] = useState([]);

  const [addSoftwares, setAddSoftwares] = useState({ title: "", softlink: "" });

  //edit particular row
  const [editableRow, setEditableRow] = useState([]);

  // refs
  //add scholarship button refs
  const viewAddSoftwareModal = useRef(null);
  const closeAddSoftwareModal = useRef(null);

  //edit row modal refs
  const editRowModal = useRef(null);
  const closeEditRowModal = useRef(null);
  // refs


  // fetch scholarships
  const fetchSoftwares = async () => {
    const response = await fetch(
      "https://api.caddcornerbd.com/api/students/fetchsoftwares",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setSoftwares(json.result);
    setViewableSoftwares(json.result);
  }

  // react-data-table-component columns
  const columns = [
    {
      name: "Software",
      selector: row => row['title'],
      sortable: true,
    },
    {
      name: "Drive Link",
      selector: row => row['softlink'],
      sortable: true,
      compact: true
    },
    {
      name: "Redirect",
      cell: (row) => {
        return (
          <div>
            <a href={`${row.softlink}`} target="_blank">
              <i
                className="fa-solid fa-arrow-up-right-from-square"
                id={row.id}
                style={{ cursor: "pointer" }}
              ></i>
            </a>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Edit",
      cell: (row) => {
        return (
          <div>
            <i
              className="fa-solid fa-pen"
              id={row.id}
              onClick={handleEditRow}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // react-data-table-component handle Row Edit
  const handleEditRow = (state) => {
    const clickedRowId = state.target.id;
    for (let i = 0; i < viewableSoftwares.length; i++) {
      if (viewableSoftwares[i].id === parseFloat(clickedRowId)) {
        setEditableRow(viewableSoftwares[i]);
        editRowModal.current.click();
      }
    }
  };

  // react-data-table-component handle selected Data
  const deleteSelectedData = async () => {
    let rows = [];
    for (let i = 0; i < selectedData.length; i++) {
      rows.push(selectedData[i]);
    }
    if (window.confirm("Really wanna delete these softwares?")) {
      const response = await fetch(
        `https://api.caddcornerbd.com/api/students/deletesoftwares`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rows),
        }
      );
      const json = await response.json();
      if (json.success) {
        setShowManageSelectionWrapper(false);
        setNumberOfSelectedRow(0);
        fetchSoftwares();
        alert.success('Softwares Deleted Successfully')
        socket.emit("delete_software", {
          message: "software Deleted"
        })
      }
    }
  };


  // handle add scholarship
  const handleAddSoftware = async (e) => {
    e.preventDefault();
    const { title, softlink } = addSoftwares;

    const response = await fetch(
      `https://api.caddcornerbd.com/api/students/addsoftwares`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
          softlink
        }),
      }
    );

    const json = await response.json();
    if (json.success) {
      fetchSoftwares();
      closeAddSoftwareModal.current.click();
      setAddSoftwares({ title: "", softlink: "" });
      alert.success('Software Added Successfully');
      socket.emit("add_software", {
        message: "software Added"
      })
    }
    // console.log(addSoftwares)
  }

  // open add software modal
  const openAddSoftwareModal = () => {
    viewAddSoftwareModal.current.click();
  }

  // react-data-table-component handle on select change
  const handleOnSelectChange = (state) => {
    if (state.selectedCount > 0) {
      setNumberOfSelectedRow(state.selectedCount);
      setShowManageSelectionWrapper(true);
      setSelectedData(state.selectedRows);
    } else {
      setNumberOfSelectedRow(0);
      setShowManageSelectionWrapper(false);
    }
  };

  // handle update scholarship
  const handleUpdateSoftware = async (e) => {
    e.preventDefault();
    const { id, title, softlink } = editableRow;

    const response = await fetch(
      `https://api.caddcornerbd.com/api/students/updatesoftware`,
      {
        method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        body: JSON.stringify({
          id,
          title,
          softlink
        }),
      }
    );

    const json = await response.json();
    if (json.success) {
      fetchSoftwares();
      closeEditRowModal.current.click();
      setEditableRow([]);
      alert.success('Software Updated Successfully');
      socket.emit("update_software", {
        message: "software Updated"
      })
    }
  }

  // react-data-table-component Data of Table
  const tableData = {
    columns,
    data: viewableSoftwares,
  };

  // onChange update Software Input
  const onChangeUpdateSoftwareInput = (e) => {
    setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
  };

  // handle on change add Software input
  const onChangeAddSoftwareInput = (e) => {
    setAddSoftwares({ ...addSoftwares, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    fetchSoftwares();
  }, [])

  return (
    <div>
      {/* Edit Row Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#editScholarshipModal"
        ref={editRowModal}
      >
        Launch Edit Software modal
      </button>

      <div
        className="modal fade"
        id="editScholarshipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="editScholarshipModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-md">
          <form onSubmit={handleUpdateSoftware} encType="multipart/form-data">
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className='modal-header'>
                <h4>Edit Software</h4>
              </div>
              <div className="modal-body">
                <div className='row'>
                  <div className="col-lg-12">
                    <input className="form-control" type="text" id="id" name='id' value={editableRow.id} onChange={onChangeUpdateSoftwareInput} required hidden/><br />
                    <label htmlFor="title" className="form-label">Software Title:</label>
                    <input className="form-control" type="text" id="title" name='title' value={editableRow.title} onChange={onChangeUpdateSoftwareInput} required /><br />
                    <label htmlFor="softlink" className="form-label">Software Link:</label>
                    <input className="form-control" type="text" id="softlink" name='softlink' value={editableRow.softlink} onChange={onChangeUpdateSoftwareInput} required /><br />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeEditRowModal}
                >
                  Close
                </button>
                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Edit Row Modal */}
      {/* Add Softwares Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#addScholarshipModal"
        ref={viewAddSoftwareModal}
      >
        Launch Add Software modal
      </button>

      <div
        className="modal fade"
        id="addScholarshipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addScholarshipModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-md">
          <form onSubmit={handleAddSoftware} encType="multipart/form-data">
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className='modal-header'>
                <h4>Add Software</h4>
              </div>
              <div className="modal-body">
                <div className='row'>
                  <div className="col-lg-12">
                    <label htmlFor="title" className="form-label">Software Title:</label>
                    <input className="form-control" type="text" id="title" name='title' value={addSoftwares.title} onChange={onChangeAddSoftwareInput} required /><br />
                    <label htmlFor="softlink" className="form-label">Software Link:</label>
                    <input className="form-control" type="text" id="softlink" name='softlink' value={addSoftwares.softlink} onChange={onChangeAddSoftwareInput} required /><br />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeAddSoftwareModal}
                >
                  Close
                </button>
                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Add Software Modal */}
      <div className="header">
        <div className="row">
          <div className="col-lg-4">
            <h4>Softwares : {softwares.length}</h4>
          </div>
          <div className="col-lg-8">
            <div
              className="btnWrapper d-flex flex-row"
              style={{ width: "max-content", marginLeft: "auto" }}
            >
              <button className='btn btnsuccess' onClick={openAddSoftwareModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
          </div>
          {showManageSelectionWrapper && (
            <div className="col-lg-12 manageSelectionWrapperContainer">
              <div
                className="manageSelectionWrapper d-flex flex-row"
                style={{
                  margin: "5px 0",
                  padding: "5px",
                  border: "1px solid lightgray",
                  alignItems: "center",
                  backgroundColor: "#ff000024",
                }}
              >
                <p style={{ color: "gray", opacity: "0.8" }}>
                  Selected rows : {numberOfSelectedRow}
                </p>
                <button
                  className="btn btn-danger"
                  onClick={deleteSelectedData}
                  style={{ borderRadius: "0", marginLeft: "auto" }}
                >
                  Delete Selected
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Page Body */}

      <div className="body">
        <DataTableExtensions
          {...tableData}
          exportHeaders
          print={false}
          fileName={Moment().format("MMM Do YY") + " softwares"}
        >
          <DataTable
            columns={columns}
            data={softwares}
            pagination
            selectableRows
            fixedHeader
            fixedHeaderScrollHeight="580px"
            highlightOnHover
            onSelectedRowsChange={handleOnSelectChange}
          />
        </DataTableExtensions>
      </div>
      {/* Page Body */}
    </div>
  )
}

export default Softwares
