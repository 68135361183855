import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Moment from "moment";
import { useAlert } from "react-alert";
import Resizer from "react-image-file-resizer";

const socket = io.connect("https://api.caddcornerbd.com");

const RegisteredCompanies = () => {

    const alert = useAlert();
    //States//
    //basic view states after fetch
    const [companies, setCompanies] = useState([]);
    const [viewableCompanies, setViewableCompanies] = useState([]);

    //add company details states
    const [addCompany, setAddCompany] = useState({ name: "" });
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
    const [previewThumbnail, setPreviewthumbnail] = useState(null);

    //edit company details states
    const [selectedEditThumbnail, setSelectedEditThumbnail] = useState(null);
    const [previewEditThumbnail, setPreviewEditThumbnail] = useState(null);

    //edit particular row
    const [editableRow, setEditableRow] = useState([]);

    //React data table component manage selection states
    const [showManageSelectionWrapper, setShowManageSelectionWrapper] =
        useState(false);
    const [numberOfSelectedRow, setNumberOfSelectedRow] = useState(0);
    const [selectedData, setSelectedData] = useState([]);

    //refs
    //add company button refs
    const viewAddCompanyModal = useRef(null);
    const closeAddCompanyModal = useRef(null);

    //thumbnail input
    const thumbnailInput = useRef(null);
    const editThumbnailInput = useRef(null);

    //edit row modal refs
    const editRowModal = useRef(null);
    const closeEditRowModal = useRef(null);

    // fetch companies
    const fetchCompanies = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/others/fetchcompanies",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCompanies(json.result);
        setViewableCompanies(json.result);
    }

    // handle add company
    const handleAddCompany = async (e) => {
        e.preventDefault();
        const { name } = addCompany;
        const formData = new FormData();
        formData.append('image', selectedThumbnail);
        formData.append('name', name);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/others/addcompany`,
            {
                method: "POST",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchCompanies();
            closeAddCompanyModal.current.click();
            setAddCompany({ name: "" });
            setSelectedThumbnail(null);
            setPreviewthumbnail(null);
            thumbnailInput.current.value = null;
            alert.success('Company Added Successfully');
            socket.emit("add_company", {
                message: "company Added"
            })
        }
        // console.log(selectedThumbnail);
    }

    // handle update scholarship
    const handleUpdateCompany = async (e) => {
        e.preventDefault();
        const { id, logo, name } = editableRow;
        const formData = new FormData();
        formData.append('image', selectedEditThumbnail);
        formData.append('id', id);
        formData.append('logo', logo);
        formData.append('name', name);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/others/updatecompany`,
            {
                method: "PUT",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchCompanies();
            closeEditRowModal.current.click();
            setEditableRow([]);
            setSelectedEditThumbnail(null);
            setPreviewEditThumbnail(null);
            thumbnailInput.current.value = null;
            alert.success('Company Updated Successfully');
            socket.emit("update_company", {
                message: "company Updated"
            })
        }
    }

    // react-data-table-component handle selected Data
    const deleteSelectedData = async () => {
        let rows = [];
        for (let i = 0; i < selectedData.length; i++) {
            rows.push(selectedData[i]);
        }
        if (window.confirm("Really wanna delete these companies?")) {
            const response = await fetch(
                `https://api.caddcornerbd.com/api/others/deletecompanies`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(rows),
                }
            );
            const json = await response.json();
            if (json.success) {
                setShowManageSelectionWrapper(false);
                setNumberOfSelectedRow(0);
                fetchCompanies();
                alert.success('Companies Deleted Successfully')
                socket.emit("delete_company", {
                    message: "company Deleted"
                })
            }
        }
    };

    // react-data-table-component handle Row Edit
    const handleEditRow = (state) => {
        const clickedRowId = state.target.id;
        for (let i = 0; i < viewableCompanies.length; i++) {
            if (viewableCompanies[i].id === parseFloat(clickedRowId)) {
                setEditableRow(viewableCompanies[i]);
                editRowModal.current.click();
            }
        }
    };

    //image resizer
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                144,
                144,
                "PNG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                144,
            );
        });

    // open add company modal
    const openAddCompanyModal = () => {
        viewAddCompanyModal.current.click();
    }

    // react-data-table-component columns
    const columns = [
        {
            name: "Name",
            selector: row => row['name'],
            sortable: true,
        },
        {
            name: "Edit",
            cell: (row) => {
                return (
                    <div>
                        <i
                            className="fa-solid fa-pen"
                            id={row.id}
                            onClick={handleEditRow}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // data to file
    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    //on change thumbnail
    const onChangeThumbnail = async (e) => {
        // console.log(e.target.files);
        // setSelectedThumbnail(e.target.files[0]);

        // const objectUrl = URL.createObjectURL(e.target.files[0]);
        // setPreviewthumbnail(objectUrl);

        const file = e.target.files[0];
        const imageResized = await resizeFile(file);

        const imageFile = dataURLtoFile(imageResized, file.name)
        setSelectedThumbnail(imageFile);

        const previewUrl = await imageResized;
        setPreviewthumbnail(previewUrl);
        // console.log(imageFile);
    }

    // react-data-table-component Data of Table
    const tableData = {
        columns,
        data: viewableCompanies,
    };

    // react-data-table-component handle on select change
    const handleOnSelectChange = (state) => {
        if (state.selectedCount > 0) {
            setNumberOfSelectedRow(state.selectedCount);
            setShowManageSelectionWrapper(true);
            setSelectedData(state.selectedRows);
        } else {
            setNumberOfSelectedRow(0);
            setShowManageSelectionWrapper(false);
        }
    };

    //on change thumbnail
    const onChangeEditThumbnail = async (e) => {
        // console.log(e.target.files);
        // setSelectedThumbnail(e.target.files[0]);

        // const objectUrl = URL.createObjectURL(e.target.files[0]);
        // setPreviewthumbnail(objectUrl);

        const file = e.target.files[0];
        const imageResized = await resizeFile(file);

        const imageFile = dataURLtoFile(imageResized, file.name)
        setSelectedEditThumbnail(imageFile);

        const previewUrl = await imageResized;
        setPreviewEditThumbnail(previewUrl);
        // console.log(imageFile);
    }

    //make null edit thumbnails
    const nullEditThumbs = () => {
        setPreviewEditThumbnail("");
        setSelectedEditThumbnail("");
    }

    // onChange Add Company Input
    const onChangeAddCompanyInput = (e) => {
        setAddCompany({ ...addCompany, [e.target.name]: e.target.value });
    };

    // onChange update Company Input
    const onChangeUpdateCompanyInput = (e) => {
        setEditableRow({ ...editableRow, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchCompanies();
    }, [])

    return (
        <div>
            {/* Add Company Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addCompanyModal"
                ref={viewAddCompanyModal}
            >
                Launch Add Company modal
            </button>

            <div
                className="modal fade"
                id="addCompanyModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addCompanyModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <form onSubmit={handleAddCompany} enctype="multipart/form-data">
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Add Company</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-4' style={{ height: "300px" }}>
                                        {selectedThumbnail && <img src={previewThumbnail} className="img-fluid" style={{ width: "144px" }} alt='thumbnail' />}
                                    </div>
                                    <div className="col-lg-8">
                                        <label htmlFor="thumblink" class="form-label">Logo</label>
                                        <input className="form-control" type="file" id="thumblink" name='thumblink' onChange={onChangeThumbnail} accept=".png" required ref={thumbnailInput} /><br />
                                        <label htmlFor="name" class="form-label">Company name:</label>
                                        <input className="form-control" type="text" id="name" name='name' value={addCompany.name} onChange={onChangeAddCompanyInput} required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeAddCompanyModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Company Modal */}
            {/* Edit Row Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editCompanyModal"
                ref={editRowModal}
            >
                Launch Edit Company modal
            </button>

            <div
                className="modal fade"
                id="editCompanyModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editCompanyModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <form onSubmit={handleUpdateCompany} enctype="multipart/form-data">
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Company</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-4' style={{ height: "300px" }}>
                                        {previewEditThumbnail !== null ? (<img src={previewEditThumbnail} className="img-fluid" style={{ width: "144px" }} alt='thumbnail' />) : (<img src={`https://api.caddcornerbd.com/schlrships/companyImg/${editableRow.logo}`} className="img-fluid" style={{ width: "144px" }} alt='thumbnail' />)}
                                    </div>
                                    <div className="col-lg-8">
                                        <input type="text" id='id' name='id' value={editableRow.id} onChange={onChangeUpdateCompanyInput} hidden />
                                        <input type="text" id='oldThumb' name='oldThumb' value={editableRow.logo} onChange={onChangeUpdateCompanyInput} hidden />
                                        <label htmlFor="thumblink" class="form-label">Logo</label>
                                        <input className="form-control" type="file" id="thumblink" name='thumblink' onChange={onChangeEditThumbnail} value={setSelectedEditThumbnail} accept=".png" ref={editThumbnailInput} /><br />
                                        <label htmlFor="name" class="form-label">Scholarship</label>
                                        <input className="form-control" type="text" id="name" name='name' value={editableRow.name} onChange={onChangeUpdateCompanyInput} required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditRowModal}
                                    onClick={nullEditThumbs}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Row Modal */}
            <div className="header">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Ragistered Companies : {companies.length}</h4>
                    </div>
                    <div className="col-lg-8">
                        <div
                            className="btnWrapper d-flex flex-row"
                            style={{ width: "max-content", marginLeft: "auto" }}
                        >
                            <button className='btn btnsuccess' onClick={openAddCompanyModal} style={{ backgroundColor: "#d72323", borderRadius: "0", color: "#fff", border: "none", marginRight: "5px" }}>
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    {showManageSelectionWrapper && (
                        <div className="col-lg-12 manageSelectionWrapperContainer">
                            <div
                                className="manageSelectionWrapper d-flex flex-row"
                                style={{
                                    margin: "5px 0",
                                    padding: "5px",
                                    border: "1px solid lightgray",
                                    alignItems: "center",
                                    backgroundColor: "#ff000024",
                                }}
                            >
                                <p style={{ color: "gray", opacity: "0.8" }}>
                                    Selected rows : {numberOfSelectedRow}
                                </p>
                                <button
                                    className="btn btn-danger"
                                    onClick={deleteSelectedData}
                                    style={{ borderRadius: "0", marginLeft: "auto" }}
                                >
                                    Delete Selected
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Page Body */}

            <div className="body">
                <DataTableExtensions
                    {...tableData}
                    exportHeaders
                    print={false}
                    fileName={Moment().format("MMM Do YY") + " Company Regs"}
                >
                    <DataTable
                        columns={columns}
                        data={companies}
                        pagination
                        selectableRows
                        fixedHeader
                        fixedHeaderScrollHeight="580px"
                        highlightOnHover
                        onSelectedRowsChange={handleOnSelectChange}
                    />
                </DataTableExtensions>
            </div>
            {/* Page Body */}
        </div>
    )
}

export default RegisteredCompanies
