import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useAlert } from "react-alert";


const Dashboard = () => {

  const alert = useAlert();

  const [othersInfos, setOthersInfos] = useState([]);

  const viewNewEmailModal = useRef(null);
  const viewNewPassModal = useRef(null);
  const closeNewEmailModal = useRef(null);
  const closeNewPassModal = useRef(null);

  const [adminAuthDetails, setAdminAuthDetails] = useState({ newEmail: "", newPass: "" });
  // fetch Courses
  const fetchOthersInfos = async () => {
    const response = await fetch(
      "https://api.caddcornerbd.com/api/others/fetchroll",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setOthersInfos(json.result);
  }

  const openNewEmailModal = () => {
    viewNewEmailModal.current.click();
  }

  const openNewPassModal = () => {
    viewNewPassModal.current.click();
  }

  const handleSetNewEmail = async (e) => {
    e.preventDefault();
    const { newEmail } = adminAuthDetails;
    const response = await fetch(`https://api.caddcornerbd.com/api/auth/setnewadminemail`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newEmail
      }),
    });

    const json = await response.json();
    if(json.success){
      fetchOthersInfos();
      closeNewEmailModal.current.click();
      alert.success('Email updated');
    }
  }

  const handleSetNewPass = async (e) => {
    e.preventDefault();
    const { newPass } = adminAuthDetails;
    const response = await fetch(`https://api.caddcornerbd.com/api/auth/adminnewpass`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newPass
      }),
    });

    const json = await response.json();
    if(json.success){
      fetchOthersInfos();
      closeNewPassModal.current.click();
      alert.success('Password updated');
    }
  }

  const onChangeAdminAuthInput = (e) => {
    setAdminAuthDetails({ ...adminAuthDetails, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    fetchOthersInfos();
  }, [])
  return (
    <div>
      {/* set new email Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#addBookModal"
        ref={viewNewEmailModal}
      >
        Launch New Email modal
      </button>

      <div
        className="modal fade"
        id="addBookModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addBookModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-md">
          <form onSubmit={handleSetNewEmail}>
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className='modal-header'>
                <h4>Set New Recovery Email</h4>
              </div>
              <div className="modal-body">
                <div className='row'>
                  <div className="col-lg-12">
                    <label htmlFor="title" className="form-label">Enter Email:</label>
                    <input className="form-control" type="email" id="newEmail" name='newEmail' value={adminAuthDetails.newEmail} onChange={onChangeAdminAuthInput} required /><br />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeNewEmailModal}
                >
                  Cancel
                </button>
                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* set new email Modal  */}
      {/* set new pass Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#newPassModal"
        ref={viewNewPassModal}
      >
        Launch New Pass modal
      </button>

      <div
        className="modal fade"
        id="newPassModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="newPassModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-md">
          <form onSubmit={handleSetNewPass}>
            <div className="modal-content banFont" style={{ borderRadius: "0" }}>
              <div className='modal-header'>
                <h4>Set New Password</h4>
              </div>
              <div className="modal-body">
                <div className='row'>
                  <div className="col-lg-12">
                    <label htmlFor="title" className="form-label">Enter New Password:</label>
                    <input className="form-control" type="text" id="newPass" name='newPass' value={adminAuthDetails.newPass} onChange={onChangeAdminAuthInput} required /><br />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style={{ borderRadius: "0" }}
                  ref={closeNewPassModal}
                >
                  Cancel
                </button>
                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* set new pass Modal  */}
      <div className='recoveryBox' style={{ padding: "50px", margin: "20px 10px", border: "2px solid #d72323" }}>
        <div className='row'>
          <div className='col-lg-8'>
            <p>Recovery Email: {othersInfos.length !== 0 ? (othersInfos[0].adminemail) : ('')}</p>
          </div>
          <div className='col-lg-4'>
            <button type='button' className='btn d-block' style={{ backgroundColor: "#d72323", borderColor: "#d72323", color: "#fff", borderRadius: "0px", margin: "7px 0" }} onClick={openNewEmailModal}>Change Recovery Email</button>
            <button type='button' className='btn d-block' style={{ backgroundColor: "#d72323", borderColor: "#d72323", color: "#fff", borderRadius: "0px", margin: "7px 0" }} onClick={openNewPassModal}>Change Admin Password</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
